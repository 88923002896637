import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '../../i18n';
import _ from 'lodash';
import { selectFilter } from '../../store/actions/filters';

import MobileDropdown from '../molecules/MobileDropdown';
import { RootState } from '../../types';
import { theme } from '../../assets/theme';

const MobileFilters : React.FC = () => {

  const filters = useSelector((state: RootState) => state.filters);
  const loading = useSelector((state: RootState) => state.loading);
  const global = useSelector((state: RootState) => state.global);
  const { years, quarterly, regions, departements, intercos, comparison } = filters;
  const dispatch = useDispatch();
  return (
    <Container>
      <Header>
        <Title>{t('Filter.title')}</Title>
      </Header>
      <Section style={{marginTop : "2rem"}} >
        <Margin>
          <MobileDropdown 
            label={t('Filter.year.label')}
            placeholder={t('Filter.year.placeholder')}
            items={years.data}
            defaultValue={years.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('years', value))}
            selected={years.selected}
            disabled={loading?.calculation > 0 || global?.disablePeriod}
            containerStyle={{color : theme.colors.text}}
            inputStyle={{backgroundColor : "transparent", width : "95%", color : theme.colors.text}}
            listStyle={{backgroundColor : "transparent"}}
            labelStyle={{color : theme.colors.text, fontFamily : 'work-sans-semiBold', fontSize : '2rem'}}
          />
          <MobileDropdown 
            label={t('Filter.quarterly.label')}
            placeholder={t('Filter.quarterly.placeholder')}
            items={quarterly.data}
            defaultValue={quarterly.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('quarterly', value))}
            selected={quarterly.selected}
            disabled={loading?.calculation > 0 || global?.disablePeriod}
            containerStyle={{color : theme.colors.text}}
            inputStyle={{backgroundColor : "transparent", width : "95%", color : theme.colors.text}}
            labelStyle={{color : theme.colors.text, fontFamily : 'work-sans-semiBold', fontSize : '2rem'}}
          />
        </Margin>
      </Section>
      <Section>
        <Margin>
          <MobileDropdown 
            label={t('Filter.territoryLabel')}
            placeholder={t('Filter.region.placeholder')}
            items={_.sortBy(regions.data, (o) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))}
            defaultValue={regions.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('regions', value))}
            selected={regions.selected}
            disabled={loading?.calculation > 0}
            showSearchBar
            searchBarPlaceholder={t('Filter.region.searchBarPlaceholder')}
            containerStyle={{color : theme.colors.text}}
            inputStyle={{backgroundColor : "transparent", width : "95%", color : theme.colors.text}}
            labelStyle={{color : theme.colors.text, fontFamily : 'work-sans-semiBold', fontSize : '2rem'}}
          />
          <MobileDropdown 
            // label={t('Filter.direction.label')}
            placeholder={t('Filter.departement.placeholder')}
            items={departements.departementsFiltered.length ? 
              _.sortBy(departements.departementsFiltered, (o) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) : 
              _.sortBy(departements.data, (o) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }
            defaultValue={departements.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('departements', value))}
            selected={departements.selected}
            showSearchBar
            disabled={loading?.calculation > 0}
            searchBarPlaceholder={t('Filter.departement.searchBarPlaceholder')}
            containerStyle={{color : theme.colors.text}}
            inputStyle={{backgroundColor : "transparent", width : "95%", color : theme.colors.text}}
            labelStyle={{color : theme.colors.text, fontFamily : 'work-sans-semiBold', fontSize : '2rem'}}
          />
          <MobileDropdown 
            // label={t('Filter.direction.label')}
            placeholder={t('Filter.interco.placeholder')}
            items={intercos.intercosFiltered.length ? 
              _.sortBy(intercos.intercosFiltered, (o: any) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) : 
              _.sortBy(intercos.data, (o: any) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }
            defaultValue={intercos.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('intercos', value))}
            selected={intercos.selected}
            showSearchBar
            disabled={loading?.calculation > 0}
            searchBarPlaceholder={t('Filter.interco.searchBarPlaceholder')}
            containerStyle={{color : theme.colors.text}}
            inputStyle={{backgroundColor : "transparent", width : "95%", color : theme.colors.text}}
            labelStyle={{color : theme.colors.text, fontFamily : 'work-sans-semiBold', fontSize : '2.5rem'}}
          />
        </Margin>
      </Section>
      {/* <Section style={{border: 'none'}}>
        <Margin style={{justifyContent: 'flex-start', marginTop: '0.5rem'}} >
          <MobileDropdown 
            label={t('Filter.comparison.label')}
            placeholder={t('Filter.comparison.placeholder')}
            items={comparison.data}
            defaultValue={comparison.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('comparison', value))}
            selected={comparison.selected}
            showSearchBar
            disabled={loading?.calculation > 0 || !regions.selected && !departements.selected && !intercos.selected}
            searchBarPlaceholder={t('Filter.comparison.searchBarPlaceholder')}
            disabledPlaceholder={t('Filter.comparison.disabledPlaceholder')}
            containerStyle={{color : theme.colors.text}}
            inputStyle={{backgroundColor : "transparent", width : "100%", color : theme.colors.text}}
            labelStyle={{color : theme.colors.text, fontFamily : 'work-sans-semiBold'}}
          />
        </Margin>
      </Section> */}
    </Container>
  )
}

export default MobileFilters;

const Container = styled.div`
  width            : 100%;
  height           : 100%;
  display          : flex;
  flex-direction   : column;
  background-color : ${({theme}) => theme.colors.filters};
`;

const Section = styled.div`
  border-bottom : 1px solid ${({theme}) => theme.colors.background}; 
  padding-left  : 4rem;
`;

const Margin = styled.div`
  margin-left      : 1.5rem;
  width            : 95%;
  height           : 100%;
  display          : flex;
  flex-direction   : column;
  align-items      : center;
  justify-content  : space-around;
`;

const Header = styled.div`
  padding-top : 10rem;
  padding-left : 6rem;
  padding-bottom : 4rem;
  border-bottom : 1px solid ${({theme}) => theme.colors.background}; 
`;

const Title = styled.div`
  font-family : work-sans-semiBold;
  font-size   : 2.5rem; 
`;