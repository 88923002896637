
import React from 'react';
import styled from 'styled-components';
// import CheckPng from '../assets/images/check.png';

/**
 * Checkbox
 * @param {boolean} selected status of checkbox
 * @param {function} onSelect behavior when click on checkbox
 * @param {number} id 
 * @param {string} name 
 * @param {string} value  
 *  
 */

const Checkbox: React.FC<CheckboxProps> = ({
  selected,
  onClick,
  id,
  name,
  value,
  ...props
}) => {
  return (
    <StyledCheckbox
      role='checkbox'
      checked={selected}
      onClick={onClick}
      id={id}
      name={name}
      value={value}
      {...props}
    >
      {/* <Icon src={CheckPng}/> */}
    </StyledCheckbox>
  );
};

export default Checkbox;

export interface CheckboxProps {
  selected: boolean;
  onClick : (value : string) => void;
  id      : number;
  name    : string;
  value   : string;
}

interface ElementProps {
  checked : boolean;
}

const StyledCheckbox : any = styled.button<ElementProps>`
  margin            : 0 13px;
  border            : ${({theme}) => `1px solid ${theme.colors.primary}`};
  border-radius     : 2px;
  cursor            : pointer;
  width             : 12px;
  min-height        : 14px;
  background-color  : ${({theme, checked}) => checked ? theme.colors.primary : theme.colors.neutral};
  display           : flex;
  flex-direction    : row;
  align-items       : center;
  justify-content   : center;
  outline-color     : ${({theme}) => theme.colors.primary};
  outline-width     : 1px;
`;

const Icon = styled.img`
  width : 8px;
  ::selection {
    background-color:transparent;
  }
`;

