import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router';

import HomepageTemplate from '../components/templates/Homepage';
import Button           from '../components/atoms/Button';
import Card             from '../components/atoms/Card';
import Icon             from '../components/atoms/Icon';
import { IconDownload } from '../assets/svg-images/IconDownload';
import { IconPlay }     from '../assets/svg-images/IconPlay';
import { AllIcons }     from '../assets/svg-images/allIcons';
import { IconClose }    from '../assets/svg-images/IconClose';

import { theme } from '../assets/theme';
import { t } from '../i18n';
import { calcHeightMobileRem, calcRem, calcWidthMobileRem } from '../utils/dimension';
import { CTT_DATA, CTT_JOBS, CTT_SECTORS, CDII } from '../routes/paths';
import useOutsideClick from '../hooks/useOutsideClick';
import { sizeMobile } from '../utils/constants';
import useWindowSize from '../hooks/useWindowSize';

const Homepage: React.FC = () => {
  const navigate = useNavigate();
  const playerRef = useRef(null);
  const size = useWindowSize();
  useOutsideClick(playerRef, () => setShowVideo(false));
  const [showVideo, setShowVideo] = useState(false);
  const download = (url : string) => {
    fetch(url).then(function(t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", 'methodologie');
        a.click();
      });
    });
  }

  return (
    <>
      <HomepageTemplate>
        <Margin>
          <Header>
            <Left>
              <Title>
                <Trans
                  i18nKey="Homepage.title" // optional -> fallbacks to defaults if not provided
                  components={{
                    bold : <strong style={{fontFamily : "montserrat-bold", color : theme.colors.text}} />,
                    color : <span style={{color : theme.colors.primary, fontFamily : "montserrat-regular"}} />
                  }}
                />
              </Title>
              <SubTitle>
                {t("Homepage.subtitle")}
              </SubTitle>
            </Left>
            <Right>
              <ContainerAllIcons>
                <AllIcons height={`100%`} width={`100%`} />
              </ContainerAllIcons>
            </Right>
          </Header>
          <NavigateTitle>{t('Homepage.navigate')}</NavigateTitle>
          <Navigate>
            <CustomizeButton 
              onClick={() => navigate(CTT_DATA)}
              centered={true}
              style={{backgroundColor : theme.colors.primary}}
            >
              <ContentButton>
                <ButtonTitle>{t('View.ctt-data.subtitle')}</ButtonTitle>
                <Icon icon='arrow-right' color={theme.colors.text} />
              </ContentButton>
            </CustomizeButton>
            <CustomizeButton 
              onClick={() => navigate(CTT_JOBS)}
              centered={true}
              style={{backgroundColor : theme.colors.secondary}}
            >
              <ContentButton>
                <ButtonTitle>{t('View.ctt-metiers.subtitle')}</ButtonTitle>
                <Icon icon='arrow-right' color={theme.colors.text} />
              </ContentButton>
            </CustomizeButton>
            <CustomizeButton 
              onClick={() => navigate(CTT_SECTORS)}
              centered={true}
              style={{backgroundColor : theme.colors.thirdary}}
            >
              <ContentButton>
                <ButtonTitle>{t('View.ctt-secteurs.subtitle')}</ButtonTitle>
                <Icon icon='arrow-right' color={theme.colors.text} />
              </ContentButton>
            </CustomizeButton>
            <CustomizeButton 
              onClick={() => navigate(CDII)}
              centered={true}
              style={{backgroundColor : theme.colors.fourthly}}
            >
              <ContentButton>
                <ButtonTitle style={{color : theme.colors.neutral}}>{t('View.cdii.subtitle')}</ButtonTitle>
                <Icon icon='arrow-right' color={theme.colors.neutral} />
              </ContentButton>
            </CustomizeButton>
          </Navigate>
          <Informations>
            <Information>
              <Trans
                i18nKey="Homepage.info" // optional -> fallbacks to defaults if not provided
                components={{
                  bold : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.text}} />
                }}
              />
            </Information>
            <Row>
              <CustomizeCard 
                type='video' 
                onClick={() => setShowVideo(true)}
                style={size?.width && size?.width >= sizeMobile ? {marginRight : '1.5rem'} : {}}
              >
                <ContentCard>
                  <CardText>{t('Homepage.video')}</CardText>
                  <IconPlay width={size?.width && size?.width < sizeMobile ? `${calcRem(1.8)}rem` : `${calcRem(1.2)}rem`} color={theme.colors.neutral} />
                </ContentCard>
              </CustomizeCard>
              <CustomizeCard 
                type='methodo' 
                onClick={() => download(`files/Methodologie_illustree.pdf`)}
                style={size?.width && size?.width >= sizeMobile ? {marginLeft : '1.5rem'} : {}}
              >
                <ContentCard>
                  <CardText style={{marginLeft : '-4rem', maxWidth : size?.width && size?.width < sizeMobile ? `${calcRem(22.5)}rem` : `${calcRem(12.5)}rem`}}>{t('Homepage.methodo')}</CardText>
                  <IconDownload width={size?.width && size?.width < sizeMobile ? `${calcRem(2.5)}rem` : `${calcRem(1.5)}rem`} color={theme.colors.neutral} />
                </ContentCard>
              </CustomizeCard>
            </Row>
          </Informations>
        </Margin>
      </HomepageTemplate>
      {
        showVideo ?
        <Video>
          <CloseButton onClick={() => setShowVideo(false)} >
            <Close>{t('Homepage.close')}</Close>
            <IconClose height={"1.6rem"} width={"1.6rem"} color='white' />
          </CloseButton> 
          <div ref={playerRef}>
            <ReactPlayer 
              url=' https://youtu.be/oPS2XsGalPs' 
              playing={true}
              controls={true}
              width={`${calcRem(72)}rem`} 
              height={`${calcRem(40.8)}rem`} 
            />
          </div>
        </Video> : null
      }
    </>
  )
}

export default Homepage;

const Margin = styled.div`
  padding-left     : 12rem;
  padding-right    : 12rem;
  display          : flex;
  flex-direction   : column;
  flex             : 1 1 auto;
  background-color : rgb(244, 244, 248);
  height           : 0px;
  overflow         : auto;

  @media screen and (max-width : ${sizeMobile}px) {
    padding-left     : ${calcWidthMobileRem(2)}rem;
    padding-right    : ${calcWidthMobileRem(2)}rem;
    overflow-x: hidden;
  }
`;

const Header = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: space-between;
  height         : ${calcRem(29)}rem;

  @media screen and (max-width : ${sizeMobile}px) {
    height    : ${calcHeightMobileRem(50)}rem;
    flex-direction : column;
  }

`;

const Title = styled.div`
  font-size     : ${calcRem(3.2)}rem;
  width         : ${calcRem(39.2)}rem;
  margin-bottom : ${calcRem(2.4)}rem;
  line-height   : ${calcRem(3.2)}rem;
  margin-top    : ${calcRem(5)}rem;

  @media screen and (max-width : ${sizeMobile}px) {
    margin-top    : ${calcHeightMobileRem(3.5)}rem;
    font-size     : ${calcWidthMobileRem(2.1)}rem;
    line-height   : ${calcWidthMobileRem(2.6)}rem;
    width         : 100%;
  }
`;

const SubTitle = styled.div`
  font-size   : ${calcRem(1.4)}rem;
  width       : ${calcRem(41.2)}rem;
  font-family : work-sans-medium;
  line-height : ${calcRem(1.6)}rem;
  color       : #2A2A2A;

  @media screen and (max-width : ${sizeMobile}px) {
    font-size     : ${calcWidthMobileRem(1.2)}rem;
    line-height   : ${calcWidthMobileRem(1.5)}rem;
    margin-bottom : ${calcHeightMobileRem(1.5)}rem;
    width         : 100%;
  }
`;

const NavigateTitle = styled.div`
  text-transform : uppercase;
  font-size      : ${calcRem(1.2)}rem;
  text-align     : center; 
  letter-spacing : ${calcRem(0.3)}rem;
  color          : #3F3F3F;
  margin-bottom  : ${calcRem(0.5)}rem;
  font-family    : montserrat-regular;

  @media screen and (max-width : ${sizeMobile}px) {
    font-size      : ${calcRem(2)}rem;
  }
`;

const Navigate = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: space-between;
  height         : ${calcRem(8)}rem;
  border-top     : 1px solid #D0D0D9;
  border-bottom  : 1px solid #D0D0D9;
  background-color : rgb(244,244,248); 

  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction  : column;
    align-items     : flex-start;
    width           : 100%;
    height          : ${calcHeightMobileRem(20)}rem;
    padding-top     : ${calcHeightMobileRem(1)}rem;
    padding-bottom  : ${calcHeightMobileRem(1)}rem;
  }
`;

const Informations = styled.div`
  display        : flex;
  flex-direction : column;
  align-items    : center;

  @media screen and (max-width : ${sizeMobile}px) {
    align-items    : flex-start;
  }
`;

const Information = styled.div`
  font-size  : ${calcRem(1.4)}rem;
  text-align : center;
  width      : ${calcRem(66)}rem;
  margin-top : ${calcRem(2)}rem;
  line-height: ${calcRem(1.6)}rem;

  @media screen and (max-width : ${sizeMobile}px) {
    font-size  : ${calcWidthMobileRem(1.2)}rem;
    width      : 100%;
    line-height: ${calcWidthMobileRem(1.5)}rem;
  }
`;

const Row = styled.div`
  display        : flex;
  flex-direction : row;
  width          : 100%;
  margin-top     : ${calcRem(2.5)}rem; 

  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction : column;
    width          : 100%;
  }
`;

const ContentButton = styled.div`
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  width          : 90%;
`;

const ButtonTitle = styled.div`
  font-size      : ${calcRem(1.4)}rem;
  font-family    : work-sans-semiBold;
  color          : ${({theme}) => theme.colors.text};
  line-height    : ${calcRem(1.6)}rem;

  @media screen and (max-width : ${sizeMobile}px) {
    font-size      : ${calcHeightMobileRem(1.4)}rem;
  }

`;

const Left = styled.div`
  @media screen and (max-width : ${sizeMobile}px) {
    width : 100%
  }
`;

const Right = styled.div``;

const ContentCard = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: center;
  margin-top     : ${calcRem(15)}rem; 
  flex            : 1;

  @media screen and (max-width : ${sizeMobile}px) {
    margin-top     : -2rem;
    margin-left    : 0; 
    height         : ${calcRem(10)}rem; 
    width          : ${calcWidthMobileRem(15)}rem;
  }
`;

const CardText = styled.div`
  font-family  : work-sans-bold;
  font-size    : 1.4rem;
  margin-right : 1rem;
  text-align   : right; 
  color        : ${({theme}) => theme.colors.neutral};

  @media screen and (max-width : ${sizeMobile}px) {
    font-size    : ${calcWidthMobileRem(1.2)}rem;
    text-align   : right;
  }
`;

const Video = styled.div`
  position         : absolute;
  top              : 0;
  left             : 0;
  width            : 100%;
  height           : 100%;
  background-color : rgba(6, 57, 66, 0.9);
  padding-top      : ${calcRem(11)}rem; 
  display          : flex;
  flex-direction   : column;
  align-items      : center;
`;

const CloseButton = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  margin-bottom  : 2rem;
  cursor         : pointer;
`;

const Close = styled.div`
  font-size      : 1.2rem;
  font-family    : work-sans-regular;
  text-transform : uppercase;
  color          : white;
  letter-spacing : 0.2rem;
  margin-right   : 1rem;
`;

const ContainerAllIcons = styled.div`
  margin-bottom : ${calcRem(-10)}rem;
  width : ${calcRem(45.9)}rem;
  height : ${calcRem(32.9)}rem;
  @media screen and (max-width : ${sizeMobile}px) {
    margin-bottom : ${calcHeightMobileRem(-2)}rem;
    margin-left   : ${calcHeightMobileRem(-5)}rem;
    width : ${calcWidthMobileRem(30.9)}rem;
    height : ${calcWidthMobileRem(23.9)}rem;
  }
`;

const CustomizeButton = styled(Button)`
  height        : ${calcRem(4)}rem; 
  width         : 23.5%; 
  border-radius : 1rem; 

  @media screen and (max-width : ${sizeMobile}px) {
    width : 100%; 
    height : ${calcHeightMobileRem(4)}rem;
    margin : ${calcHeightMobileRem(0.6)}rem 0;
  }
`;

const CustomizeCard = styled(Card)`
  @media screen and (max-width : ${sizeMobile}px) {
    margin : 2rem 0;
    height  : ${calcWidthMobileRem(21)}rem; 
  }
`;