import React from 'react'
import { IconLabel } from '../../types';

import {IconJob} from '../../assets/svg-images/IconJob';
import {IconContract} from '../../assets/svg-images/IconContract';
import {IconActivity} from '../../assets/svg-images/IconActivity';
import {IconDemographic} from '../../assets/svg-images/IconDemographics';
import { IconRefresh } from '../../assets/svg-images/IconRefresh';

const defaultProps = {
  width : 30,
  height : 30,
  color : '#5572CC',
  secondaryColor : '#ffffff'
}

const Icon: React.FC<IconProps> = (props) => {
  switch (props.icon) {
    case 'overview':
      return <IconOverview {...props} />;
    case 'quarter':
      return <IconQuarter {...props} />;
    case 'avatar' : 
      return <IconAvatar {...props}/>;
    case 'logout' : 
      return <IconLogout {...props}/>;
    case 'three-dots':
      return <IconThreeDots {...props}/>;
    case 'list':
      return <IconList {...props} />;
    case 'oir':
      return <IconOIR {...props} />;
    case 'activity' :
      return <IconActivity {...props} />;
    case 'contract':
      return <IconContract {...props} />;
    case 'chart' : 
      return <IconChart {...props}/>;
    case 'indicators' : 
      return <IconChart {...props}/>;
    case 'demographic' : 
      return <IconDemographic {...props}/>;
    case 'job' : 
      return <IconJob {...props}/>;
    case 'tab-chart' : 
      return <IconTabChart {...props}/>;
    case 'tab-map' : 
      return <IconTabMap {...props}/>;
    case 'tab-info' : 
      return <IconTabInfo {...props}/>;
    case 'arrow-down' : 
      return <IconArrowDown {...props}/>;
    case 'arrow-up' : 
      return <IconArrowUp {...props}/>;
    case 'arrow-right' : 
      return <IconArrowRightDirection {...props}/>;
    case 'arrow-left' :
      return <IconArrowLeft {...props} />;
    case 'previous' : 
      return <IconArrowLeft {...props}/>;
    case 'next' : 
      return <IconArrowRightDirection {...props}/>;
    case 'checkmark-circle' : 
      return <IconCheckmarkCircle {...props}/>;
    case 'close-circle' : 
      return <IconCloseCircle {...props}/>;
    case 'close' : 
      return <IconClose {...props} />;
    case 'filter' : 
      return <IconFilter {...props}/>;
    case 'search' : 
      return <IconSearch {...props}/>;
    case 'logo' : 
      return <Logo {...props} />;
    case 'download-map' : 
      return <IconDownloadMap {...props} />;
    case 'download-file' : 
      return <IconDownload {...props} />;
    case 'zoom-in' : 
      return <IconZoomIn {...props} />;
    case 'zoom-out' : 
      return <IconZoomOut {...props} />;
    case 'top-login' : 
      return <IconTopLogin {...props} />;
    case 'bottom-login' : 
      return <IconBottomLogin {...props} />;
    case 'zoom-chart' : 
      return <IconZoomChart {...props} />;
    case 'filterButton' : 
      return <IconFilterButton {...props} />;
    case 'hamburger' :
      return <IconHamburger {...props} />;
    case 'refresh' : 
      return <IconRefresh {...props} />;
    default : 
      return <div></div>
  }
}

export default Icon;

export interface IconProps {
  icon   : IconLabel;
  width?  : number | string;
  height? : number | string;
  color?  : string;
  secondaryColor? : string;
}

// Icon.defaultProps = defaultProps;

const IconArrowLeft : React.FC<IconProps> = ({width=10, height=15.135, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 15.135">
  <path id="FILTRE_Open" data-name="FILTRE/Open" d="M1.786,0,7.568,6.214,13.35,0l1.786,1.909L7.568,10,0,1.909Z" transform="translate(10) rotate(90)" fill="#a4a2a0"/>
</svg>

)

const IconQuarter : React.FC<IconProps> = ({width=24, height=24, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 24 24">
  <g id="noun-hours-4298401" transform="translate(-6 -6)">
    <path id="Tracé_467" data-name="Tracé 467" d="M18,6a1,1,0,0,0,0,2A10,10,0,1,1,8,18a1,1,0,0,0-2,0A12,12,0,1,0,18,6Z" fill="#7fb351"/>
    <path id="Tracé_468" data-name="Tracé 468" d="M11,19h7a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6H11a1,1,0,0,0,0,2Z" fill="#7fb351"/>
  </g>
  </svg>
)

const IconList : React.FC<IconProps> = ({width=11, height=8, color, ...props}) => (
  <svg id="Group_2" data-name="Group 2" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 11 8">
  <rect id="Rectangle_Copy_10" data-name="Rectangle Copy 10" width="2" height="2" fill={color} />
  <rect id="Rectangle_Copy_12" data-name="Rectangle Copy 12" width="2" height="2" transform="translate(0 3)" fill={color} />
  <rect id="Rectangle_Copy_14" data-name="Rectangle Copy 14" width="2" height="2" transform="translate(0 6)" fill={color} />
  <rect id="Rectangle_Copy_11" data-name="Rectangle Copy 11" width="8" height="2" transform="translate(3)" fill={color} />
  <rect id="Rectangle_Copy_13" data-name="Rectangle Copy 13" width="8" height="2" transform="translate(3 3)" fill={color} />
  <rect id="Rectangle_Copy_15" data-name="Rectangle Copy 15" width="8" height="2" transform="translate(3 6)" fill={color} />
</svg>


)

const IconThreeDots : React.FC<IconProps> = ({width=11, height=10, color, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 11 10">
  <g id="Group" transform="translate(0 -0.4)">
    <path id="Icon_Path" data-name="Icon Path" d="M0,2A2,2,0,1,0,1,.268,2,2,0,0,0,0,2Z" transform="translate(3.5 0.4)" fill={color}/>
    <path id="Icon_Path_Copy" data-name="Icon Path Copy" d="M0,2A2,2,0,1,0,1,.268,2,2,0,0,0,0,2Z" transform="translate(0 6.4)" fill={color}/>
    <path id="Icon_Path_Copy_2" data-name="Icon Path Copy 2" d="M0,2A2,2,0,1,0,1,.268,2,2,0,0,0,0,2Z" transform="translate(7 6.4)" fill={color}/>
  </g>
</svg>

)

const IconOIR : React.FC<IconProps> = ({width=30.882, height=11.255, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 30.882 11.255">
  <g id="Group_3" data-name="Group 3" transform="translate(-0.19 -0.498)">
    <path id="Fill_1" data-name="Fill 1" d="M5.69,11.255A5.573,5.573,0,0,1,1.643,9.642,5.421,5.421,0,0,1,0,5.628,5.422,5.422,0,0,1,1.643,1.613,5.578,5.578,0,0,1,5.69,0,5.578,5.578,0,0,1,9.736,1.613,5.422,5.422,0,0,1,11.38,5.628,5.421,5.421,0,0,1,9.736,9.642,5.573,5.573,0,0,1,5.69,11.255ZM5.7,2.12A2.978,2.978,0,0,0,3.411,3.148a3.592,3.592,0,0,0-.93,2.487,3.561,3.561,0,0,0,.93,2.479A2.985,2.985,0,0,0,5.7,9.135,2.986,2.986,0,0,0,7.984,8.114a3.565,3.565,0,0,0,.931-2.479,3.6,3.6,0,0,0-.931-2.487A2.979,2.979,0,0,0,5.7,2.12Z" transform="translate(0.19 0.498)" fill="#00b3d4"/>
    <path id="Fill_27" data-name="Fill 27" d="M0,10.9H2.418V0H0Z" transform="translate(14.945 0.678)" fill="#fbbc15"/>
    <path id="Fill_37" data-name="Fill 37" d="M2.418,10.9H0V0H4.108A5.9,5.9,0,0,1,7.713.858,3.333,3.333,0,0,1,8.79,3.617,3.221,3.221,0,0,1,6.728,7l2.743,3.9L6.5,10.9l-2.4-3.477H2.418V10.9Zm0-8.809V5.317H4.186a2.6,2.6,0,0,0,1.689-.406,1.658,1.658,0,0,0,.451-1.286A1.385,1.385,0,0,0,5.86,2.417,2.97,2.97,0,0,0,4.233,2.09Z" transform="translate(21.6 0.677)" fill="#e5444a"/>
  </g>
  </svg>
)

const IconAvatar : React.FC<IconProps> = ({width=30, height=30, ...props}) => (
  <svg id="ICON_-_User" data-name="ICON - User" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 29 30">
    <g id="Icon_User" data-name="Icon User" transform="translate(0.597 1.187)">
      <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="13.5" cy="14" rx="13.5" ry="14" transform="translate(0.404 -0.187)" fill="#c2cdef"/>
      <path id="Icon_User-2" data-name="Icon User" d="M6.856,7.713A3.856,3.856,0,1,0,3,3.856,3.857,3.857,0,0,0,6.856,7.713Zm3.428.857H8.808a4.662,4.662,0,0,1-3.9,0H3.428A3.428,3.428,0,0,0,0,12v.428a1.286,1.286,0,0,0,1.285,1.285H12.426a1.286,1.286,0,0,0,1.285-1.285V12A3.428,3.428,0,0,0,10.284,8.57Z" transform="translate(6.943 6.759)" fill="#5572cc"/>
    </g>
    <rect id="Rectangle_778" data-name="Rectangle 778" width="29" height="30" fill="none"/>
  </svg>
)

const IconOverview : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 22.5 24">
    <g id="ICON_-Vue_D_ensemble-_Barre" data-name="ICON - Vue D&apos;ensemble - Barre" transform="translate(1.5)">
      <rect id="Rectangle_777" data-name="Rectangle 777" width="21" height="24" fill="none"/>
      <g id="Groupe_862" data-name="Groupe 862" transform="translate(-0.5 2.049)">
        <rect id="Rectangle_955" data-name="Rectangle 955" width="7.368" height="7.368" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <rect id="Rectangle_956" data-name="Rectangle 956" width="7.368" height="7.368" transform="translate(12.632)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <rect id="Rectangle_957" data-name="Rectangle 957" width="7.368" height="7.368" transform="translate(12.632 12.632)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <rect id="Rectangle_958" data-name="Rectangle 958" width="7.368" height="7.368" transform="translate(0 12.632)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      </g>
    </g>
  </svg>
)

const IconLogout : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg id="ICON_-_Logout" data-name="ICON - Logout" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 10 18 10">
    <g id="ICON_-_Logout-2" data-name="ICON - Logout" transform="translate(-17 -17)">
      <path id="Tracé_462" data-name="Tracé 462" d="M21,27V37a1,1,0,0,1-2,0V27a1,1,0,0,1,2,0Z" fill={color}/>
      <path id="Tracé_463" data-name="Tracé 463" d="M40.975,38.6l4.55-4.55h0c.05-.05.075-.1.125-.15.025-.025.025-.05.05-.075s.025-.075.05-.1a.15.15,0,0,0,.025-.1c0-.025.025-.05.025-.1s.025-.125.025-.175v-.025h0a.425.425,0,0,0-.025-.175c0-.025-.025-.05-.025-.075s-.025-.075-.025-.1c-.025-.025-.025-.075-.05-.1s-.025-.05-.05-.075a.663.663,0,0,0-.125-.15v-.025L40.975,28.1a1,1,0,1,0-1.425,1.4l2.85,2.85H35.1a1,1,0,0,0,0,2h7.325l-2.85,2.85a.967.967,0,0,0,0,1.4A.967.967,0,0,0,40.975,38.6Z" transform="translate(-11.325 -1.35)" fill={color}/>
    </g>
    <rect id="Rectangle_779" data-name="Rectangle 779" width={width} height={height} fill="none"/>
  </svg>
)

const IconChart : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg id="BARRE_-_Icon_Graph" data-name="BARRE - Icon Graph" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 20 21 20">
    <g id="Groupe_863" data-name="Groupe 863" transform="translate(-556.493 713.34)">
      <path id="Tracé_641" data-name="Tracé 641" d="M559.291-691.39v16.405H575.7" transform="translate(0 0)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Tracé_642" data-name="Tracé 642" d="M563.788-680.952l4.2-4.22,3.458,3.186,4.542-4.456" transform="translate(0.095 -0.625)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
)

const IconTabChart : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg id="Icon_-_Graph" data-name="Icon - Graph" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 21 21">
    <rect id="Rectangle_805" data-name="Rectangle 805" width={width} height={height} fill="none"/>
    <g id="Groupe_866" data-name="Groupe 866" transform="translate(-554.493 696.891)">
      <path id="Tracé_641" data-name="Tracé 641" d="M559.291-682.392h9" transform="translate(0.703 0)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Tracé_642" data-name="Tracé 642" d="M563.788-683.431l2.579-2.315L568.489-684l2.788-2.444" transform="translate(-3.105 -2.577)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
)

const IconTabMap : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg id="Icon_-_Carte" data-name="Icon - Carte" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 21 21">
    <path id="Icon_awesome-map" data-name="Icon awesome-map" d="M0,4.066V11.41a.339.339,0,0,0,.465.315l2.928-1.333V2.25L.427,3.436A.679.679,0,0,0,0,4.066Zm4.071,6.326L8.143,11.75V3.607L4.071,2.25Zm7.678-8.118L8.821,3.607V11.75l2.966-1.186a.678.678,0,0,0,.427-.63V2.59A.339.339,0,0,0,11.749,2.275Z" transform="translate(4.208 3.25)" fill={color}/>
    <rect id="Rectangle_805" data-name="Rectangle 805" width={width} height={height} fill="none"/>
  </svg>
)

const IconTabInfo : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" {...props} viewBox="0 0 1024 1024" width={width} height={height} >
    <path stroke={color} fill={color} d="M865.2,262.1c-51.2-71.7-123-126.7-205.8-156.9c-84.5-30.9-178.9-34-265.4-9.5  c-83.7,23.7-159.9,73.3-215.2,140.5c-57,69.2-91.4,154.3-98.3,243.8c-6.8,89,14.8,179.5,61,255.9c44.9,74.1,112.2,134.5,191.2,170.2  c83.5,37.7,177.3,48.2,267.1,29.9c85.2-17.4,164.5-61.6,224.5-124.3c60.6-63.3,101.7-145,115-231.7c3.4-22.4,5.3-45,5.4-67.6V512  C944.6,423,917,334.6,865.2,262.1z M458.4,239.7c18-22.1,50.3-30.7,77-20.9c27.7,10.2,46.2,35.9,46.9,65.4c0,0.3,0,0.6,0,0.9v0.2  c0,0.3,0,0.6,0,0.9c-0.9,32.1-23.8,61.7-56,67.7c-29.3,5.5-59.3-7.1-74.8-32.9C436.2,295.2,439.7,262.7,458.4,239.7z M596,714.6  v49.2c0,8.1-6.9,15-15,15H443.1c-8.1,0-15-6.9-15-15v-50.2c0-8.1,6.9-15,15-15h15.8V495h-15.8c-8.1,0-15-6.9-15-15v-50.2  c0-8.1,6.9-15,15-15h107.1c8.1,0,15,6.9,15,15v268.8H581c8.1-0.4,15,7.2,15,15C596,714,596,714.3,596,714.6z"/>
  </svg>
)

const IconArrowDown : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg id="Dropdown" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 9.4 6.1">
    <path id="Path_36" data-name="Path 36" d="M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z" transform="translate(-2 -2)" fill={color}/>
  </svg>
)

const IconArrowUp : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 9.4 6.1">
    <g id="Dropdown" transform="translate(9.4 6.1) rotate(180)">
      <path id="Path_36" data-name="Path 36" d="M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z" transform="translate(-2 -2)" fill={color}/>
    </g>
  </svg>
)

const IconArrowRightDirection : React.FC<IconProps> = ({width=10, height=15.135, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 15.135">
  <path id="FILTRE_Open" data-name="FILTRE/Open" d="M1.786,0,7.568,6.214,13.35,0l1.786,1.909L7.568,10,0,1.909Z" transform="translate(0 15.135) rotate(-90)" fill={color} />
</svg>

)

const IconLeftDirection : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg id="ICON_-_Precedent" data-name="ICON - Precedent" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 11.725 11.1">
    <path id="Tracé_463" data-name="Tracé 463" d="M40.975,38.6l4.55-4.55h0c.05-.05.075-.1.125-.15.025-.025.025-.05.05-.075s.025-.075.05-.1a.15.15,0,0,0,.025-.1c0-.025.025-.05.025-.1s.025-.125.025-.175v-.025h0a.425.425,0,0,0-.025-.175c0-.025-.025-.05-.025-.075s-.025-.075-.025-.1c-.025-.025-.025-.075-.05-.1s-.025-.05-.05-.075a.663.663,0,0,0-.125-.15v-.025L40.975,28.1a1,1,0,1,0-1.425,1.4l2.85,2.85H35.1a1,1,0,0,0,0,2h7.325l-2.85,2.85a.967.967,0,0,0,0,1.4A.967.967,0,0,0,40.975,38.6Z" transform="translate(45.825 38.9) rotate(-180)" fill={color} />
  </svg>
)

const IconCheckmarkCircle : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 29.25 29.25">
    <path id="Icon_ionic-ios-checkmark-circle" data-name="Icon ionic-ios-checkmark-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm7.488,10.582-9.4,9.443H16.08a1.27,1.27,0,0,1-.816.387,1.231,1.231,0,0,1-.823-.4L10.5,19.448a.28.28,0,0,1,0-.4L11.756,17.8a.272.272,0,0,1,.394,0l3.122,3.122,8.578-8.641a.278.278,0,0,1,.2-.084h0a.255.255,0,0,1,.2.084l1.23,1.273A.277.277,0,0,1,25.488,13.957Z" transform="translate(-3.375 -3.375)" fill={color} />
  </svg>
)

const IconCloseCircle : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 29.25 29.25">
    <path id="close-circle" data-name="Icon ionic-ios-close-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm3.705,19.92L18,19.589l-3.705,3.705a1.124,1.124,0,1,1-1.589-1.589L16.411,18l-3.705-3.705a1.124,1.124,0,0,1,1.589-1.589L18,16.411l3.705-3.705a1.124,1.124,0,1,1,1.589,1.589L19.589,18l3.705,3.705a1.129,1.129,0,0,1,0,1.589A1.116,1.116,0,0,1,21.705,23.295Z" transform="translate(-3.375 -3.375)" fill={color}/>
  </svg>
)

const IconRedirectChart : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 10.657 16.423">
    <g id="Dropdown" transform="translate(-702 699.423) rotate(-90)">
      <path id="Path_36" data-name="Path 36" d="M10.211,12.657,2,4.446,4.446,2l5.765,5.765L15.977,2l2.446,2.446Z" transform="translate(681 700)" fill={color}/>
    </g>
  </svg>
)

const IconFilter : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 15.351 14.3">
    <g id="noun_Settings_1664551" transform="translate(17.851 -5.8) rotate(90)">
      <g id="Groupe_564" data-name="Groupe 564" transform="translate(5.8 2.5)">
        <path id="Tracé_447" data-name="Tracé 447" d="M74.346,12.373A1.958,1.958,0,0,0,72.908,10.5V2.985a.485.485,0,0,0-.97,0V10.5A1.929,1.929,0,0,0,70.5,12.373a1.958,1.958,0,0,0,1.438,1.874v3.119a.485.485,0,0,0,.97,0V14.231A1.925,1.925,0,0,0,74.346,12.373Zm-1.939.953a.953.953,0,1,1,.953-.953A.945.945,0,0,1,72.407,13.326Z" transform="translate(-60.045 -2.5)" fill={color} />
        <path id="Tracé_448" data-name="Tracé 448" d="M40.506,6.12V2.985a.485.485,0,1,0-.97,0V6.12A1.929,1.929,0,0,0,38.1,7.994a1.889,1.889,0,0,0,1.438,1.858v7.514a.485.485,0,1,0,.97,0V9.852a1.944,1.944,0,0,0,1.438-1.874A1.889,1.889,0,0,0,40.506,6.12Zm-.485,2.812a.953.953,0,1,1,.953-.953A.945.945,0,0,1,40.021,8.931Z" transform="translate(-32.879 -2.5)" fill={color} />
        <path id="Tracé_449" data-name="Tracé 449" d="M8.208,10.5V2.985a.485.485,0,0,0-.97,0V10.5A1.929,1.929,0,0,0,5.8,12.373a1.958,1.958,0,0,0,1.438,1.874v3.119a.485.485,0,1,0,.97,0V14.231a1.93,1.93,0,0,0,0-3.733Zm-.485,2.828a.953.953,0,1,1,.953-.953A.955.955,0,0,1,7.723,13.326Z" transform="translate(-5.8 -2.5)" fill={color} />
      </g>
    </g>
  </svg>
)

const IconSearch : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 13.296 13.298">
    <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M13.115,11.5,10.526,8.908a.623.623,0,0,0-.442-.182H9.661a5.4,5.4,0,1,0-.935.935v.423a.623.623,0,0,0,.182.442L11.5,13.115a.621.621,0,0,0,.88,0l.735-.735a.626.626,0,0,0,0-.883ZM5.4,8.726A3.324,3.324,0,1,1,8.726,5.4,3.322,3.322,0,0,1,5.4,8.726Z" fill={color}/>
  </svg>
)

const Logo : React.FC<IconProps> = ({width=30, height=30, ...props}) => (
  <svg id="g841" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 100 127.62">
    <g id="g1447">
      <path id="path1133" d="M286.107-301.156h-9.751v25.066c0,24.713,4.724,31.283,15.9,37.871l.218-.248c-5.4-6.153-6.367-18.328-6.367-35.155v-27.535" transform="translate(-231.47 365.838)" fill="#767676"/>
      <path id="path1145" d="M299.561-384.466H276.356v42.478c4.187,0,8,0,9.751,0v-34.573h11.965c11.927,0,20.279,1.651,24.781,6.476,2.761,2.96,4.337,8.2,3.936,13.342A21.014,21.014,0,0,0,328.2-362.7c0,.034,0-.038,0,0-.983-17.228-10.479-21.764-28.642-21.764" transform="translate(-231.469 396.477)" fill="#876133"/>
      <path id="path1167" d="M276.358-384.462v42.478h9.751v-34.573H298.07c11.927,0,20.283,1.651,24.785,6.479h0c2.761,2.956,4.337,8.2,3.936,13.338h0A21.011,21.011,0,0,0,328.2-362.7h0c0-.007,0-.015,0,0h0c-.983-17.228-10.475-21.761-28.642-21.761h-23.2Z" transform="translate(-231.47 396.476)" fill="#767676"/>
      <path id="path1177" d="M286.109-296.212h-9.751v-4.945h9.751Z" transform="translate(-231.47 365.839)" fill="#404040"/>
      <path id="path1181" d="M314.54-384.374l-2.495-19.089H230.323v2.979a94.435,94.435,0,0,1,9.6,2.84c4.18,1.79,5.118,4.892,5.118,16.79v73.093c0,11.976-.953,15.041-5.2,16.831a92.58,92.58,0,0,1-9.522,2.8v2.979H254.8v-110.4h27.614c11.8,0,23.787,1.673,28.69,11.181h3.437" transform="translate(-214.54 403.464)" fill="#fff"/>
      <path id="path1183" d="M263.363-296.212h-9.751v-4.945h9.751Z" transform="translate(-223.105 365.839)" fill="#d3d3d3"/>
      <path id="path1195" d="M296.2-330.375c-4.529,5.632-13.233,11.241-35.327,11.241H235.078c-17.93,0-26.33,6.074-29.392,18.605h-.326c-.075-14.726,10.76-28.8,30.183-28.8,5.418,0,22.421-.007,25.107,0,19.7.034,31.969-2.277,38.182-11.255a20.2,20.2,0,0,0,3.257-9.462,27.224,27.224,0,0,1-5.89,19.667" transform="translate(-205.359 383.817)" fill="#004d6d"/>
      <path id="path1217" d="M298.838-340.578c-6.217,8.974-18.485,11.285-38.19,11.252h0c-2.683,0-19.686,0-25.1,0h0c-19.427,0-30.259,14.073-30.184,28.8h.323c3.065-12.535,11.466-18.605,29.4-18.605h25.794c22.095,0,30.8-5.609,35.327-11.24h0a27.221,27.221,0,0,0,5.89-19.671h0a20.311,20.311,0,0,1-3.253,9.466" transform="translate(-205.36 383.818)" fill="#bababa"/>
    </g>
  </svg>
)

const IconClose : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 9.936 9.936">
    <g id="Groupe_728" data-name="Groupe 728" transform="translate(-1295.691 -93.277)">
      <path id="Tracé_495" data-name="Tracé 495" d="M1297.105,15951.691l7.108,7.107" transform="translate(0 -15857)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2"/>
      <path id="Tracé_496" data-name="Tracé 496" d="M0,0,7.108,7.108" transform="translate(1304.213 94.691) rotate(90)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2"/>
    </g>
  </svg>
)

const IconDownloadMap : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width}height={height} {...props} viewBox="0 0 14.79 14.79">
    <path id="Tracé_66" data-name="Tracé 66" d="M6.009,4.5H16.574a1.509,1.509,0,0,1,1.509,1.509V16.574a1.509,1.509,0,0,1-1.509,1.509H6.009A1.509,1.509,0,0,1,4.5,16.574V6.009A1.509,1.509,0,0,1,6.009,4.5Z" transform="translate(-4 -4)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    <path id="Tracé_68" data-name="Tracé 68" d="M19.574,18.773,15.8,15,7.5,23.3" transform="translate(-5.491 -9.218)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    <path id="Tracé_69" data-name="Tracé 69" d="M18,21.941V18" transform="translate(-13.118 -14.41)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    <path id="Tracé_70" data-name="Tracé 70" d="M13.5,22.5h3.941" transform="translate(-10.588 -16.939)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
  </svg>
);

const IconDownloadFile : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 13.267 16.334">
    <g id="Icon_feather-file-plus" data-name="Icon feather-file-plus" transform="translate(-5.5 -2.5)">
      <path id="Tracé_62" data-name="Tracé 62" d="M13.667,3H7.533A1.533,1.533,0,0,0,6,4.533V16.8a1.533,1.533,0,0,0,1.533,1.533h9.2A1.533,1.533,0,0,0,18.267,16.8V7.6Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <path id="Tracé_63" data-name="Tracé 63" d="M21,3V7.6h4.6" transform="translate(-7.333)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <path id="Tracé_64" data-name="Tracé 64" d="M18,22.6V18" transform="translate(-5.866 -7.333)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <path id="Tracé_65" data-name="Tracé 65" d="M13.5,22.5h4.6" transform="translate(-3.667 -9.533)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    </g>
  </svg>
);

const IconZoomIn : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 17.414 17.414">
    <g id="Icon_feather-zoom-in" data-name="Icon feather-zoom-in" transform="translate(-3.5 -3.5)">
      <path id="Tracé_645" data-name="Tracé 645" d="M17.882,11.191A6.691,6.691,0,1,1,11.191,4.5a6.691,6.691,0,0,1,6.691,6.691Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Tracé_646" data-name="Tracé 646" d="M28.495,28.495l-3.52-3.52" transform="translate(-8.995 -8.995)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Tracé_647" data-name="Tracé 647" d="M16.5,12v4.855" transform="translate(-5.333 -3.273)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Tracé_648" data-name="Tracé 648" d="M12,16.5h4.855" transform="translate(-3.273 -5.333)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
);

const IconZoomOut : React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 17.414 17.414">
    <g id="Icon_feather-zoom-out" data-name="Icon feather-zoom-out" transform="translate(-3.5 -3.5)" >
      <path id="Tracé_649" data-name="Tracé 649" d="M17.882,11.191A6.691,6.691,0,1,1,11.191,4.5a6.691,6.691,0,0,1,6.691,6.691Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Tracé_650" data-name="Tracé 650" d="M28.495,28.495l-3.52-3.52" transform="translate(-8.995 -8.995)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Tracé_651" data-name="Tracé 651" d="M12,16.5h4.855" transform="translate(-3.273 -5.333)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
);

const IconTopLogin : React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="357" height="48" {...props} viewBox="0 0 357 48">
    <rect id="Rectangle_963" data-name="Rectangle 963" width="357" height="9" fill="#5572cc"/>
    <rect id="Rectangle_964" data-name="Rectangle 964" width="263" height="10" transform="translate(0 9)" fill="#ff8d7e"/>
    <rect id="Rectangle_965" data-name="Rectangle 965" width="288" height="9" transform="translate(0 19)" fill="#0c7db7"/>
    <rect id="Rectangle_966" data-name="Rectangle 966" width="135" height="10" transform="translate(0 28)" fill="#7a54ba"/>
    <rect id="Rectangle_971" data-name="Rectangle 971" width="241" height="10" transform="translate(0 38)" fill="#03a580"/>
  </svg>
)

const IconBottomLogin : React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="288" height="48" {...props} viewBox="0 0 288 48">
    <rect id="Rectangle_970" data-name="Rectangle 970" width="225" height="9" transform="translate(288 48) rotate(180)" fill="#7a54ba"/>
    <rect id="Rectangle_969" data-name="Rectangle 969" width="162" height="10" transform="translate(288 39) rotate(180)" fill="#0c7db7"/>
    <rect id="Rectangle_968" data-name="Rectangle 968" width="288" height="9" transform="translate(288 29) rotate(180)" fill="#ff8d7e"/>
    <rect id="Rectangle_967" data-name="Rectangle 967" width="203" height="10" transform="translate(288 20) rotate(180)" fill="#5572cc"/>
    <rect id="Rectangle_972" data-name="Rectangle 972" width="223" height="10" transform="translate(288 10) rotate(180)" fill="#03a580"/>
  </svg>
)

const IconZoomChart : React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" {...props} viewBox="0 0 30 30">
    <rect id="Rectangle_578" data-name="Rectangle 578" width="27" height="27" transform="translate(0 0)" fill="#54587e"/>
    <path id="Tracé_89" data-name="Tracé 89" d="M14.658,9.579A5.079,5.079,0,1,1,9.579,4.5a5.079,5.079,0,0,1,5.079,5.079Z" transform="translate(3 3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    <path id="Tracé_90" data-name="Tracé 90" d="M27.737,27.737l-2.762-2.762" transform="translate(-8.809 -8.809)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    <path id="Tracé_91" data-name="Tracé 91" d="M16.5,12v3.809" transform="translate(-3.921 -1.326)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    <path id="Tracé_92" data-name="Tracé 92" d="M12,16.5h3.809" transform="translate(-1.326 -3.921)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
  </svg>
)


const IconDownload : React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.619" height="16.611" {...props} viewBox="0 0 16.619 16.611">
    <path id="noun-download-2176275" d="M8.3,0a.593.593,0,0,0-.584.593V.6h0V11.029L3.983,7.3a.593.593,0,1,0-.838.839L7.889,12.88l.007.006h0l.018.017h0l.024.021h0l.016.011h0l.028.02,0,0,.021.013,0,0,.024.014,0,0,.021.01,0,0,.024.011,0,0,.026.009h0l.021.007h0l.026.007h0l.024.005h0l.025,0h0l.028,0h.061l.053,0h0l.041-.007h0l.032-.007h0l.026-.007h0L8.5,13.02h0l.025-.009,0,0L8.557,13l0,0,.048-.024,0,0,0,0,0,0,.036-.022,0,0,.025-.017h0l.039-.031h0l0,0h0l.028-.024L13.47,8.138a.593.593,0,0,0-.413-1.018H13.04a.592.592,0,0,0-.408.179h0L8.9,11.03V.6A.593.593,0,0,0,8.308,0H8.3ZM.6,15.425H.591a.593.593,0,1,0,0,1.186H16.024a.593.593,0,0,0,0-1.186H.6Z" transform="translate(0.002)" fill="#fff"/>
  </svg>  
);

const IconFilterButton: React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 23">
    <g id="Groupe_35" data-name="Groupe 35" transform="translate(0 3)">
      <path id="Tracé_18" data-name="Tracé 18" d="M0,.5H24" fill="none" stroke="#fff" stroke-width="2"/>
    </g>
    <g id="Groupe_36" data-name="Groupe 36" transform="translate(0 19)">
      <path id="Tracé_19" data-name="Tracé 19" d="M0,.5H24" fill="none" stroke="#fff" stroke-width="2"/>
    </g>
    <g id="Groupe_37" data-name="Groupe 37" transform="translate(0 11)">
      <path id="Tracé_20" data-name="Tracé 20" d="M0,.5H24" fill="none" stroke="#fff" stroke-width="2"/>
    </g>
    <g id="Groupe_38" data-name="Groupe 38" transform="translate(15.062 17)">
      <path id="Tracé_25" data-name="Tracé 25" d="M2.5,5A2.5,2.5,0,1,0,0,2.5,2.5,2.5,0,0,0,2.5,5Z" fill="none" stroke="#fff" stroke-width="2"/>
    </g>
    <g id="Groupe_39" data-name="Groupe 39" transform="translate(15.062 1)">
      <path id="Tracé_30" data-name="Tracé 30" d="M2.5,5A2.5,2.5,0,1,0,0,2.5,2.5,2.5,0,0,0,2.5,5Z" fill="none" stroke="#fff" stroke-width="2"/>
    </g>
    <g id="Groupe_40" data-name="Groupe 40" transform="translate(5.062 9)">
      <path id="Tracé_35" data-name="Tracé 35" d="M2.5,5A2.5,2.5,0,1,0,0,2.5,2.5,2.5,0,0,0,2.5,5Z" fill="none" stroke="#fff" stroke-width="2"/>
    </g>
    <path id="Tracé_40" data-name="Tracé 40" d="M98-30a2,2,0,0,0,2-2,2,2,0,0,0-2-2,2,2,0,0,0-2,2A2,2,0,0,0,98-30Z" transform="translate(-80.438 35.5)" fill={color} fill-rule="evenodd"/>
    <path id="Tracé_41" data-name="Tracé 41" d="M98-30a2,2,0,0,0,2-2,2,2,0,0,0-2-2,2,2,0,0,0-2,2A2,2,0,0,0,98-30Z" transform="translate(-90.438 43.5)" fill={color} fill-rule="evenodd"/>
    <path id="Tracé_42" data-name="Tracé 42" d="M98-30a2,2,0,0,0,2-2,2,2,0,0,0-2-2,2,2,0,0,0-2,2A2,2,0,0,0,98-30Z" transform="translate(-80.438 51.5)" fill={color} fill-rule="evenodd"/>
  </svg>
)

const IconHamburger: React.FC<IconProps> = ({width=30, height=30, color="#5572CC", ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 23.4 18.6">
    <g id="Groupe_1391" data-name="Groupe 1391" transform="translate(-328.062 -36.09)">
      <line id="Path-2" x1="23.4" transform="translate(328.062 37.59)" fill="none" stroke={color} stroke-width="3"/>
      <line id="Path-2-Copy" x1="23.4" transform="translate(328.062 45.39)" fill="none" stroke={color} stroke-width="3"/>
      <line id="Path-2-Copy-2" x1="23.4" transform="translate(328.062 53.19)" fill="none" stroke={color} stroke-width="3"/>
    </g>
  </svg>
)