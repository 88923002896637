import { IconProps } from "../../types";

export const IconDemographic : React.FC<IconProps> = ({width=34.904, height=26.012, color='00b3d4', ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 34.904 26.012">
  <g id="Group_6" data-name="Group 6" transform="translate(0.5 0.476)">
    <path id="Stroke_1" data-name="Stroke 1" d="M8.974,0A5.029,5.029,0,0,1,4.487,2.724,5.029,5.029,0,0,1,0,0H0" transform="translate(3.846 12.055)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_9" data-name="Stroke 9" d="M0,0A8.09,8.09,0,0,1,4.968,7.433v4.586" transform="translate(12.019 11.414)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_11" data-name="Stroke 11" d="M0,10.737V7.421A8.126,8.126,0,0,1,4.808,0" transform="translate(0 11.414)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_29_Copy" data-name="Stroke 29 Copy" d="M0,0A8.119,8.119,0,0,1,4.808,7.424v3.313" transform="translate(29.096 11.735)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_31_Copy" data-name="Stroke 31 Copy" d="M14.583,13.3H0V7.445A8.144,8.144,0,0,1,4.816,0" transform="translate(16.917 11.735)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_37" data-name="Stroke 37" d="M12.34,6.235a6.17,6.17,0,1,1-1.018-3.46" transform="translate(2.244 0.196)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_41_Copy" data-name="Stroke 41 Copy" d="M2.391,3.017a13.178,13.178,0,0,0,9.224-.169A6.329,6.329,0,1,0,1.046,9.814a6.121,6.121,0,0,0,.974,1.147,6.324,6.324,0,0,0,7.792.654,5.866,5.866,0,0,0,.828-.654A6.308,6.308,0,0,0,12.66,6.4" transform="translate(19.16 0.036)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_45_Copy" data-name="Stroke 45 Copy" d="M5.17,0,2.585,3.8,0,0" transform="translate(22.905 14.299)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_47" data-name="Stroke 47" d="M0,2.083V0" transform="translate(4.647 1.639)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_49" data-name="Stroke 49" d="M0,2.724V0" transform="translate(6.09 0.837)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_51" data-name="Stroke 51" d="M0,3.205V0" transform="translate(7.532 0.357)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_53" data-name="Stroke 53" d="M0,3.365V0" transform="translate(8.974 0.357)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_55" data-name="Stroke 55" d="M0,3.045V0" transform="translate(10.417 0.677)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_57" data-name="Stroke 57" d="M0,2.083V0" transform="translate(12.019 1.478)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_59" data-name="Stroke 59" d="M0,0V2.244" transform="translate(8.333 6.286)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_73" data-name="Stroke 73" d="M1.923,1.442V0L0,1.442" transform="translate(12.019 17.023)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_75" data-name="Stroke 75" d="M3.686,0h0Z" transform="translate(11.218 18.72)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_79" data-name="Stroke 79" d="M0,0C0,1.954.232,4.167,2.083,4.167" transform="translate(14.583 5.805)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_80" data-name="Stroke 80" d="M2.083,0c0,1.954-.232,4.167-2.083,4.167" transform="translate(0.16 5.805)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_81_Copy" data-name="Stroke 81 Copy" d="M0,0V2.244" transform="translate(25.41 6.446)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_84_Copy" data-name="Stroke 84 Copy" d="M5.929,0A4.069,4.069,0,0,1,0,0" transform="translate(22.526 9.812)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_86" data-name="Stroke 86" d="M5.929,0A4.069,4.069,0,0,1,0,0" transform="translate(5.449 9.491)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  </g>
</svg>
)