import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';  
import { RootState, Filters } from '../../types';

import SelectedElement from '../atoms/SelectedElement';

interface FiltersChipsProps {
  filters : Filters;
}

const FiltersChips: React.FC<FiltersChipsProps> = ({filters={}}) => {
  const [items, setItems] = useState<any[]>([]);
  const [national, setNational] = useState<any>({});

  useEffect(() => {
    const isNational = !!filters?.regions?.selected || !!filters?.departements?.selected || !!filters?.intercos?.selected ? {} : {data : [{label : 'National', value : 'n0'}], selected : '', defaultValue : 'n0'}
    setItems([{type : 'year', ...filters?.years}, {type : 'quarterly', ...filters?.quarterly}, {type : 'national', isNational}, {type : 'regions', ...filters?.regions}, {type : 'departements', ...filters?.departements}, {type : 'intercos', ...filters?.intercos}])
    setNational(isNational)
  }, [filters])
  
  
  return (
    <Container>
      {
        items.map(({selected, data, defaultValue, type}, index: number) => {
          const item: any = data?.find(({value}: {value: string}) => {
            if(selected){
              return value === selected  
            } else {
              return value === defaultValue
            }
          });
          if(item?.label){
            return (
              <SelectedElement key={`${item.label}${index}`} text={item?.label} />
            )
          } else {
            return null
          }
        })
      }
    </Container>
  )
}

export default FiltersChips;

const Container = styled.div`
  display          : flex;
  flex-direction   : row;
  margin-left      : 1rem;
`;