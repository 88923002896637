import React, {useState, useEffect, useRef} from 'react';


function useOutsideClick(ref : any, handleEvent : any) {
    useEffect(() => {
        function handleClickOutside(event : any) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleEvent()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default useOutsideClick;