import React, { useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { sizeMobile } from '../utils/constants';

import useWindowSize from '../hooks/useWindowSize';

import TitlePage from '../components/atoms/TitlePage';
// import DownloadButton from '../components/DownloadButton';
import { i18next, t } from '../i18n';
import { Trans } from 'react-i18next';
import { theme } from '../assets/theme';
import Icon from '../components/atoms/Icon';
import HomepageTemplate from '../components/templates/Homepage';


const Methodologie : React.FC<any> = ({history, onClose}) => {
  const size = useWindowSize();
  const contentHeight = (size.height || 0) - 160 - 65; //160 height of top & 65 height of footer
  const scrollRef = useRef<any>(null);

  const scrollToTop = () => {
    if(scrollRef?.current){
      scrollRef?.current?.scrollTo(0,0);
    }
  }

  return (
    <HomepageTemplate>
      <Container>
        <Content heightContent={contentHeight} ref={scrollRef} >
          <div style={{marginTop : '1rem'}}>
            { size.width && <Trans 
              i18nKey='Method.title'
              components={
                {
                  font : <div style={{fontFamily : 'work-sans-regular'}} />,
                  boldcolor : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                  h1 : <h1 style={{fontSize : size.width && size.width < sizeMobile ? "3rem" : "2.2rem", textAlign : "center"}} />,
                }
              }
            />}
          </div>
          <Section>
            { size.width && <Trans 
              i18nKey='Method.introduction'
              components={
                {
                  font : <div style={{fontFamily : 'work-sans-regular'}} />,
                  boldcolor : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                  bold : <strong style={{fontFamily : "work-sans-semiBold"}} />, 
                  h1 : <h1 style={{fontSize : "2rem", textAlign : "center"}} />,
                  h2 : <h2 style={{fontSize : size.width && size.width < sizeMobile ? "2.5rem" : "1.7rem"}} />,
                  ul : <List />,
                  li : <li />,
                  p : <p style={{lineHeight : "2.5rem", fontSize : size.width && size.width < sizeMobile ? "1.9rem" : "1.5rem", marginTop : "0.5rem"}} />,
                  underline : <span style={{textDecoration : 'underline'}} />
                }
              }
            />}
          </Section>
          <Section>
            { size.width && <Trans 
              i18nKey='Method.source'
              components={
                {
                  font : <div style={{fontFamily : 'work-sans-regular'}} />,
                  boldcolor : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                  bold : <strong style={{fontFamily : "work-sans-semiBold"}} />, 
                  h1 : <h1 style={{fontSize : "2rem", textAlign : "center"}} />,
                  h2 : <h2 style={{fontSize : size.width && size.width < sizeMobile ? "2.5rem" : "1.7rem"}} />,
                  ul : <List />,
                  li : <li />,
                  p : <p style={{lineHeight : "2.5rem", fontSize : size.width && size.width < sizeMobile ? "1.9rem" : "1.5rem", marginTop : "0.5rem"}} />,
                  underline : <span style={{textDecoration : 'underline'}} />,
                  br : <br />
                }
              }
            />}
          </Section>
          <Section>
            { size.width && <Trans 
              i18nKey='Method.presentation'
              components={
                {
                  font : <div style={{fontFamily : 'work-sans-regular'}} />,
                  boldcolor : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                  bold : <strong style={{fontFamily : "work-sans-semiBold"}} />, 
                  h1 : <h1 style={{fontSize : "2rem", textAlign : "center"}} />,
                  h2 : <h2 style={{fontSize : size.width && size.width < sizeMobile ? "2.5rem" : "1.7rem"}} />,
                  ul : <List />,
                  li : <li />,
                  p : <p style={{lineHeight : "2.5rem", fontSize : size.width && size.width < sizeMobile ? "1.9rem" : "1.5rem", marginTop : "0.5rem"}} />,
                  underline : <span style={{textDecoration : 'underline'}} />,
                  br : <br />,
                  a1 : <a href="https://www.insee.fr/fr/information/6205305" target="_blank" style={{fontFamily : "work-sans-semiBold", color : theme.colors.text}} />,
                  a2 : <a href="https://www.insee.fr/fr/information/2406147" target="_blank" style={{fontFamily : "work-sans-semiBold", color : theme.colors.text}} />
                }
              }
            />}
          </Section>
          <Section>
            { size.width && <Trans 
              i18nKey='Method.features'
              components={
                {
                  font : <div style={{fontFamily : 'work-sans-regular'}} />,
                  boldcolor : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                  bold : <strong style={{fontFamily : "work-sans-semiBold"}} />, 
                  h1 : <h1 style={{fontSize : "2rem", textAlign : "center"}} />,
                  h2 : <h2 style={{fontSize : size.width && size.width < sizeMobile ? "2.5rem" : "1.7rem"}} />,
                  ul : <List />,
                  li : <li />,
                  p : <p style={{lineHeight : "2.5rem", fontSize : size.width && size.width < sizeMobile ? "1.9rem" : "1.5rem", marginTop : "0.5rem"}} />,
                  underline : <span style={{textDecoration : 'underline'}} />,
                  br : <br />
                }
              }
            />}
          </Section>
          <Section>
            { size.width && <Trans 
              i18nKey='Method.limitation'
              components={
                {
                  font : <div style={{fontFamily : 'work-sans-regular'}} />,
                  boldcolor : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                  bold : <strong style={{fontFamily : "work-sans-semiBold"}} />, 
                  h1 : <h1 style={{fontSize : "2rem", textAlign : "center"}} />,
                  h2 : <h2 style={{fontSize : size.width && size.width < sizeMobile ? "2.5rem" : "1.7rem"}} />,
                  ul : <List />,
                  li : <li />,
                  p : <p style={{lineHeight : "2.5rem", fontSize : size.width && size.width < sizeMobile ? "1.9rem" : "1.5rem", marginTop : "0.5rem"}} />,
                  underline : <span style={{textDecoration : 'underline'}} />,
                  br : <br />
                }
              }
            />}
          </Section>
          <Footer style={{margin : "3rem 0"}} >
            <ScrollToTopButton onClick={scrollToTop}>
              <Icon icon='arrow-up' color="black" width={15} />
              <Button>Haut</Button>
              <Button>de Page</Button>
            </ScrollToTopButton>
          </Footer>
        </Content>
      </Container>
    </HomepageTemplate>
  )
}

export default Methodologie;

const Container = styled.div`
  flex             : 1 1 auto;
  display          : flex;
  flex-direction   : column;
  background-color : rgb(244, 244, 248);
  position         : relative;
  padding          : 2rem 0rem;
  @media screen and (min-width : ${sizeMobile}px) {
    flex             : 1 1 0%;
    min-height     : 85vh;
    padding          : 5rem 5rem;
  }
  
`;
const Content = styled.div<any>`
  height : ${({heightContent}) => `${heightContent}px`}; 
  @media screen and (min-width : ${sizeMobile}px) {
    height : 0px;
  }
  display          : flex;
  flex-direction   : column;
  flex             : 1 1 auto;
  background-color : ${({theme}) => theme.colors.neutral};
  overflow         : auto;
  padding          : 3rem;
  position         : relative;
`;

const List = styled.ul`
  list-style: none;
  li::before {
    content: "•"; 
    color: ${({theme}) => theme.colors.primary};
    display: inline-block; 
    width: 2rem;
  }
`;

const Section = styled.div`
  margin-top : 5rem;
`;

const Footer = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : flex-end;
`;

const ScrollToTopButton = styled.div`
  bottom         : 0rem; 
  display        : flex;
  flex-direction : column;
  align-items    : center;
  cursor         : pointer;
`;

const Button = styled.div`
  font-family : work-sans-semiBold;
  font-size : 1.5rem;
`;

const CloseButton = styled.div`
  position : absolute;
  top      : 1.5rem;
  right    : 2rem;
  cursor   : pointer;
`;