export const loginRequested = (username : string, password : string) => ({
  type : "LOGIN_REQUESTED",
  username,
  password,
});

export const loginSucceeded = (accountId : string, email : string, token : string) => ({
  type : 'LOGIN_SUCCEEDED',
  accountId,
  email,
  token
});

export const loginFailed = (errorMessage : string) => ({
  type : 'LOGIN_FAILED',
  errorMessage
});

export const logoutRequested = () => ({
  type : 'LOGOUT_REQUESTED',
});