import { Dataset } from "../../types"

export const datasetsLoaded = (data : Dataset[]) => ({
  type : 'DATASETS_LOADED',
  data
})

export const selectDataset = (data: Dataset) => ({
  type : 'SELECT_DATASET',
  data
})