import React from 'react';
import styled from 'styled-components';
import GenericLegend from './GenericLegend';

interface LegendGraphProps {
  color: string;
  comparisonLabel: string;
  territoryLabel: string;
}

const LegendGraph: React.FC<LegendGraphProps> = ({color, comparisonLabel, territoryLabel}) => {
  return (
    <Labels>
      <GenericLegend 
        color={color} 
        legend={territoryLabel} 
        height={10} 
        width={10} 
      />
      <GenericLegend 
        color={color} 
        legend={comparisonLabel} 
        height={0} 
        width={0}
        dotted={true} 
      />
    </Labels>
  )
}

export default LegendGraph;

const Labels = styled.div``;

