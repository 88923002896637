import React from 'react';
import styled from 'styled-components';
import { Parser } from 'json2csv';

import GenericButton from '../atoms/GenericButton';
import NumberIndicator from '../atoms/NumberIndicator';
import PieIndicator from '../atoms/PieIndicator';
import { sizeMobile } from '../../utils/constants';
import pxToRem from '../../utils/dimension';
import { t } from '../../i18n';
import { Trans } from 'react-i18next';
import Button from '../atoms/Button';
import { IconMention } from '../../assets/svg-images/IconMention';
import { IconInfo } from '../../assets/svg-images/IconInfo';
import { openPopup } from '../../store/actions/popup';
import { useDispatch } from 'react-redux';
import { Methodos } from './AllMethodo';
import { updatePopupColor } from '../../store/actions/global';
import { IconIndicatorInfo } from '../../assets/svg-images/IconIndicatorInfo';

export interface GenericCardProps {
    color : string;
    title : string;
    Icon? : React.FC<{color : string}>;
    total? : number;
    comparisonTotal? : number;
    label? : string;
    source2? : string;
    Source1? : React.ReactNode;
    Source3? : React.ReactNode;
    children: React.ReactNode;
    isComparing?: boolean;
    data?: any;
    fileName?: string;
    cardName: Methodos;
}

interface TotalProps {
  color : string;
}

export const GenericCard : React.FC<GenericCardProps> = ({
    color,
    title,
    Icon,
    total,  
    label,
    source2,
    children,
    comparisonTotal,
    isComparing,
    data,
    fileName,
    Source1,
    Source3,
    cardName
}) => {
  const dispatch = useDispatch();
  const exportCsv = () => {
    const opts = {
      withBOM : true,
      delimiter : ';'
    }
    const parser = new Parser(opts);
    const csv = parser.parse(data)
    const byteOrderMark = "\ufeff";
    const blob = new Blob([byteOrderMark, csv], {
      type:  "text/csv;charset=utf-8;"
    });
  
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName ? fileName : 'export';
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return(
    <Container color={color}>
      <Margin>
        <Header>
          <Title>
            <div>{title}</div>
            <span 
              onClick={() => {
              dispatch(openPopup(`methodo-${cardName}`));
              dispatch(updatePopupColor(color));
              }} 
              style={{marginLeft : '0.6rem', cursor : 'pointer'}} ><IconIndicatorInfo width={"1.6rem"} height={"1.6rem"} /></span>
          </Title>
        {
          isComparing && comparisonTotal && total ?
          <div style={{display : 'flex', flexDirection : 'column', alignItems : 'flex-end'}} >
            <NumberIndicator 
              numberInterim={total || 0} 
              nationalNumberInterim={comparisonTotal || 0} 
              color={color} 
              Icon={Icon} 
              isComparing={true} 
            />
            {label && <Subtitle>{label}</Subtitle>}
            <PieIndicator 
              percentage={Math.round((total/comparisonTotal)*100)} 
              pathColor={color} 
              direction={'row'} 
            />
          </div>
          :
          <TotalSection>
            {
              total ?
              <div style={{display : 'flex', flexDirection : 'row', justifyContent : 'space-evenly', alignItems : 'center'}}>
                  {Icon && <Icon color={color}/>}
                  {total && <Total color={color}>{total.toLocaleString()}</Total>}
              </div> : 
              <div style={{height: '5rem'}} />
            }
            <Subtitle>{label}</Subtitle>
          </TotalSection>
        } 
        </Header>
        <Children>
          {children}
        </Children>
        <Footer>
          <div>
              <GenericButton 
                onClick={exportCsv}
                iconColor='white'
                iconName="download-file"
                title={t('GenericCard.export')}
              />
          </div>
        </Footer>
      </Margin>
      <Methodo>
        <div>
          {Source1 || source2 || Source3 ? <Key style={{marginBottom : "0.5rem"}} >{t('GenericCard.key')}</Key> : null}
          <Source>
            {Source1 && Source1}
          </Source>
          <Source>
            {source2 && <Source>{source2.toLocaleString()}</Source>}
          </Source>
          <Source>
            {Source3 && Source3}
          </Source>
        </div>
        <Button 
          onClick={() => {
            dispatch(openPopup(`methodo-${cardName}`));
            dispatch(updatePopupColor(color));
          }}
          centered={true}
          style={{width : '20.4rem', height : '3.2rem', background : 'white', marginRight : '2rem'}}
        >
          <ContentButton>
            <TextButton>{t('GenericCard.indicator')}</TextButton>
            <IconInfo color='black' width={'1rem'} height={'1.2rem'} />
          </ContentButton>
        </Button>
      </Methodo>
    </Container>
  )
}

const Container = styled.div`
  position          : relative;
  display           : flex;
  flex-direction    : column;
  border-left       : solid;
  border-left-width : 3px;
  border-left-color : ${({color}) => color};
  background-color  : ${({theme}) => theme.colors.neutral};
  justify-content   : space-between;
  overflow          : hidden;
  
  @media screen and (max-width : ${sizeMobile}px) {
    padding : 2.1rem 1.73rem 2.1rem 2.1rem;
  }
`;

const Margin = styled.div`
  padding           : 2.1rem 1.73rem 2.1rem 2.1rem;
`;

const Header = styled.div`
  flex             : 0;
  display          : flex;
  flex-direction   : row;
  background-color : transparent;
  justify-content  : space-between;

  @media screen and (max-width : ${sizeMobile}px){
    flex-direction  : column;
    justify-content : start;
    align-items     : start;
  }
`;


const Footer = styled.div`
  flex             : 0;
  display          : flex;
  flex-direction   : row;
  background-color : transparent;
  justify-content  : flex-end;

  @media screen and (max-width : ${sizeMobile}px) {
    justify-content : flex-start;
    margin-bottom   : 31px;
  }
`;


const TotalSection = styled.div`
  background-color : transparent;
  display          : flex;
  flex-direction   : column;
  background-color : transparent;
  margin           : 1rem;
  align-items      : flex-end;

  @media screen and (max-width : ${sizeMobile}px) {
    align-items : flex-start;
    margin      : 1rem 0;
  }
`;


const Total = styled.div<TotalProps>`
  color       : ${({color}) => color};
  font-size   : 3rem;
  font-family : work-sans-light;
  margin-left : 1.3rem;

  @media screen and (max-width : ${sizeMobile}px) {
    font-size : 1.8rem;
  }
`;

const Title = styled.div`
  font-family : work-sans-semiBold;
  font-size   : 1.6rem;
  display     : flex; 
  @media screen and (max-width : ${sizeMobile}px) {
    font-size : 1.5rem;
  }
`;

const Subtitle = styled.div`
  background-color : transparent;
  max-width        : 23rem;
  min-height       : 3rem;
  text-align       : end;
  font-size        : 1.4rem;
  font-family      : work-sans-regular;
  margin-top       : 5px; 

  @media screen and (max-width : ${sizeMobile}px) {
    text-align : start;
    min-width : ${pxToRem(332)}rem;
    min-height       : 0rem;
  }
`;

const Source = styled.div`
  font-size        : 1.4rem;
  font-family      : work-sans-semiBold;
  margin           : 0.2rem 0;
  color            : white;
  line-height      : 1.8rem;
`;

const Key = styled.div`
  font-size        : 1.4rem;
  font-family      : work-sans-regular;
  margin           : 0.2rem 0;
  color            : white;
  max-width        : 60%;
  line-height      : 1.8rem;
`;

const Children = styled.div`
  width           : 100%;
  height          : 100%;
  
  @media screen and (max-width : ${sizeMobile}px) {
    margin-bottom : 29px;
  }
`;

const Methodo = styled.div`
  background-color : #2A2A2A;
  padding-top      : 2rem;
  padding-bottom   : 2rem;
  display          : flex;
  justify-content  : space-between;
  align-items      : flex-end;
  padding-left     : 2.1rem;
`;

const ContentButton = styled.div`
  display          : flex;
  align-items      : center;
`;

const TextButton = styled.div`
  color        : black;
  font-size    : 1.2rem;
  margin-left  : 0.5rem; 
  margin-right : 0.3rem; 
  white-space  : nowrap;
`;