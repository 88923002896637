export const theme = {
  colors : {
    primary            : "#00B3D4",
    secondary          : "#FBBC15",
    thirdary           : "#D54C74",
    fourthly           : "#405693",
    neutral            : "#FFF",
    background         : '#F9F8F6',
    chartPrimaryColor  : "#6FB53F",
    menu               : "#343438",
    text               : "#2A2A29",
    lightBackground    : "#404146",
    arrowNext          : "#A4A2A0",
    filters            : "rgb(237.16,249.56,249.56)",
    // accent             : '#8690c5',
    // text               : '#15203f',
    lightText          : '#FFF',
    darkText           : "#A4A2A0",
    disabledBackground : '#A4A2A0',
    // disabledText       : '#BBBBBB',
    // error              : '#DD0000',
    border             : "#B9B9B9",
    borderLight        : "#EDEDF4",
    placeholder        : "#FFF",
    searchBarPlaceholder     : '#A6A9BF',
    dropdownDisabled : "#707070",
    barList          : "#F5F5F4",
    // borderCalendar     : "rgba(187,189,189,0.4)",
    // primaryLight       : "#B299C9",
    // warning            : "#BB3248",
    // row                : "#222C53"
    ctt : {
      primary : {
        dark : '#0192AD',
        medium : '#00B3D4',
        light : '#5CC7DB',
        lighter : '#99DBE7',
        border : '#7FB351',
        disabled : "#A4A2A0"
      },
      secondary : {
        dark : '#4D8C23',
        medium : '#6FB53F',
        light : '#9BD872',
        lighter : '#C6E7B0',
        border : '#59B8D4',
        disabled : "#A4A2A0"
      },
      ternary : '#479F8F'
    },
    job : {
      primary : {
        medium : '#FBBC15',
        border : '#F1BF45'
      },
      secondary : {
        medium : '#D07200',
        light : '#F19000',
        border : '#E49535',
        switch : '#F19000'
      },
    },
    sector : {
      primary : '#E73E73',
      secondary : '#7F5389'
    },
    cdii : {
      primary : {
        medium : '#405693',
        border : "#45558F"
      },
      secondary : {
        dark : '#87508C',
        medium : '#9F72A3',
        light : '#B796BA',
        lighter : '#CFB9D1',
        border  : '#7F5389',
        disabled : "#dedede"
      },
      ternary : '#20BBD8'
    }
  },
  fonts : {
    small  : "1.4rem", //14px
    medium : "1.53rem", //15.3px 
    large  : "1.92rem", //19.2px
    general : {
      filterLabel     : '1.2rem/1rem work-sans-bold',
      chip            : '0.625rem/0.8125rem work-sans-bold',
      dropdownLabel   : '1.2rem/0.9375rem work-sans-regular',
      dropdown        : '1.2rem/1.125rem work-sans-regular',
      dropdownFooter  : '0.875rem/1.125rem work-sans-regular',
      dropdownFooterBold  : '0.875rem/1.125rem work-sans-bold',
      overlayCard     : '0.75rem/0.9375rem work-sans-regular',
      inputLabel      : '1.2rem/1.125rem work-sans-regular',
      input           : '1.2rem/1.125rem work-sans-regular',
      filterInput     : '1.2rem/1.125rem work-sans-regular',
      switchLabel     : '0.875rem/1.125rem work-sans-regular',
      switchLabelSelected : '0.875rem/1.125rem work-sans-medium',
      button          : '0.875rem/1.125rem work-sans-regular',
      buttonBold      : '0.875rem/1.125rem work-sans-bold',
      count           : '0.75rem/0.9375rem work-sans-regular',
      loader          : '1.375rem/1.6875rem work-sans-regular',
      buttonNext      : '0.75rem/1.125rem work-sans-bold',
    },
    login : {
      titleFirstPart : '1.25rem/1.5rem work-sans-regular',
      titleSecondPart  : '3.125rem/3.8125rem work-sans-bold',
      info : '0.875rem/1.125rem work-sans-regular',
      button : '0.875rem/1.125rem work-sans-bold'
  },
  footer : '1.5rem/1.125rem work-sans-bold'
  },
  shadow : {
    list : '0px 3px 6px #00000029',
    overlayCard : '0px 4px 10px #24263B17',
    overlayMapCard : '0px 4px 10px #24263B17',
    Card : '0px 3px 6px #00000029'
  }
}