import React from 'react';
import styled from'styled-components';
import BarIndicator from '../atoms/BarIndicator';
import pxToRem from '../../utils/dimension';
import { statisticSecretValue } from '../../utils/constants';
import { t } from '../../i18n';

export interface CompareBarIndicatorProps {
  color : string;
  percentage : number;
  comparisonPercentage : number;
  width?: number;
  height : number;
  label : string;
  description : string;
  withDot?: boolean;
  isComparing : boolean;
  labelWidth?: number;
  value?: number | string;
}


const CompareBarIndicator : React.FC<CompareBarIndicatorProps> = ({ color, percentage, comparisonPercentage, width, height=41, label, description, withDot=false, isComparing=false, labelWidth=170, value }) => {

  return(
    <Container>
      <LabelContainer width={labelWidth}>{description}</LabelContainer>
      {
        value === statisticSecretValue ?
        <StatisticSecret>
          <SecretText>{t('StatisticSecret.lessThan3')}</SecretText>
        </StatisticSecret> :
        <PathContainer>
        <BarIndicator color={color} percentage={percentage} width={width} height={height} label={label} isComparing={isComparing} dottedBorder={false} />
        { isComparing &&
          <BarIndicator color={color} percentage={comparisonPercentage} width={width} height={23} label={label} isComparing={isComparing} dottedBorder={true} />
        }
        </PathContainer>
      }
      
    </Container>
  )
}

export default CompareBarIndicator;

interface LabelContainerProps {
  width : number;
}

const Container = styled.div`
  display         : flex;
  flex-direction  : row;
  align-items     : center;
  justify-content : flex-start;
  margin-bottom   : ${pxToRem(17)}rem;
`;

const PathContainer = styled.div`
`;

const LabelContainer = styled.div<LabelContainerProps>`
  font-size    : ${pxToRem(14)}rem;
  width        : ${({width}) => `${pxToRem(width)}rem`};
  font-family  : work-sans-regular;
`;


const StatisticSecret = styled.div`
  flex    : 1;
  max-width : 50rem;
`;

const SecretText = styled.div`
  font-family  : work-sans-regular;
  font-size    : 1.3rem;
  line-height  : 1.5rem;
  padding      : 1rem;
`;