import React from 'react';
import styled from 'styled-components';
import { sizeMobile } from '../../utils/constants';
import pxToRem from '../../utils/dimension';



const SelectedElement : React.FC<SelectedElementProps> = ({text, size = 23}) => {

    return(
        <Container size={size}>
            {text}
        </Container>
    )
}

export default SelectedElement;


export interface SelectedElementProps {
    text : string;
    size? : number;
}

interface ContainerProps {
    size : number;
}

const Container = styled.div<ContainerProps>`
    display          : flex;
    align-items      : center;
    justify-content  : center;
    background-color : white;
    padding          : 0.7rem 1rem;
    margin-right     : 1rem;
    border           : solid;
    border-width     : 1px;
    border-radius    : 1.2rem;
    border-color     : ${({theme}) => theme.colors.border};
    text-transform   : uppercase;
    font-family      : work-sans-bold;
    text-transform   : uppercase;
    font-size        : ${pxToRem(10)}rem;

    @media screen and (max-width : ${sizeMobile}px) {
        font-size        : ${pxToRem(14)}rem;
        border-radius    : 2rem;
    }
`;