import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/theme';
import pxToRem from '../../utils/dimension';
import Icon from './Icon';



const Pagination : React.FC<PaginationProps> = ({ value, onChange, range }) => {

    let pattern = null;

  switch (true) {
    case range < 7:
      pattern = [...new Array(range)].map((_, i) => i + 1);
      break;
    case value < 4:
      pattern = [1, 2, 3, 4, 5, "...", range];
      break;
    case value > range - 4:
      pattern = [1, "...", range - 4, range - 3, range - 2, range - 1, range];
      break;
    default:
      pattern = [1, "...", value - 1, value, value + 1, "...", range];
  }

  function changeNumber(n: any) {
    if (typeof n === "number" && n > 0 && n <= range) {
      onChange(n-1);
    }
  }

  return (
    <Container>
      {pattern.map((label) => (
        <NumberContainer
            className={value === label ? "active" : ""}
            onClick={() => changeNumber(label)}
            selected={value === label}
            // isActive={value === label}
        >
        {label}
      </NumberContainer>
      ))}
    </Container>
  );
}

export default Pagination;


export interface PaginationProps {
    value: number;
    onChange: (value: number) => void;
    range: number;
}

interface PaginationState {
    
}

interface NumberContainerProps {
    selected : boolean;
}

const Container = styled.div`
    display        : flex;
    flex-direction : row;
    align-items    : center;
`;

const PageNumbers = styled.div`
display : flex;
flex-direction : row;
flex : auto;
justify-content : center;
margin-right : 16.9px;
margin-left : 16.9px;
& div:last-child{
    margin-right : 0px;
}
`;

const NumberContainer = styled.div<NumberContainerProps>`
  font-family  : ${({selected}) => selected ? "work-sans-semiBold" : "work-sans-regular"};
  color        : #A4A2A0;
  font-size    : ${pxToRem(14)}rem;
  margin       : 0 9px;
  cursor       : pointer;
  user-select  : none;
`;

const IconRightContainer = styled.div`
flex : none;
cursor : pointer;
`;

const IconLeftContainer = styled.div`
flex : none;
cursor : pointer;
& :focus {
    tranform : scale(0.95);
}
`;

const Button = styled.div`
  cursor : pointer;
  display : flex;
  align-items : center;
  margin : 0 15px;
`;