export const departementsLoaded = (year: string, maps: any[]) => ({
  type : 'DEPARTEMENTS_LOADED',
  year,
  maps
})

export const intercosLoaded = (year: string, maps: any[]) => ({
  type : 'INTERCOS_LOADED',
  year,
  maps
})

export const regionsLoaded = (year: string, maps: any[]) => ({
  type : 'REGIONS_LOADED',
  year,
  maps
})