import defaultState from '../defaultState';

const popupReducer = (state = defaultState.popup, action : Action) => {
  switch (action.type) {
    case '@@RESET':
      return defaultState.popup;

    case 'OPEN_POPUP':
      return {
        show : true,
        name : action.name,
      };

    case 'CLOSE_POPUP' : 
      return {
        show : false,
        name : action.name,
      };
    
    default:
      return state;
  }
};

export default popupReducer;

interface Action {
  name : string;
  type : string;
}