import React, {useEffect, useState} from 'react';
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Customized, Cell } from 'recharts';
import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import { BarGraphData, ListBarData } from '../../types';
import { sizeMobile } from '../../utils/constants';


import pxToRem from '../../utils/dimension';
import ListBarIndicators from '../graphs/ListBarIndicators';


/**
 * 
 * @param {string} color the color of the graph
 * @param {number} height
 * @param {number} width
 * @param {{name : string, value : number, nationalValue : number}} data
 * @param {boolean} isComparing displaying the comparison between two datasets
 * @param {number} total the total number 
 * @param {number} nationalTotal the national total number
 * @returns Recharts graph with vertical bars
 */


const BarGraph : React.FC<BarGraphProps> = ({color, data, isComparing, total, comparisonTotal=0}) => {
    const size = useWindowSize();

    const [items, setItems] = useState<any[]>([]);
    const [barItems, setBarItems] = useState<ListBarData[]>([]);
    const [totalValue, setTotalValue] = useState<number>();
    useEffect(() => {
        const usedData = data?.map((element) => (
            {
                name : element.name,
                value : typeof element.value === "number" ? element.value / total : 0,
                comparisonValue : element.comparisonValue / (comparisonTotal || 1)
            }
        ))

        const usedDataListBar : any[] = data?.map((element) => (
            {
                description : element.name,
                percentage : Math.floor(element.value / total*1000)/10,
                nationalPercentage : Math.floor(element.comparisonValue / comparisonTotal*1000)/10,
                value : element.value,
                comparisonValue : element.comparisonValue
            }
        ))

        setItems(usedData)
        setBarItems(usedDataListBar)
        setTotalValue(total);
    }, [data])

    const displayTextTick = (text: string, x: number , y: number) => {

        let words = text.split(' ');

        return(
            <svg textAnchor='middle'>
                {words.map((word, index) => {
                    return <text key={`tick-label-${index}`} fontFamily='work-sans-regular' fontSize={`${pxToRem(14)}rem`} x={x} y={y + index * 15 + 27}>{word}</text>
                })}
            </svg>
        )
    }



    const renderCustomAxisTick = ({ x, y, payload } : { x: number,y:  number, payload: any}) => {
        return(
            <svg>
                {displayTextTick(payload.value, x, y)}
            </svg>
        )
    }

    const renderCustomBarLabel = (payload: any, x: number, y: number, value: number, isComparing: boolean, total? : number ) => {
        return(
            <svg>
                <text
                fontFamily='work-sans-semiBold'
                fontSize={isComparing ? `${pxToRem(14)}rem` : `${pxToRem(12)}rem`}
                x={x}
                dx={0}
                y={!isComparing ? y - 10 : y - 44} 
                fill={color}
                >
                    {value !== 0 &&`${Math.round(10000 * value) / 100}%`} 
                </text>

                {isComparing && totalValue &&
                <svg dominantBaseline={'middle'} textAnchor={'middle'} >
                    {
                        value === 0 ?
                        <text 
                        fontFamily='work-sans-regular'
                        fontSize={`${pxToRem(14)}rem`}
                        fill='#A4A2A0'
                        x={x} 
                        dx={21}
                        y={y - 29} 
                        >
                            NR
                        </text> :
                        <>
                            <text 
                            fontFamily='work-sans-regular'
                            fontSize={`${pxToRem(14)}rem`}
                            fill='#A4A2A0'
                            x={x} 
                            dx={21}
                            y={y - 29} 
                            >

                                {`${(value * totalValue).toLocaleString()}`}
                            </text>

                            <text 
                            x={x} 
                            dx={21}
                            y={y - 14} 
                            fontFamily='work-sans-regular'
                            fill='#A4A2A0'
                            fontSize={`${pxToRem(14)}rem`}
                            >
                                {`intérimaires`}
                            </text>
                        </> 
                    }
                </svg>

    }
            </svg>
        )
    }


    return(
        <>
            {

                size.width && size.width > sizeMobile ?
                <ResponsiveContainer width='100%' height='100%' > 
                    <BarChart 
                    data={items}
                    margin={{ top: 100, right: 30, left: 0, bottom: 100 }}
                    >
                        <XAxis 
                        dataKey={'name'} 
                        interval={0} 
                        tick={renderCustomAxisTick} 
                        tickLine={false} 
                        stroke={'#707070'}
                            />
        
                        <YAxis 
                        hide={true} 
                        />
        
                        <Bar dataKey="value" 
                        fill={color} 
                        isAnimationActive={false}
                        radius={[6, 6, 6, 6]}
                        barSize={41}
                        label={({payload, x, y, width, height, value}) => renderCustomBarLabel(payload, x, y, value, !isComparing, totalValue)}
                        />
                        { 
                            isComparing &&
                            <Bar 
                            dataKey="comparisonValue" 
                            isAnimationActive={false} 
                            radius={[6, 6, 6, 6]} 
                            fill={'transparent'} 
                            stroke={color} 
                            strokeDasharray="2" 
                            barSize={20}
                            label={({payload, x, y, width, height, value}) => renderCustomBarLabel(payload, x, y, value, !isComparing, comparisonTotal)}
                            />
                        }
                    </BarChart>
                </ResponsiveContainer>
                : size.width && size.width <= sizeMobile ?
                <div style={{width : '100%', height : '40rem', alignItems :  'center', justifyContent : 'center'}}>
                    <ListBarIndicators 
                        data={barItems}
                        color={color}
                        label=''
                        size='small'
                        isComparing={isComparing} 
                        width={178}    
                        labelWidth={200}        
                    />
                </div> : null
            }
        </>
    )
}


export default BarGraph;

export interface BarGraphProps {
    data : BarGraphData[];
    color : string;
    isComparing : boolean;
    total : number;
    comparisonTotal?: number;
}