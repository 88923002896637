import React from 'react';
import { CttEvolution } from '../../assets/svg-images/methodos/CttEvolution';
import { CttNumberWorkers } from '../../assets/svg-images/methodos/CttNumberWorkers';
import { CttDynamic } from '../../assets/svg-images/methodos/CttDynamic';
import { CttNumberHours } from '../../assets/svg-images/methodos/CttNumberHours';
import { CttAge } from '../../assets/svg-images/methodos/CttAge';
import { CttGenre } from '../../assets/svg-images/methodos/CttGenre';
import { CdiiAge } from '../../assets/svg-images/methodos/CdiiAge';
import { CdiiEvolution } from '../../assets/svg-images/methodos/CdiiEvolution';
import { CdiiGenre } from '../../assets/svg-images/methodos/CdiiGenre';
import { CdiiNumber } from '../../assets/svg-images/methodos/CdiiNumber';
import { CompaniesSize } from '../../assets/svg-images/methodos/CompaniesSize';
import { Jobs } from '../../assets/svg-images/methodos/Jobs';
import { Csp } from '../../assets/svg-images/methodos/Csp';
import { MajorSectors } from '../../assets/svg-images/methodos/MajorSectors';
import { Sectors } from '../../assets/svg-images/methodos/Sectors';

export type Methodos = 
  'cttEvolution' |
  'cttNumberWorkers' |
  'cttNumbersHours' |
  'cttDynamic' |
  'cttGenre' |
  'cttAge' |
  'csp' |
  'jobs' |
  'companiesSize' |
  'majorSectors' |
  'sectors' |
  'cdiiEvolution' |
  'cdiiNumber' |
  'cdiiGenre' |
  'cdiiAge'; 

interface AllMethodoProps {
  name: Methodos;
}

const AllMethodo: React.FC<AllMethodoProps> = ({name}) => {
  switch (name) {
    case 'cttEvolution' :
      return <CttEvolution width={"100%"} height={"100%"} />
    case 'cttNumberWorkers' :
      return <CttNumberWorkers width={"100%"} height={"100%"} /> 
    case 'cttNumbersHours' :
      return <CttNumberHours width={"100%"} height={"100%"} /> 
    case 'cttDynamic' :
      return <CttDynamic width={"100%"} height={"100%"} /> 
    case 'cttGenre' :
      return <CttGenre width={"100%"} height={"100%"} /> 
    case 'cttAge' :
      return <CttAge width={"100%"} height={"100%"} /> 
    case 'csp' :
      return <Csp width={"100%"} height={"100%"} /> 
    case 'jobs' :
      return <Jobs width={"100%"} height={"100%"} /> 
    case 'companiesSize' :
      return <CompaniesSize width={"100%"} height={"100%"} /> 
    case 'majorSectors' :
      return <MajorSectors width={"100%"} height={"100%"} /> 
    case 'sectors' :
      return <Sectors width={"100%"} height={"100%"} /> 
    case 'cdiiEvolution' :
      return <CdiiEvolution width={"100%"} height={"100%"} /> 
    case 'cdiiNumber' :
      return <CdiiNumber width={"100%"} height={"100%"} /> 
    case 'cdiiGenre' :
      return <CdiiGenre width={"100%"} height={"100%"} /> 
    case 'cdiiAge' :
      return <CdiiAge width={"100%"} height={"100%"} />
    default :
      return <div></div>
  } 
}

export default AllMethodo;