import styled from 'styled-components';
import Input from './Input';

const SearchInput : any = styled(Input)`
  border-radius : 17px;
  width         : 245px;
  background-color : white;
  div {
    height        : 32px;
    border-radius : 17px;
    padding       : 0 11px;
  }
  input {
    height        : 16px;
    padding-right : 5px;
    caret-color   : #A6A9BF;
    color         : black;;

    ::placeholder {
      color: black;
    }

    :focus-visible {
      outline : none;
    }

    :disabled {
      background-color : transparent;
    }
  }
`;

export default SearchInput;