import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import pxToRem from '../../utils/dimension';
import PieIndicator from './PieIndicator';


const GenericLineChart: React.FC<GenericLineChartProps> = ({ color = '#6FB53F', data, isComparing }) => {
    const [values, setValues] = useState<{
        name: string,
        value: number,
        comparisonValue: number
    }[] | []>([]);

    useEffect(() => {
        setValues(data)
        return () => setValues([])
    }, [data, isComparing])
    const displayTextTick = (name: string, x: number, y: number) => {

        return (
            <svg textAnchor='middle'>
                <text fontFamily='work-sans-medium' fontSize={`${pxToRem(14)}rem`} x={x} y={y + 11.5}>{name}</text>
            </svg>
        )
    }

    const renderCustomBarLabel = (payload: any, x: number, y: number, value: number, isComparing: boolean) => {
        return (
            <svg>
                <text
                    fontFamily='work-sans-regular'
                    fontSize={`${pxToRem(14)}rem`}
                    x={x}
                    dx={-17}
                    y={y - 20}
                    fill={isComparing ? color : '#A4A2A0'}
                >
                    {value.toLocaleString()}
                </text>
            </svg>

        )
    }

    const renderCustomAxisTick = ({ x, y, payload }: { x: number, y: number, payload: any }) => {

        return (
            <svg>
                {displayTextTick(payload.value, x, y)}
            </svg>
        )
    }


    return (
    <Container>
        <ResponsiveContainer width={"100%"} height={400}>
            <AreaChart
                data={values}
                margin={{
                    top: 50,
                    right: 10,
                    left: 10,
                    bottom: 0
                }}
            >
                <XAxis
                    dataKey="name"
                    padding={{ left: 40, right: 40 }}
                    tickLine={false}
                    tick={renderCustomAxisTick}
                    stroke={'#707070'}
                />

                <YAxis
                    hide={true}
                />

                <Area
                    type="monotone"
                    dataKey="value"
                    stroke='transparent'
                    fill={color}
                    activeDot={{ r: 0 }}
                    dot={<circle cx='50' cy='50' r='10' fill={color} stroke='white' strokeWidth={2} opacity={1} dy={3} />}
                    label={({ payload, x, y, width, height, value }: { payload: any, x: number, y: number, height: number, width: number, value: number }) => renderCustomBarLabel(payload, x, y, value, isComparing)}
                    isAnimationActive={false}
                    fillOpacity={1}
                />
                {
                    isComparing &&
                    <Area
                        type="monotone"
                        dataKey="comparisonValue"
                        stroke={color}
                        fill='transparent'
                        activeDot={{ r: 0 }}
                        strokeDasharray="2"
                        dot={<circle cx='50' cy='50' r='10' fill={'white'} stroke={color} strokeDasharray="0" strokeWidth={2} opacity={1} dy={3} />}
                        label={({ payload, x, y, width, height, value }: { payload: any, x: number, y: number, height: number, width: number, value: number }) => renderCustomBarLabel(payload, x, y, value, isComparing)}
                        isAnimationActive={false}
                        fillOpacity={1}
                    />
                }
            </AreaChart>
        </ResponsiveContainer>
        { 
            isComparing && 
            <Footer>
                <PieIndicatorContainer style={{justifyContent : data.length > 1 ? "space-between" : "center"}} >
                    { data?.map((element, index) => (
                        <PieIndicator pathColor={color} percentage={Math.round(100 * (element.value / element.comparisonValue))} direction='column-reverse' />
                    ))}
                </PieIndicatorContainer>
            </Footer>
        }
    </Container>
    )
}

export default GenericLineChart;

export interface GenericLineChartProps {
    color: string;
    data: {
        name: string,
        value: number,
        comparisonValue: number
    }[];
    isComparing: boolean;
}

const Container = styled.div`
    display        : flex;
    flex-direction : column;
    align-items    : center;
    width          : 100%;
`;

const PieIndicatorContainer = styled.div`
    display         : flex;
    flex-direction  : row;
    align-items     : center;
    width           : 92%;
    padding-top     : 0px;
`;

const Footer = styled.div`
    width : 100%;
    display : flex;
    justify-content : center;
`;