import styled from 'styled-components';
import Input from './Input';

const DropdownFilterInput : any = styled(Input)`
  border-radius : 8px;
  padding : 7px;
  div {
    height        : 30px;
    border-radius : 4px;
    padding       : 0 9px;
  }
  input {
    height        : 16px;
    padding-right : 5px;
    color         : ${({theme}) => theme.colors.text};
    font          : ${({theme}) => theme.fonts.general.filterInput};

    ::placeholder {
      color: ${({theme}) => theme.colors.searchBarPlaceholder};
    }

    :focus-visible {
      outline : none;
    }

    :disabled {
      background-color : transparent;
    }
  }
`;

export default DropdownFilterInput;