import React, { useState} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import SwitchButton from '../atoms/SwitchButton';
import SwitchButtonWithIcons from '../atoms/SwitchButtonWithIcons';
import Dropdown from '../molecules/DropDown';
import { BubbleChart } from '../molecules/BubbleChart';

import { t, i18next } from '../../i18n';
import { theme } from '../../assets/theme';
import { RootState } from '../../types';
import List from './List';
import { Loader } from '../atoms/Loader';
import LegendGraph from '../atoms/LegendGraph';
import { sizeMobile } from '../../utils/constants';

interface ContainerBubbleChartProps {
  category: string;
  color?: string;
  filterDataNiv1: any;
  filterDataNiv2: any;
  onSelectNiv1: (value: string | undefined) => void;
  onSelectNiv2: (value: string | undefined) => void;
  data: {key: string, value: number}[];
  onClickSwitchButton: (value: 'aggregate' | 'detail') => void;
  dataView: 'aggregate' | 'detail';
  totalAggregate: number;
  totalDetail: number;
  territoryLabel?: string;
  comparisonLabel?: string;
  comparison?: boolean;
  isLoading?: boolean;
}

const ContainerBubbleChart: React.FC<ContainerBubbleChartProps> = ({
  category,
  color,
  data,
  filterDataNiv1,
  filterDataNiv2,
  onSelectNiv1,
  onSelectNiv2,
  onClickSwitchButton,
  dataView,
  totalAggregate,
  totalDetail,
  territoryLabel,
  comparisonLabel,
  comparison,
  isLoading=false
}) => {
  const filters = useSelector((state: RootState) => state.filters);
  const loading = useSelector((state: RootState) => state.loading);
  const { pcs_niv1, sectors_niv1, groupsAgeSector, groupsAgeCttNiv2 } = filters;
  const {totalCtt, comparisonTotalCtt} = useSelector((state: RootState) => state.global);

  const [view, setView] = useState<'bubble' | 'list'>('bubble');
  const [totalCat1, setTotalCat1] = useState<number>(0);
  const [totalCat2, setTotalCat2] = useState<number>(0);

  return (
    <>
      <ContainerSwitchButton>
        <SwitchButtonWithIcons 
          circleColor={category === 'job' ? theme.colors.job.secondary.switch : theme.colors.sector.primary}
          checked={view === 'bubble'}
          onClick={() => view === 'bubble' ? setView('list') : setView('bubble')}
          colors={{
              default : '#8E8D8A',
              selected : '#FFFFFF'
          }}
        />
      </ContainerSwitchButton>
    <Container>
      {
        data.filter((d) => d.value && typeof d.value === "number").length ?
        <Middle>
          {
            view === 'bubble' ?
            <BubbleChart 
              data={_.orderBy(data, (d) => typeof d.value === "number" ? d.value : 0, ["desc"])}
              id={`bubble-chart-${category}`}
              total={_.sumBy(data, 'value')}
              colors={color ? [color] : undefined}
              selectedColor={color}
            /> :
            <List 
              data={data} 
              category={category} 
              color={color|| ''} 
              total={_.sumBy(data, 'value')} 
              comparison={comparison}
              comparisonTotalCtt={_.sumBy(data, 'comparisonValue') || 1}
            />
          }
        </Middle> :
        <Middle style={{display : 'flex', justifyContent : 'center', alignItems : 'center', height: '53rem'}}>
          <Loader size={100} height={43} color={color || ""} isLoading={isLoading} />
        </Middle> 
      }
      <Right>
        <ContainerRight>
          <Level>{t('BubbleChart.levelDetail')}</Level>
          <ContainerSwitchButtons>
            <SwitchButton 
              label={i18next.t(`BubbleChart.switch.${category}.aggregate`, {count: totalAggregate})} 
              value={dataView} 
              selected={dataView === 'aggregate'} 
              onClick={() => onClickSwitchButton('aggregate')} 
              labelSide={'right'} 
              color={category === 'job' ? theme.colors.job.secondary.light : theme.colors.sector.primary}
              colorBar={category === 'job' ? "rgba(228,149,53,0.4)" : "rgba(213,76,116,0.4)"}         
            />
            <div style={{marginTop : '1.4rem'}}>
              <SwitchButton 
                label={i18next.t(`BubbleChart.switch.${category}.detail`, {count: totalDetail})} 
                value={dataView} 
                selected={dataView === 'detail'} 
                onClick={() => onClickSwitchButton('detail')}
                labelSide={'right'}
                color={category === 'job' ? theme.colors.job.secondary.light : theme.colors.sector.primary} 
                colorBar={category === 'job' ? "rgba(228,149,53,0.4)" : "rgba(213,76,116,0.4)"}
              />
            </div>
          </ContainerSwitchButtons>
          <ContainerDropdown>
            <Section>{t(`BubbleChart.${category}Category`)}</Section>
            <Dropdown 
              placeholder={t(`Filter.${category}.placeholder`)}
              items={filterDataNiv1.data}
              onSelect={onSelectNiv1}
              selected={filterDataNiv1.selected}
              disabled={loading?.calculation > 0}
              colors={category === 'job' ? {button : theme.colors.job.secondary.switch, pictos : theme.colors.neutral} : {button : theme.colors.sector.primary, pictos : theme.colors.neutral}}
              style={{width : '21rem'}}
            />
          </ContainerDropdown>
          <ContainerDropdown>
            <Section>{t(`BubbleChart.groupAge`)}</Section>
            <Dropdown 
              placeholder={t('Filter.groupsAge.placeholder')}
              items={filterDataNiv2.data}
              onSelect={onSelectNiv2}
              selected={filterDataNiv2.selected}
              disabled={loading?.calculation > 0}
              colors={category === 'job' ? {button : theme.colors.job.secondary.switch, pictos : theme.colors.neutral} : {button : theme.colors.sector.primary, pictos : theme.colors.neutral}}
              style={{width : '21rem'}}
            />
          </ContainerDropdown>
          {
            !!comparison && view === 'list' ? 
            <div style={{width : '100%'}} >
              <LegendGraph 
                color={color || ""}
                comparisonLabel={comparisonLabel || ''}
                territoryLabel={territoryLabel || ''}
              />
            </div>
              : null
          }
        </ContainerRight>
      </Right>
    </Container>
    </>

  )
}  


export default ContainerBubbleChart;


const ContainerSwitchButton = styled.div`
  margin-top : -5rem;
  @media screen and (max-width : ${sizeMobile}px) {
    margin-top : 1rem;
  }
`;

const Container = styled.div`
  display        : flex;
  flex-direction : row;
  width          : 100%;
  
  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction : column-reverse;
  }
`;

const Left = styled.div`
  flex : 1;
`;

const Middle = styled.div`
  flex : 3;
`;

const Right = styled.div`
  flex             : 1;
  padding-top      : 7rem;
  display          : flex;
  flex-direction   : column;
  align-items      : flex-end;

  @media screen and (max-width : ${sizeMobile}px) {
    align-items      : flex-start;
    padding-top      : 1rem;
    padding-left     : 0rem;
  }
`;

const ContainerRight = styled.div`
  width : 21rem;

  @media screen and (max-width : ${sizeMobile}px) {
    width : 40rem;
  }
`;


const Level = styled.div`
  color         : ${({theme}) => theme.colors.text};
  font-size     : 1.6rem;
  font-family   : work-sans-medium;
  margin-bottom : 0.6rem;

  @media screen and (max-width : ${sizeMobile}px) {
    margin-top : 3rem;
  }
`;

const Section = styled.div`
  color     : ${({theme}) => theme.colors.text};
  font-size : 1.6rem;
  font-family : work-sans-medium;
  margin-bottom : 0.6rem;
`;

const ContainerSwitchButtons = styled.div`
  margin-left   : 3.7rem;
  margin-bottom : 4rem;

  @media screen and (max-width : ${sizeMobile}px) {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    margin-left    : 0rem;
    margin-bottom  : 0rem;
  }
`;

const ContainerDropdown = styled.div`
  margin-bottom : 2.4rem;

  @media screen and (max-width : ${sizeMobile}px) {
    margin-top : 2.4rem;
  }
`;

const Text = styled.div`
  font-family : work-sans-medium;
  font-size   : 2rem;
  color       : ${({theme}) => theme.colors.text};
`;