import React from 'react';
import styled from 'styled-components';
import pxToRem from '../../utils/dimension';

export interface GenericLegendProps {
  color : string;
  legend : string;
  height : number;
  width : number;
  dotted?: boolean;
}

const GenericLegend : React.FC<GenericLegendProps> = ({
  color, 
  legend,
  height,
  dotted=false
}) => {

  const width = (11 / 5) * height;

  return(
    <Container height={height} width={width}>
      <Circle dotted={dotted} color={color} size={height}  />
      <Legend>{legend}</Legend>
    </Container>
  )
}

export default GenericLegend;


interface CircleProps {
  color : string;
  size : number;
  dotted: boolean;
}

interface ContainerProps {
  height : number;
  width : number;
}

interface LegendProps {
}

const Container = styled.div<ContainerProps>`
  display          : flex;
  flex-direction   : row;
  align-items      : center;
  background-color : white;
  height           : ${({height}) => height }rem;
  width            : auto;
`;

const Circle = styled.div<CircleProps>`
  background-color : ${({color, dotted}) => dotted ? 'transparent' : color};
  border-radius    : 50%;
  min-height       : ${pxToRem(18)}rem;
  min-width        : ${pxToRem(18)}rem;
  border           : ${({dotted, color}) => dotted ? `1px dotted ${color}` : `1px solid ${color}`};  
`;

const Legend = styled.div<LegendProps>`
  font-size   : ${pxToRem(14)}rem;
  margin-left : ${pxToRem(8.6)}rem;
  color       : #A4A2A0;
  width       : auto;
  white-space : nowrap;
`;