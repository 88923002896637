import styled from 'styled-components';
import Input from './Input';

const MobileDropdownFilterInput : any = styled(Input)`
  border-radius : 8px;
  div {
    height        : 4rem;
    border-radius : 4px;
    padding       : 0 9px;
    background-color : #FFF;
    width            : 80%; 
    margin-left      : 5%
  }
  input {
    height        : 1.5rem;
    padding-right : 5px;
    color         : ${({theme}) => theme.colors.text};
    font          : ${({theme}) => theme.fonts.general.filterInput};

    ::placeholder {
      color: ${({theme}) => theme.colors.searchBarPlaceholder};
      font-size : 1.8rem;
    }

    :focus-visible {
      outline : none;
    }

    :disabled {
      background-color : transparent;
    }
  }
`;

export default MobileDropdownFilterInput;