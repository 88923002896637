import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { sizeMobile} from '../utils/constants';

import useWindowSize from '../hooks/useWindowSize';

import TitlePage from '../components/atoms/TitlePage';
import { t } from '../i18n';
import { Trans } from 'react-i18next';
import { theme } from '../assets/theme';
import Icon from '../components/atoms/Icon';


const Mentions : React.FC<any> = ({history, onClose}) => {
  const size = useWindowSize();
  const contentHeight = (size.height || 0) - 160 - 65; //160 height of top & 65 height of footer
  
  return (
    <Container>
      <Content heightContent={contentHeight} >
      <CloseButton onClick={onClose}>
        <Icon icon='close' height={"3rem"} color={theme.colors.primary} />
      </CloseButton>
        <Trans 
          i18nKey='Mentions.title'
          components={
            {
              font : <div style={{fontFamily : 'work-sans-regular'}} />,
              bold : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
              h1 : <h1 style={{fontSize : "2.2rem", textAlign : "center"}} />,
            }
          }
        />
        <Section>
          <Trans 
            i18nKey='Mentions.header'
            components={
              {
                font : <div style={{fontFamily : 'work-sans-regular', lineHeight : "2.5rem", fontSize : "1.5rem"}} />,
                bold : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                h2 : <h2 style={{fontSize : "1.7rem"}} />,
                p : <p style={{lineHeight : "2.5rem", fontSize : "1.5rem", marginTop : "0.5rem"}} />,
                quadrature : <a href="https://quadrature-du-cercle.com/" target="_blank" style={{textDecoration : 'none', fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />,
                infhotep : <a href="https://www.infhotep.com/" target="_blank" style={{textDecoration : 'none', fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />,
                appunto : <a href="https://www.appunto.io/" target="_blank" style={{textDecoration : 'none', fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />,
              }
            }
          />
        </Section>
        <Section>
          <Trans 
            i18nKey='Mentions.intellectualProperty'
            components={
              {
                font : <div style={{fontFamily : 'work-sans-regular', lineHeight : "2.5rem", fontSize : "1.5rem"}} />,
                bold : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                h2 : <h2 style={{fontSize : "1.7rem"}} />,
                p : <p style={{lineHeight : "2.5rem", fontSize : "1.5rem", marginTop : "0.5rem"}} />,
                color : <p style={{color : theme.colors.primary}} />
              }
            }
          />
        </Section>
        <Section>
          <Trans 
            i18nKey='Mentions.limitation'
            components={
              {
                font : <div style={{fontFamily : 'work-sans-regular', lineHeight : "2.5rem", fontSize : "1.5rem"}} />,
                bold : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                h2 : <h2 style={{fontSize : "1.7rem"}} />,
                p : <p style={{lineHeight : "2.5rem", fontSize : "1.5rem", marginTop : "0.5rem"}} />,
                color : <span style={{color : theme.colors.primary}} />
              }
            }
          />
        </Section>
        <Section>
          <Trans 
            i18nKey='Mentions.data'
            components={
              {
                font : <div style={{fontFamily : 'work-sans-regular', lineHeight : "2.5rem", fontSize : "1.5rem"}} />,
                bold : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                h2 : <h2 style={{fontSize : "1.7rem"}} />,
                p : <p style={{lineHeight : "2.5rem", fontSize : "1.5rem", marginTop : "0.5rem"}} />,
                color : <span style={{color : theme.colors.primary}} />
              }
            }
          />
        </Section>
        <Section>
          <Trans 
            i18nKey='Mentions.dataLimitation'
            components={
              {
                font : <div style={{fontFamily : 'work-sans-regular', lineHeight : "2.5rem", fontSize : "1.5rem"}} />,
                bold : <strong style={{fontFamily : "work-sans-semiBold", color : theme.colors.primary}} />, 
                h2 : <h2 style={{fontSize : "1.7rem"}} />,
                p : <span style={{lineHeight : "2.5rem", fontSize : "1.5rem", marginTop : "0.5rem"}} />,
              }
            }
          />
        </Section>
      </Content>
    </Container>
  )
}

export default Mentions;

const Container = styled.div`
  flex             : 1 1 auto;
  display          : flex;
  flex-direction   : column;
  background-color : rgb(244, 244, 248);
  position         : relative;
  padding          : 5rem 5rem;

  @media screen and (min-width : ${sizeMobile}px) {
    flex             : 1 1 0%;
    min-height     : 85vh;
  }
  
`;
const Content = styled.div<any>`
  height : ${({heightContent}) => `${heightContent}px`}; 
  @media screen and (min-width : ${sizeMobile}px) {
    height : 0px;
  }
  display          : flex;
  flex-direction   : column;
  flex             : 1 1 auto;
  background-color : ${({theme}) => theme.colors.neutral};
  overflow         : auto;
  padding          : 3rem;
  position         : relative;
`;

const Section = styled.div`
  margin-top : 5rem;
`;

const CloseButton = styled.div`
  position : absolute;
  top      : 1.5rem;
  right    : 2rem;
  cursor   : pointer;
`;