import defaultState from '../defaultState';

const loginReducer = (state = defaultState.login, action : any) => {
  switch (action.type) {
    case '@@RESET':
      return defaultState.login;
      
    case 'LOGIN_FAILED':
      return {
        ...defaultState.login,
        errorMessage : action.errorMessage
      }

    case 'LOGIN_SUCCEEDED':
      return {
        ...state,
        logged    : true,
        accountID : action.accountId,
        token     : action.token,
        email     : action.email
      };

    default:
      return state;
  }
};

export default loginReducer;