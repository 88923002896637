import { groupsAge } from "../utils/constants";

const defaultState = {
  init : {
    done : false
  },
  router : {},
  login : {
    logged : false
  },
  loading : {},
  user : {
    username : '', 
    avatar    : ''
  },
  nomenclatures : {
    data : [],
    selected : {}
  },
  datasets : {
    data : [],
    selected : {}
  },
  filters : {
    years : {
      data : [
        {label : 'Toutes', value : '0'},
      ],
      selected : '',
      defaultValue : ''
    },
    quarterly : {
      data : [
        {label : 'Tous les trimestres', value : ''},
        // {label : '1er Trimestre', value : '1'}, 
        // {label : '2ème Trimestre', value : '2'}, 
        // {label : '3ème Trimestre', value : '3'}, 
        // {label : '4ème Trimestre', value : '4'},
      ],
      selected : '',
    },
    regions : {
      data : [],
      selected : '',
      defaultValue : ''
    },
    departements : {
      data : [],
      selected : '',
      defaultValue : '',
      departementsFiltered : []
    },
    intercos : {
      data : [],
      selected : '',
      defaultValue : '',
      intercosFiltered : []
    },
    comparison : {
      data : [],
      selected : '',
      defaultValue : ''
    },
    pcs_niv1 : {
      data : [],
      selected : '',
      defaultValue : ''
    },
    sectors_niv1 : {
      data : [],
      selected : '',
      defaultValue : ''
    },
    groupsAgeCttNiv1 : {
      data : groupsAge,
      selected : '',
      defaultValue : ''
    },
    groupsAgeCttNiv2 : {
      data : groupsAge,
      selected : '',
      defaultValue : ''
    },
    groupsAgeSector : {
      data : groupsAge,
      selected : '',
      defaultValue : ''
    }
  },
  paramsFiles : {
    territory : '',
    period : '',
    age : {
      ctt : {
        niv1 : 'GA-1',
        niv2 : 'GA-1'
      },
      sector : 'GA-1'
    }
  },
  popup : {
    show : false,
    errorMessage : ''
  },
  global : {},
  maps : {}
}

export default defaultState;