import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

import Icon from './Icon';
import MenuButton from './MenuButton';

import { theme } from '../../assets/theme';
import { CTT_DATA, CTT_JOBS, CTT_SECTORS, CDII } from '../../routes/paths';
import { sizeMobile } from '../../utils/constants';
export interface NavigationHeaderProps {
    pathname: string;
}

const NavigationHeader : React.FC<NavigationHeaderProps> = ({pathname}) => {
    const navigate = useNavigate();
    return(
        <Container>
            <MenuButton 
                label = 'Données démographiques'
                showTitle={true}
                Icon = {({color}) => <Icon icon='demographic' color={color} width={"3.4rem"} height={"2.8rem"} />}
                color = {theme.colors.primary}
                selected = {pathname === CTT_DATA}
                onClick = {() => {
                    navigate(CTT_DATA)
                }}
            />
            <MenuButton 
                label = 'Métiers'
                showTitle={true}
                Icon = {({color}) => <Icon icon='job' color={color} width={"3.3rem"} height={"4.15rem"} />}
                color = {theme.colors.secondary}
                selected = {pathname === CTT_JOBS}
                onClick = {() => {
                    navigate(CTT_JOBS)
                }}
            />
            <MenuButton 
                label = "Secteurs d'activité"
                showTitle={true}
                Icon = {({color}) => <Icon icon='activity' color={color} width={"3.1rem"} height={"2.5rem"} />}
                color = {theme.colors.thirdary}
                selected = {pathname === CTT_SECTORS}
                onClick = {() => {
                    navigate(CTT_SECTORS)
                }}
            />
            <MenuButton 
                label = 'CDI Intérimaire'
                showTitle={false}
                Icon = {({color}) => <Icon icon='contract' color={color} width={"3.863rem"} height={"4rem"} />}
                color = {theme.colors.fourthly}
                selected = {pathname === CDII}
                onClick = {() => {
                    navigate(CDII)
                }}
            />
        </Container>
    )
}

export default NavigationHeader;

const Container = styled.div`
    display          : flex;
    flex-direction   : row;
    justify-content  : space-between;
    background-color : #F9F8F6;
    height           : 100%;

    @media screen and (max-width : ${sizeMobile}px) {
        flex-direction   : column;
        height           : 50%;
        width            : 100%;
        justify-content  : flex-start;
    }
`;