import React from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';

import { LabelSide } from '../../types';
import { theme } from '../../assets/theme';
import { sizeMobile } from '../../utils/constants';

/**
 * Switch Button
 * @param {string} label on the right on switch button
 * @param {boolean} selected 
 * @param {function} onClick behavior when switch 
 */

const SwitchButton: React.FC<SwitchProps> = ({
  label,
  onClick,
  selected,
  labelSide,
  value,
  disabled,
  color,
  colorBar,
  ...props
}) => {
  return (
    <StyledSwitch
      selected={selected}
      colorBar={colorBar}
      {...props}
    >
      {labelSide === 'left' && <Span selected={selected} disabled={disabled} labelSide={labelSide}>{label}</Span>}
      <Switch
        checked={selected}
        onChange={()=>onClick(value)}
        onColor={color}
        onHandleColor={color}
        handleDiameter={20}
        boxShadow='0px 2px 2px #A6A9BF'
        activeBoxShadow='0px 0px 1px 5px rgba(0, 0, 0, 0.2)'
        height={14}
        uncheckedIcon={false}
        checkedIcon={false}
        width={31}
        className='react-switch'
        id={label}
        disabled={disabled}
      />
      {labelSide === 'right' && <Span selected={selected} color={color} disabled={disabled} labelSide={labelSide}>{label}</Span>}
    </StyledSwitch>
  );
};

export default SwitchButton;

export interface SwitchProps {
  label     : string;
  value     : string;
  selected  : boolean;
  onClick   : (value : any) => void;
  labelSide : LabelSide;
  disabled? : boolean; 
  color?: string;
  colorBar?: string;
}

interface SpanProps {
  selected  : boolean;
  labelSide : LabelSide;
  disabled : boolean;
}

interface StyledSwitchProps {
  selected : boolean;
  colorBar?: string;
}

const StyledSwitch = styled.div<StyledSwitchProps>`
  display           : flex;
  flex-direction    : row;
  align-items       : center;

  .react-switch-bg {
      background-color : ${({theme, selected, colorBar}) => selected ? colorBar : theme.colors.borderLight} !important;
  }
`;

const Span : any = styled.span<SpanProps>`
  height       : 100%;
  display      : inline-block;
  font-family  : work-sans-medium;
  font-size    : 1.4rem;
  color        : ${({theme, selected, disabled, color}) => disabled ? theme.colors.lightText : selected ? color : theme.colors.text};
  margin-left  : ${({labelSide})=> labelSide === 'right' ? '13px' : '0'};
  margin-right : ${({labelSide})=> labelSide === 'left' ? '13px' : '0'};
  max-width    : 150px;

  @media screen and (min-width : ${sizeMobile}px) {
    max-width : none;
  }
`;

