import React, { ReactComponentElement } from 'react';
import styled from 'styled-components';
import { IconLabel } from '../../types';

import Icon from './Icon';

import pxToRem from '../../utils/dimension';


export interface GenericButtonProps {
  onClick : () => void;
  iconName : IconLabel;
  iconColor : string;
  title : string;
}

interface TitleProps {
  color : string;
}

interface ContainerProps {

}

/**
 * Creating a button e.g : Exporting data
 * @param { () => void } onClick
 * @param { string } iconName
 * @param { string } iconColor
 * @param { string } title 
 * @param { number } height
 * @param { number } width
 * @returns { React.FC } GenericButton
 */

const GenericButton : React.FC<GenericButtonProps> = ({
  onClick,
  iconName,
  iconColor,
  title
}) => {
  return(
    <Container onClick={onClick}>
      <div style={{marginLeft : '1.4rem'}}>
        <NavigationIcon icon={iconName} color={iconColor} width={"1.6rem"} height={"1.6rem"} />
      </div>
      <Title color={iconColor}>{title}</Title>
    </Container>
  )
}

export default GenericButton;

const NavigationIcon = styled(Icon)`
  width : ${pxToRem(16.61)}rem;
`;

const Container = styled.div<ContainerProps>`
  display          : flex;
  padding          : 0px;
  flex-direction   : row;
  background-color : #343434;
  border           : none;
  height           : ${pxToRem(38)}rem;
  width            : ${pxToRem(206)}rem;
  border-radius    : 5px;
  justify-content  : center;
  align-items      : center;
  opacity          : 1;
  transition-property : opacity, transform;
  transition-duration : 0.2s, 0.2s;
  &:hover{
    opacity : 0.5;
  }
  &:active {
    transform : scale(0.95);
  }
  cursor : pointer;
`;

const Title = styled.div<TitleProps>`
  flex          : 1;
  color         : ${({color}) => color};
  font-family   : work-sans-regular;
  font-size     : ${pxToRem(14)}rem;
  font-weight   : 400;
  margin-left   : ${pxToRem(10.4)}rem;
  margin-right  : ${pxToRem(18.4)}rem;
  width         : auto;
  white-space   : nowrap;
`;

