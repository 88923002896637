export const updateTotal = (dataType: string, value: number) => ({
  type : 'UPDATE_TOTAL',
  dataType,
  value
})

export const disablePeriod = () => ({
  type : 'DISABLE_PERIOD',
})

export const updateCurrentLabel = (current: string) => ({
  type : 'UPDATE_CURRENT_LABEL',
  current,
})

export const updateComparisonLabel = (comparison: string) => ({
  type : 'UPDATE_COMPARISON_LABEL',
  comparison
})

export const updatePopupColor = (color: string) => ({
  type : 'UPDATE_POPUP_COLOR',
  color
})