import React from 'react';
import styled from 'styled-components';
import QuarterPieIndicator from '../atoms/QuarterPieIndicator';
import pxToRem from '../../utils/dimension';


/** 
 * Creating 2 quarter pie charts  
 * @param { number } size                size of the chart
 * @param { number } firstQuarterNumber  value of the first element of the chart
 * @param { string } firstQuarterText    title of the first element of the chart
 * @param { number } secondQuarterNumber value of the second element of the chart
 * @param { string } secondQuarterText   title of the second element of the chart
 * @param { string } firstColor          color of the first part of the chart 
 * @param { string } secondColor         color of the second part of the chart
 * @param { boolean } isComparing        display comparing graphs
 * @returns { React.FC } CompareTwoQuarters
 */

const CompareTwoQuarters : React.FC<CompareTwoQuartersProps> = ({
    firstQuarterNumber, 
    firstNationalQuarterNumber,
    firstQuarterText, 
    secondQuarterNumber, 
    secondNationalQuarterNumber,
    secondQuarterText,
    firstColor,
    secondColor,
    workContract,
    isComparing,
    comparisonText
}) => {

    const firstPercentage = 100 * firstQuarterNumber / (firstQuarterNumber + secondQuarterNumber);
    const secondPercentage = 100 * secondQuarterNumber / (firstQuarterNumber + secondQuarterNumber);

    const firstcomparisonPercentage = 100 * firstNationalQuarterNumber / (firstNationalQuarterNumber + secondNationalQuarterNumber);
    const secondcomparisonPercentage = 100 * secondNationalQuarterNumber / (firstNationalQuarterNumber + secondNationalQuarterNumber);

    return(
        <Container>
            <QuarterContainer>
                { !isComparing ? 
                <Text justify='end'>
                    <TextField style={{fontFamily: 'work-sans-medium'}} color={firstColor}>
                        {firstQuarterText}
                    </TextField>
                    
                    <TextField color='grey'>
                        {firstQuarterNumber.toLocaleString()} {workContract}
                    </TextField>

                    <TextField style={{fontFamily: 'work-sans-semiBold'}} color={firstColor}>
                        {Math.round(10 * firstPercentage) / 10}%
                    </TextField>
                </Text>
                :
                <Text justify='end'>
                    <TextField style={{fontFamily: 'work-sans-medium'}} color={firstColor}>
                        {firstQuarterText}
                    </TextField>

                    <TextField style={{fontFamily: 'work-sans-semiBold'}} color={firstColor}>
                        {Math.round(10 * firstPercentage) / 10}%
                    </TextField>
                    
                    <div style={{height : `${pxToRem(15)}rem`}}></div>

                    <TextField color={firstColor}>
                        {comparisonText}
                    </TextField>

                    <TextField color={firstColor}>
                        {Math.round(10 * firstcomparisonPercentage) / 10}%
                    </TextField>
                </Text>
                
            }
                <QuarterPieIndicator 
                percentage={firstPercentage}
                comparisonPercentage={firstcomparisonPercentage}
                color={firstColor}
                direction='left'
                isComparing={isComparing}
                />
            </QuarterContainer>

            <QuarterContainer>
                { !isComparing ?
                <Text justify="start">
                    <TextField style={{fontFamily: 'work-sans-medium'}} color={secondColor}>
                        {secondQuarterText}
                    </TextField>
                    <TextField color='grey'>
                        {secondQuarterNumber.toLocaleString()} {workContract}
                    </TextField>
                    <TextField style={{fontFamily: 'work-sans-semiBold'}} color={secondColor}>
                        {Math.round(10 * secondPercentage) / 10}%
                    </TextField>
                </Text>
                :
                <Text justify='start'>
                    <TextField style={{fontFamily: 'work-sans-medium'}} color={secondColor}>
                        {secondQuarterText}
                    </TextField>

                    <TextField style={{fontFamily: 'work-sans-semiBold'}} color={secondColor}>
                        {Math.round(10 * secondPercentage) / 10}%
                    </TextField>

                    <div style={{height : `${pxToRem(15)}rem`}}></div>

                    <TextField style={{fontFamily: 'work-sans-medium'}} color={secondColor}>
                        {comparisonText}
                    </TextField>

                    <TextField style={{fontFamily: 'work-sans-semiBold'}} color={secondColor}>
                        {Math.round(10 * secondcomparisonPercentage) / 10}%
                    </TextField>
                </Text>
                }
                <QuarterPieIndicator 
                    percentage={secondPercentage}
                    comparisonPercentage={secondcomparisonPercentage}
                    color={secondColor}
                    direction='right'
                    isComparing={isComparing}
                />
            </QuarterContainer>
        </Container>
    )
}

export default CompareTwoQuarters;

export interface CompareTwoQuartersProps {
    firstQuarterText : string;
    secondQuarterText: string;
    firstQuarterNumber : number;
    firstNationalQuarterNumber : number;
    secondQuarterNumber : number;
    secondNationalQuarterNumber : number;
    firstColor : string;
    secondColor : string;
    workContract : string;
    isComparing : boolean;
    comparisonText?: string;
}

interface TextFieldProps {
    color : string;
}

interface TextProps {
    justify : Justify;
}

const Container = styled.div`
    display        : flex;
    flex-direction : row;
`;

const QuarterContainer = styled.div`
    display        : flex;
    flex-direction : column;
    align-items    : center;
`;

const TextField = styled.div<TextFieldProps>`
    color : ${({color}) => color};
    padding : 0.2rem 0;
`;

const Text = styled.div<TextProps>`
    text-align  : ${({justify}) => justify};
    font-family : work-sans-medium;
    font-size   : ${pxToRem(14)}rem;
    ${({justify}) => justify !== 'start' ? `margin-right : ${pxToRem(90)}rem;` : `margin-left : ${pxToRem(90)}rem;`}
`;

type Justify = 'start' | 'end';

