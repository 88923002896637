import { put, takeEvery, call } from 'redux-saga/effects';
import { apiCall, client } from './axiosApiCallWrapper';

import { initSucceeded } from '../actions/init';
import { loginSucceeded } from '../actions/auth';

function* checkUser(accountId: string, token: string) {
  try {
    yield call(apiCall, 'GET',`${window._env_.ACCOUNTS_API_URL}/me`
    );

    return true;
  }
  catch(error) {
    localStorage.clear();
    return false;
  }
}

function* initSaga() {
  const token: string     = yield call([localStorage, localStorage.getItem], 'TOKEN');
  const accountId: string = yield call([localStorage, localStorage.getItem], 'ACCOUNT_ID');
  const email: string     = yield call([localStorage, localStorage.getItem], 'EMAIL');
  yield put(initSucceeded());

  // if(window._env_.TOKEN){
  //   client.defaults.headers.common['Authorization'] = `Bearer ${window._env_.TOKEN}`;
  //   yield put(initSucceeded());
  // } else {
  //   if (token && accountId && email) {
  //     client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  //     const logged: boolean = yield call(checkUser, accountId, token);
  //     if (logged) {
  //       yield put(loginSucceeded(accountId, email, token));
  //     }
  //   }
  // }

}

export default initSaga;

