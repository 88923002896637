import defaultState from '../defaultState';

const filesReducer = (state = defaultState.global, action : Action) => {
  switch (action.type) {
    case '@@RESET':
    case 'LOGIN_FAILED':
      return defaultState.global;
        
    case 'UPDATE_TOTAL':
      return {
        ...state,
        [action.dataType] : action.value
      }
    case 'DISABLE_PERIOD' :
      return {
        ...state,
        disablePeriod : true
      }
    case 'UPDATE_COMPARISON_LABEL':
      return {
        ...state,
        comparisonLabel : action.comparison
      }
    case 'UPDATE_CURRENT_LABEL':
      return {
        ...state,
        currentLabel : action.current
      }
    case 'UPDATE_POPUP_COLOR' :
        return {
          ...state,
          popupColor : action.color
        }
    default:
      return state;
  }
};

export default filesReducer;

interface Action {
  type : string;
  value : number;
  dataType: string;
  comparison: string;
  current: string;
  color: string;
}