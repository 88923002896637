import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

import BarGraph from '../atoms/BarGraph';
import Dropdown from '../molecules/DropDown';

import { t } from '../../i18n';
import { theme } from '../../assets/theme';
import { RootState, BarGraphData } from '../../types';
import LegendGraph from '../atoms/LegendGraph';
import { sizeMobile } from '../../utils/constants';

interface ContainerBarWithFilterProps {
  onSelectAge : (value: string | undefined) => void;
  data: BarGraphData[];
  comparison?: number;
  territoryLabel?: string;
  comparisonLabel?: string;
  color: string;
  isComparing?: boolean;
  total: number;
}

const ContainerBarWithFilter: React.FC<ContainerBarWithFilterProps> = ({onSelectAge, data, comparison, comparisonLabel, territoryLabel, color, isComparing=false, total}) => {
  const {groupsAgeCttNiv1} = useSelector((state: RootState) => state.filters);
  const loading = useSelector((state: RootState) => state.loading);
  
  return (
    <Container>
      <Left>
        {
          data?.filter((d) => d?.value).length ?
          <BarGraph 
            color={color}
            data={data}
            isComparing={isComparing} 
            total={total}
            comparisonTotal={comparison || 0}
          /> :
          <div style={{display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100%', width : '100%'}}>
            <Text>{t('Chart.nodata')}</Text>
          </div>
        }
      </Left>
      <Right>
        <ContainerRight>
          <Section>{t(`BubbleChart.groupAge`)}</Section>
          <Dropdown 
            placeholder={t('Filter.groupsAge.placeholder')}
            items={groupsAgeCttNiv1.data}
            onSelect={onSelectAge}
            selected={groupsAgeCttNiv1.selected}
            disabled={loading?.calculation > 0}
            colors={{button : theme.colors.job.primary.medium, pictos : theme.colors.neutral}}
          />
          {
            isComparing ? 
            <div style={{width : '100%'}} >
              <LegendGraph 
                color={color || ""}
                comparisonLabel={comparisonLabel || ''}
                territoryLabel={territoryLabel || ''}
              />
            </div>
              : null
          }
        </ContainerRight>
      </Right>
    </Container> 
  )
}

export default ContainerBarWithFilter;

const Container = styled.div`
  height         : 43rem;
  display        : flex;
  flex-direction : row;
  width          : 100%;

  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction : column-reverse;
    height         : 100%;
  }
`;

const Left = styled.div`
  flex         : 2;
  padding-left : 4rem;

  @media screen and (max-width : ${sizeMobile}px) {
    padding-left : 0rem;
    padding-top  : 3rem;
    height       : 100rem;
  }
`;

const Right = styled.div`
  flex             : 1;
  padding-top      : 7rem;
  display          : flex;
  flex-direction   : column;
  align-items      : flex-end;
  padding-left     : 1.8rem;

  @media screen and (max-width : ${sizeMobile}px) {
    align-items      : flex-start;
    padding-top      : 1rem;
    padding-left     : 0rem;
  }
`;

const Section = styled.div`
  color         : ${({theme}) => theme.colors.text};
  font-size     : 1.6rem;
  font-family   : work-sans-medium;
  margin-bottom : 0.6rem;
`;

const ContainerRight = styled.div`
  width : 21rem;
`;

const Text = styled.div`
  font-family : work-sans-medium;
  font-size   : 2rem;
  color       : ${({theme}) => theme.colors.text};
`;