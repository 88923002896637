import { IconProps } from "../../types";

export const Logo : React.FC<IconProps> = ({width=30, height=30}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30.882 11.255">
    <g id="Group_3" data-name="Group 3" transform="translate(-0.19 -0.498)">
      <path id="Fill_1" data-name="Fill 1" d="M5.69,11.255A5.573,5.573,0,0,1,1.643,9.642,5.421,5.421,0,0,1,0,5.628,5.422,5.422,0,0,1,1.643,1.613,5.578,5.578,0,0,1,5.69,0,5.578,5.578,0,0,1,9.736,1.613,5.422,5.422,0,0,1,11.38,5.628,5.421,5.421,0,0,1,9.736,9.642,5.573,5.573,0,0,1,5.69,11.255ZM5.7,2.12A2.978,2.978,0,0,0,3.411,3.148a3.592,3.592,0,0,0-.93,2.487,3.561,3.561,0,0,0,.93,2.479A2.985,2.985,0,0,0,5.7,9.135,2.986,2.986,0,0,0,7.984,8.114a3.565,3.565,0,0,0,.931-2.479,3.6,3.6,0,0,0-.931-2.487A2.979,2.979,0,0,0,5.7,2.12Z" transform="translate(0.19 0.498)" fill="#00b3d4"/>
      <path id="Fill_27" data-name="Fill 27" d="M0,10.9H2.418V0H0Z" transform="translate(14.945 0.678)" fill="#fbbc15"/>
      <path id="Fill_37" data-name="Fill 37" d="M2.418,10.9H0V0H4.108A5.9,5.9,0,0,1,7.713.858,3.333,3.333,0,0,1,8.79,3.617,3.221,3.221,0,0,1,6.728,7l2.743,3.9L6.5,10.9l-2.4-3.477H2.418V10.9Zm0-8.809V5.317H4.186a2.6,2.6,0,0,0,1.689-.406,1.658,1.658,0,0,0,.451-1.286A1.385,1.385,0,0,0,5.86,2.417,2.97,2.97,0,0,0,4.233,2.09Z" transform="translate(21.6 0.677)" fill="#e5444a"/>
    </g>
  </svg>
)

