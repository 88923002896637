import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';

import { Logo } from '../../assets/svg-images/logo';
import FitMenu from '../organisms/FitMenu';

import { t } from '../../i18n';
import { calcHeightMobileRem, calcRem, calcWidthMobileRem } from '../../utils/dimension';
import { sizeMobile } from '../../utils/constants';

interface HomepageProps {
  children: React.ReactNode;
}

const HomepageTemplate: React.FC<HomepageProps> = ({children}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1];
  const view : any = path;

  const handleClickMenu = (value: string) => {
    navigate(value);
  }

  return (
    <Container>
      <Left>
        <ContainerLogo>
          <div>
            <Logo height={32} width={32} />
            <Text>{t('Header.logo')}</Text>
          </div>
        </ContainerLogo>
        <FitMenu selected={view} onClick={handleClickMenu} />
      </Left>
      <Right>
        {children}
      </Right>
    </Container>
  )
}

export default HomepageTemplate;

const Container = styled.div`
  display        : flex;
  flex-direction : row;
  min-height     : 100%;

  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction : column;
  }
`;

const Left = styled.div`
  width : ${calcRem(9.4)}rem;
  box-shadow : 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 20px 60px rgba(131, 131, 145, 0.75);

  @media screen and (max-width : ${sizeMobile}px) {
    display        : flex;
    flex-direction : row;
    width          : 100%;
    height         : ${calcHeightMobileRem(6.8)}rem;
  }
`;

const Right = styled.div`
  flex           : 1;
  display        : flex;
  flex-direction : column;
`;

const ContainerLogo = styled.div`
  height          : ${calcRem(9.2)}rem;
  box-shadow      : 1px 0px 10px rgba(0,0,0,0.12);
  display         : flex;
  flex-direction  : column;
  justify-content  : center;
  align-items     : center;

  @media screen and (max-width : ${sizeMobile}px) {
    display        : flex;
    align-items    : center;
    width          : ${calcWidthMobileRem(9.2)}rem;
    height         : 100%;
    padding-left   : 0;
  }
`;

const Text = styled.div`
  color       : ${({theme})=> theme.colors.text};
  font-family : work-sans-medium;
  font-size   : 1.2rem;
  margin-top  : -0.7rem;
  width       : ${calcRem(6.8)}rem;
  line-height : 1.1rem;
  text-align  : left;

  @media screen and (max-width : ${sizeMobile}px) {
    font-size   : 1.8rem;
    line-height : 1.6rem;
    width       : ${calcWidthMobileRem(5.8)}rem;
    text-align  : left;
    margin-top  : -1rem;
  }
`;