import React from 'react';
import styled from'styled-components';
import BarIndicatorWithDot from '../atoms/BarIndicatorWithDot';
import pxToRem from '../../utils/dimension';
import { sizeMobile, statisticSecretValue } from '../../utils/constants';
import { t } from '../../i18n';

export interface CompareBarIndicatorWithDotProps {
  color : string;
  percentage : number;
  comparisonPercentage : number;
  width: number;
  label : string;
  description : string;
  isComparing : boolean;
  comparisonLabel?: string;
  value?: number;
}


const CompareBarIndicatorWithDot : React.FC<CompareBarIndicatorWithDotProps> = ({ color, percentage, comparisonPercentage, width, label, description, isComparing=false, comparisonLabel, value}) => {

  return(
    <Container>
      <LabelContainer width={365}>{description}</LabelContainer>
      {
        value === statisticSecretValue ?
        <StatisticSecret>
          <SecretText>{t('StatisticSecret.lessThan3')}</SecretText>
        </StatisticSecret> :
        <PathContainer>
          <BarIndicatorWithDot 
            color={color} 
            percentage={percentage} 
            comparisonPercentage={comparisonPercentage} 
            width={width} 
            label={label} 
            isComparing={isComparing} 
            comparisonLabel={comparisonLabel} 
            value={value || 0}
          />
        </PathContainer>
      }
    </Container>
  )
}

export default CompareBarIndicatorWithDot;

interface LabelContainerProps {
  width : number;
}

const Container = styled.div`
  flex            : 1;
  display         : flex;
  flex-direction  : row;
  align-items     : center;
  margin-bottom   : ${pxToRem(20)}rem;
`;

const PathContainer = styled.div`
  flex             : 1;
  padding-left     : 10px;
  background-color : white;
  height           : 15px;
  display          : flex;
  align-items      : center;

  @media screen and (max-width : ${sizeMobile}px) {
    padding-left     : 3rem;
  }
`;

const LabelContainer = styled.div<LabelContainerProps>`
  font-size     : ${pxToRem(14)}rem;
  width         : 36rem;
  font-family   : work-sans-regular;
  text-overflow : ellipsis;
  white-space   : nowrap;
  overflow      : hidden;

  @media screen and (max-width : ${sizeMobile}px) {
    overflow      : hidden;
    min-width     : 25rem;
    max-width     : 25rem;
  }
`;

const StatisticSecret = styled.div`
  flex    : 1;
  max-width : 50rem;
`;

const SecretText = styled.div`
  font-family  : work-sans-regular;
  font-size    : 1.3rem;
  line-height  : 1.5rem;
  padding      : 1rem;
`;