import reportWebVitals from './reportWebVitals';
import * as React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './index.css';
import { I18nextProvider } from 'react-i18next';
import {i18next} from './i18n'

const container = document.getElementById('root');
if(container){
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
