import React from 'react';
import styled from 'styled-components';

interface HeaderMobileProps {
  LeftItem : React.FC;
  RightItem : React.FC;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({LeftItem, RightItem}) => {
  return (
    <Container>
      <Left>
        <LeftItem />
      </Left>
      <Right>
        <RightItem />
      </Right>
    </Container>
  )
}

export default HeaderMobile;

const Container = styled.div`
  height         : 12rem;
  width          : 100%;
  display        : flex;
  flex-direction : row;
  align-items    : center;
  box-shadow     : ${({theme}) => theme.shadow.list};
  z-index        : 1;
`;

const Left = styled.div`
  flex           : 1;
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: space-between;
`;

const Right = styled.div`
  flex           : 1;
  padding-right  : 2.45rem;
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: flex-end;
`;