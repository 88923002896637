import React, { Ref, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Tooltip: React.FC<TooltipProps> = ({
  label,
  total,
  percentage,
  color,
}) => {
  const [widthCard, setWidthCard] = useState(0);
  const RefCard = useRef<any>(null);

  useEffect(() => {
    setWidthCard(RefCard?.current?.offsetWidth)
  }, [])

  return (
    <Container>
      <Arrow />
        <Card ref={RefCard} left={(widthCard/2)-5}>{/*5 corresponding to width / 2 of Arrow ELement */}
        <Content>
          <Label>{label}</Label>  
          <Result>{total?.toLocaleString()}</Result>
          {percentage && <Percentage color={color}>{percentage}%</Percentage>}
        </Content>         
        </Card>
    </Container>
  );
};

export default Tooltip;

export interface TooltipProps {
  label: string;
  total: number;
  percentage: string;
  color: string;
}

const Container = styled.div`
  position   : relative;
  width      : 10px;
  height     : 10px;
`;

const Content = styled.div`
  flex            : 1;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  margin          : 10px 10px 5px 10px;
`;

const Card = styled.div<any>`
  position         : absolute;
  box-shadow       : ${({theme}) => theme.shadow.overlayMapCard};
  background-color : white;
  border-radius    : 8px;
  bottom           : 4px;
  left             : ${({left}) => `-${left}px`};
  width            : 15rem;
`;

const Label = styled.div`
  color         : ${({theme}) => theme.colors.text};
  font-family   : work-sans-regular;
  font-size     : 1.4rem;
  margin-bottom : 3px;
  text-align    : center;
`;

const Result = styled.div`
  color         : ${({theme}) => theme.colors.darkText};
  font-family   : work-sans-regular;
  font-size     : 1.4rem;
  margin-bottom : 5px;
`;

const Percentage = styled.div`
  color : ${({color})=> color};
  font-family : work-sans-semiBold;
  font-size : 1.6rem;
`;

const Arrow = styled.div`
  position   : absolute;
  height     : 10px;
  width      : 10px;
  box-shadow : 8px 5px 17px #24263b17;
  background-color : white;
  transform : rotate(45deg);
  z-index : 1;
`;



