import React from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

import { theme } from '../../assets/theme';
/**
 * Generic Button
 * @param {string} label 
 * @param {boolean} loading show a spinner when loading
 * @param {function} onClick behavior when click on button
 * @param {React.FC} Icon add icon inside button
 */
const Button: React.FC<ButtonProps> = ({
  loading,
  onClick=()=>{},
  children,
  centered,
  style,
  ...props
}) => {
  return (  
    <StyledButton
      type='button'
      onClick={onClick}
      align={centered ? 'center' : 'flex-start'}
      style={style}
      {...props}
    >
      {
        loading ?
          <ClipLoader loading={true} size={20} color={theme.colors.neutral} /> :
          <React.Fragment>
            {children}
          </React.Fragment>
      }
    </StyledButton>
  );
};

export default Button;

export interface ButtonProps {
  loading?: boolean;
  onClick: () => void;
  centered : boolean;
  children : React.ReactNode;
  style?: any;  
}

interface StyledButtonProps {
  align : string
}


const StyledButton = styled.button<StyledButtonProps>`
  width             : 100%;
  height            : 100%;
  display           : flex;
  flex-direction    : row;
  align-items       : center;
  justify-content   : ${({align}) => align};
  border            : 0;
  border-radius     : 5px;
  cursor            : pointer;
  background-color  : ${({theme}) => theme.colors.primary};
  color             : ${({theme}) => theme.colors.neutral};
  font              : ${({theme}) => theme.fonts.general.button};

  :hover {
    background-color : ${({theme}) => theme.colors.text};
  }
`;
