const pxToRem  = (px : number) => {
    return px / 10;
}

export const calcRem = (value: number) => {
    return value * 1.1323
} 

export const calcWidthMobileRem = (value: number) => {
    return value * 2.18
}

export const calcHeightMobileRem = (value: number) => {
    return value * 1.64
}

export const getWidth = () => (
    "screen?.width"
)

export const getHeight = () => {
    "screen?.height"
}

export default pxToRem;
