import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { loginSucceeded, loginFailed } from '../actions/auth';
import { loadingBegin, loadingEnd } from '../actions/loading';
import { t } from '../../i18n';
import { LOGIN_REQUESTED, LOGOUT_REQUESTED } from '../../utils/constants';
import { apiCall, client } from './axiosApiCallWrapper';

function* loginSaga({username, password}: {username: string, password: string, type: string}) {
  try {
    yield put(loadingBegin('login'));
    const {id, token, email : name} = yield call(apiCall, 'POST', `${window._env_.ACCOUNTS_API_URL}/tokens`, {username, password});
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
    yield call([localStorage, localStorage.setItem], 'ACCOUNT_ID', id);
    yield call([localStorage, localStorage.setItem], 'TOKEN',      token);
    yield call([localStorage, localStorage.setItem], 'EMAIL',      name);
    
    yield put(loginSucceeded(id, name, token));
    yield put(loadingEnd('login'));
  } 
  catch (error: any) {
    yield put(loadingEnd('login'));
    if (error.status === 401) {
      yield put(loginFailed(t('Login.wrongCredentials')));
    }
    else {
      yield put(loginFailed(t('Login.connectionError')));
    }
  }
}

function* logoutSaga() {
  yield put({type : '@@RESET'});
  yield call([localStorage, localStorage.clear]);
}


function* authSaga() {
  yield takeEvery(LOGIN_REQUESTED, loginSaga);
  yield takeEvery(LOGOUT_REQUESTED, logoutSaga);
}

export default authSaga;