import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell } from "recharts";
import pxToRem from '../../utils/dimension';


export interface PieIndicatorProps {
  percentage : number;
  pathColor : string;
  direction : Direction
}

interface PercentageTextProps {
  color : string;
}


interface PercentageTextProps {
  color : string;
}

interface ContainerProps {
  direction : Direction;
}

type Direction = 'row' | 'column-reverse';

const PieIndicator : React.FC<PieIndicatorProps>= ({percentage, pathColor, direction}) => {

  const radius = 50;

  const data = [
    {name : "", value : percentage},
    {name : "", value : 100 - percentage}
  ];

  const COLORS = [pathColor, 'rgba(223, 223, 223, 1)'];

  return(
    <Container direction={direction} >
      <PercentageText color={pathColor}>
        {`${percentage}%`}
      </PercentageText>
      <PieChart width = {radius} height = {radius} margin={{top : 0, left : 0, right : 0, bottom : 0}}>
        <Pie
          data={data}
          cx={radius / 2}
          cy={radius / 2}
          outerRadius={14}
          fill="#8884d8"
          dataKey="value"
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
          <Cell key={`cell-${index}`} strokeWidth={1.3} fill={COLORS[index % COLORS.length]} />
        ))}
        </Pie>
      </PieChart>
    </Container>
  )
}

export default PieIndicator;

const Container = styled.div<ContainerProps>`
  display          : flex;
  flex-direction   : ${({direction}) => direction};
  justify-content  : center;
  align-items      : center;
  background-color : transparent;
`;

const PercentageText = styled.div<PercentageTextProps>`
  color            : ${({color}) => color};
  background-color : transparent;
  font-size        : ${pxToRem(18)}rem;
  font-family      : work-sans-semiBold;
`;