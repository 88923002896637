import { all } from 'redux-saga/effects';

import initSaga from './init';
import authSaga   from './auth';
import filtersSaga from './filters';
import filesSaga from './files';
import mapsSaga from './maps';

export default function* Sagas() {
  yield all([
    initSaga(),
    authSaga(),
    filtersSaga(),
    filesSaga(),
    mapsSaga(),
  ]);
}