import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import SwitchButton from '../atoms/SwitchButton';
import SwitchButtonWithIcons from '../atoms/SwitchButtonWithIcons';
import Map from '../molecules/Map';
import MapLegend from '../molecules/MapLegend';
import List from './List';

import { t } from '../../i18n';
import { ColorsPanel, Commune, Interco, MapTypes, RootState } from '../../types';
import { getLegend } from '../../utils/helpers';
import Tooltip from '../atoms/Tooltip';
import LegendGraph from '../atoms/LegendGraph';
import useWindowSize from '../../hooks/useWindowSize';
import { sizeMobile } from '../../utils/constants';
import { IconHours } from '../../assets/svg-images/IconHours';
import pxToRem from '../../utils/dimension';
import { theme } from '../../assets/theme';

interface ContainerMapProps {
  color?: string;
  data: {key: string, value: number}[];
  onClickSwitchButton: (value: MapTypes) => void;
  dataView: string;
  colors: ColorsPanel;
  selectedMap: MapTypes;
  dataType : 'n' | 'r' | 'd' | 'i';
  isComparing?: boolean;
  territoryLabel?: string;
  comparisonLabel?: string;
  hoursData?: any;
}

const ContainerMap: React.FC<ContainerMapProps> = ({
  color,
  data,
  onClickSwitchButton,
  dataView,
  colors,
  selectedMap,
  dataType,
  isComparing,
  territoryLabel,
  comparisonLabel,
  hoursData
}) => {
  const size = useWindowSize();
  const filters = useSelector((state: RootState) => state.filters);
  const loading = useSelector((state: RootState) => state.loading);
  const { pcs_niv1, sectors_niv1, groupsAgeSector, groupsAgeCttNiv2, intercos } = filters;

  const [legend, setLegend] = useState();

  const [view, setView] = useState<'map' | 'list'>('map');
  
  const intercoSelected = intercos?.data?.find((d: Interco) => d.code_int === intercos.selected)


  useEffect(() => {
    const min = Math.min(...data.map(d => d.value));
    const max = Math.max(...data.map(d => d.value));
    const calcLegend : any = getLegend(min, max, data.map(d => d.value));
    setLegend(calcLegend)
  }, [data])

  useEffect(() => {
    if(dataView.slice(0,1) === 'i'){
      setView('map');
    }
  }, [dataView])

  const handleChangeView = () => {
    if(view === 'map'){
      setView('list'); 
    } else {
      setView('map');
    }
  }
    
  return (
    <>
    <ContainerSwitchButton>
      {
        dataType !== 'i' ?
        <SwitchButtonWithIcons 
          circleColor={colors.medium}
          checked={view === 'map'}
          onClick={handleChangeView}
          colors={{
              default : '#8E8D8A',
              selected : '#FFFFFF'
          }}
        /> : null
      }
    </ContainerSwitchButton>
    <Container isVertical={dataType === 'i'} >
      {
        view === 'map' ? 
        <Left>
          {
            dataType !== 'i' && view === 'map' ? 
            <MapLegend legend={legend} customization={colors} /> : null
          }
          {
            dataType === 'i' ?
            <ListCommunes maxHeight={intercoSelected?.communes?.length/4*2.5} >
              {
                intercoSelected?.communes?.map((c: Commune) => <LabelCommune>{c.libelle}</LabelCommune>)
              }

            </ListCommunes> : null
          }
        </Left> : null
      }
      <Middle isVertical={dataType === 'i'} >
        {
          view === 'map' ?
          <Map 
            data={_.keyBy(data, 'name')} 
            selectedMap={selectedMap} 
            OverlayCard={({section, tooltipData, color}) => {
              return (
                <Tooltip
                  label={tooltipData?.label} 
                  total={tooltipData?.value > 0 ? tooltipData?.value : "NR"} 
                  percentage={tooltipData?.value > 0 ? (Math.floor(tooltipData?.value/ _.sumBy(Object.values(data.map((d: any) => d.value))) * 10000)/100).toString() : ""} 
                  color={color} 
                />
              )}
            } 
            colors={colors}
            legend={legend} 
            isDepartement={selectedMap.slice(0,1) === 'd'}
            isInterco={selectedMap.slice(0,1) === 'i'}
            isRegion={selectedMap.slice(0,2) === 'ri'}
          /> :
          <List 
            data={data} 
            category={dataView.slice(0,1) === 'd' ? 'd' : dataView.slice(1,2)}   
            color={color || ""} 
            total={_.sumBy(data, 'value')} 
            hideLabel={true}
            comparison={isComparing}
          />
        }
      </Middle> 
      <Right style={{flex : view === 'map' ? 2 : 1}} >
        <ContainerRight>
          {
            dataType === 'n' || dataType === 'r' ?
            <Section>{t('Map.switch.title')}</Section> : null
          }
          <ContainerSwitchButtons>
            {
              dataType === 'n' ?
              <div style={{marginTop : '2rem'}}> 
                <SwitchButton 
                  label={t(`Map.switch.region`)} 
                  value={dataView} 
                  selected={dataView.slice(-1) === 'r'} 
                  onClick={() => onClickSwitchButton('r')}
                  labelSide={'right'} 
                  color={colors.medium}
                  colorBar={colors.lighter}         
                />
              </div> : null
            }
            {
              dataType === 'n' || dataType === 'r' ?
              <div style={{marginTop : '2rem'}}>
                <SwitchButton 
                  label={t(`Map.switch.departement`)} 
                  value={dataView} 
                  selected={dataView.slice(1, 2) === 'd'} 
                  onClick={() => onClickSwitchButton('d')}
                  labelSide={'right'}
                  color={colors.medium}
                  colorBar={colors.lighter}   
                />
              </div> : null
            }
            {
              dataType === 'r' ?
              <div style={{marginTop : '2rem'}}>
                <SwitchButton 
                  label={t(`Map.switch.interco`)} 
                  value={dataView} 
                  selected={dataView.slice(1,2) === 'i'} 
                  onClick={() => onClickSwitchButton('i')}
                  labelSide={'right'}
                  color={colors.medium}
                  colorBar={colors.lighter}   
                />
              </div> : null
            }
            {
              isComparing && view === 'list' ? 
              <div style={{}} >
                <LegendGraph 
                  color={color || ""}
                  comparisonLabel={comparisonLabel || ""}
                  territoryLabel={territoryLabel || ""}
                />
              </div>
                : null
            }
            {
              dataType === 'i' && 
              <TotalSection>
                {
                  hoursData ?
                  <>
                    <div style={{display : 'flex', flexDirection : 'row', justifyContent : 'space-evenly', alignItems : 'center'}}>
                        <IconHours width={25} height={25} color={theme.colors.ctt.primary.border} />
                        {hoursData && <Total color={color}>{hoursData[0]?.value.toLocaleString()}</Total>}
                    </div> 
                    <Subtitle>{t('Ctt.NumbHours.label')}</Subtitle>
                  </>
                  : 
                  <div style={{height: '5rem'}} />
                }
              </TotalSection>
            }
          </ContainerSwitchButtons>
        </ContainerRight>
      </Right>
    </Container>
    </>
  )
}  


export default ContainerMap;


const ContainerSwitchButton = styled.div`
  margin-top : -4rem;

  @media screen and (max-width : ${sizeMobile}px) {
    margin-top : 2rem;
    margin-bottom : 2rem;
  }
`;

const Container = styled.div<any>`
  display        : flex;
  flex-direction : ${({isVertical}) => isVertical ? "column-reverse" : "row"};
  width          : 100%;

  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction : column-reverse;
  }
`;

const Left = styled.div`
  flex            : 1;
  display         : flex;
  align-items     : center;
  justify-content : center;

  @media screen and (max-width : ${sizeMobile}px) {
    justify-content : flex-start;
    margin-top : 5rem;
  }
`;

const Middle = styled.div<any>`
  flex : 3;
  margin-top : ${({isVertical}) => isVertical ? "-15rem" : "0rem"};
  @media screen and (max-width : ${sizeMobile}px) {
    margin-top : 3rem;
  }
`;

const Right = styled.div`
  flex             : 1;
  padding-top      : 7rem;
  display          : flex;
  flex-direction   : column;
  align-items      : flex-end;

  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction : row;
    padding-top      : 0rem;
  }
`;

const ContainerRight = styled.div`
  width : 21rem;

  @media screen and (max-width : ${sizeMobile}px) {
    width : 46rem;
  }
`;

const Section = styled.div`
  color         : ${({theme}) => theme.colors.text};
  font-size     : 1.6rem;
  font-family   : work-sans-medium;
  margin-bottom : 0.6rem;
`;

const ContainerSwitchButtons = styled.div`
  margin-left   : 3.7rem;
  margin-bottom : 4rem;

  @media screen and (max-width : ${sizeMobile}px) {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    margin-left    : 0rem;
    margin-bottom  : 0rem;
  }
`;

const ContainerDropdown = styled.div`
  margin-bottom : 2.4rem;
`;

const Footer = styled.div`
  margin-bottom : 2.4rem;
`;

const ListCommunes = styled.div<any>`
  flex           : 1;
  display        : flex;
  flex-direction : column;
  flex-wrap      : wrap;
  max-height     : ${({maxHeight}) => `${maxHeight}rem`};
  margin-top     : 1rem;
  margin-bottom  : 4rem;
`;

const LabelCommune = styled.div`
  font-family : work-sans-regular;
  font-size   : 1.2rem;
  margin      : 0.5rem 0.5rem;
  line-height : 1.5rem;
  max-width   : 10rem;
`;

const TotalSection = styled.div`
  background-color : transparent;
  display          : flex;
  flex-direction   : column;
  background-color : transparent;
  margin           : 1rem;
  align-items      : flex-end;

  @media screen and (max-width : ${sizeMobile}px) {
    align-items : flex-start;
    margin      : 1rem 0;
  }
`;

const Subtitle = styled.div`
  background-color : transparent;
  max-width        : 23rem;
  text-align       : end;
  font-size        : 1.4rem;
  font-family      : work-sans-regular;
  margin-top       : 5px; 

  @media screen and (max-width : ${sizeMobile}px) {
    text-align : start;
    min-width : ${pxToRem(332)}rem;
  }
`;

const Total = styled.div<any>`
  color       : ${({theme}) => theme.colors.ctt.primary.border};
  font-size   : 3rem;
  font-family : work-sans-light;
  margin-left : 1.3rem;

  @media screen and (max-width : ${sizeMobile}px) {
    font-size : 1.8rem;
  }
`;