import React from 'react';
import styled from 'styled-components';
import DotLoader from "react-spinners/DotLoader";
import { t } from '../../i18n';
import { sizeMobile } from '../../utils/constants';

interface LoaderProps {
  width?: number;
  height?: number;
  color: string;
  isLoading: boolean;
  size: number;
}

export const Loader: React.FC<LoaderProps> = ({width, height, color, isLoading, size}) => {
  return (
    <Container height={height}>
      {
        isLoading ?
        <DotLoader color={color} loading={isLoading} size={size} /> :
        <Text>{t('Chart.nodata')}</Text>
      }
    </Container>
  )
}

const Container = styled.div<{height?: number}>`
  width           : 80%;
  height          : ${({height}) => height ? `${height}rem` : '400px'};
  padding-left    : 6.7rem;
  display         : flex;
  justify-content : center;
  align-items     : center;

  @media screen and (min-width : ${sizeMobile}px) {
    width           : 80rem;
  }
`;

const Text = styled.div`
  font-family : work-sans-medium;
  font-size   : 2rem;
  color       : ${({theme}) => theme.colors.text};
`;