import React, { useState, useEffect, useRef, RefAttributes } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SVG, { Props as SVGProps } from 'react-inlinesvg';

import { MapTypes, Legend, ColorsPanel, RootState, Interco } from '../../types';
import { sizeMobile, viewboxMap } from '../../utils/constants';
import { maps } from '../../utils/constants';
import { getColor } from '../../utils/helpers';
import useWindowSize from '../../hooks/useWindowSize';

export interface MapProps {
  data        : any;
  selectedMap : MapTypes;
  OverlayCard : React.FC<any>;
  legend?     : Legend[];
  colors      : ColorsPanel;
  isRegion?   : boolean;
  isDepartement? : boolean;
  isInterco?   : boolean;
}

const Map : React.FC<MapProps> = ({
  data={}, 
  selectedMap, 
  OverlayCard,
  legend,
  colors,
  isInterco, 
  isDepartement,
  isRegion
}) => {
  const size = useWindowSize();

  const mapsLoaded: any = useSelector((state: RootState) => state.maps);
  const {selected}: any = useSelector((state: RootState) => state.nomenclatures);
  const filters: any = useSelector((state: RootState) => state.filters);

  const [hover, setHover] = useState<boolean>(false);
  const [section, setDelegation] = useState<string>('');
  const [positionX, setPositionX] = useState<number>(0);
  const [positionY, setPositionY] = useState<number>(0);
  const [color, setColor] = useState<any>('disabled');
  const [mapsState, setMaps] = useState<any>([]);

  useEffect(() => {
    setMaps(maps[map])
    return () => setMaps([]);
  }, [selectedMap])

  useEffect(()=>{
    const value = data?.[section];
    const colorCard = legend ? getColor(legend, value) : 'disabled';
    setColor(colorCard);
  }, [section])
  
  const handleHover = (event : any, section : string) => {
    if(event.layerX){
      setPositionX(event.layerX-10);
      setPositionY(event.layerY-30);
    } else {
      setPositionX(event.nativeEvent.layerX-10);
      setPositionY(event.nativeEvent.layerY-30);
      
    }
    setHover(true);
    setDelegation(section);
  }

  const handleLeave = () => {
    setHover(false);
  }

  const Wrapper : any = useRef(null);
  const view = selectedMap as MapTypes;
  const map = selectedMap as MapTypes;

  const dep = `dep_${selectedMap.split('d')[1]}`;
  const int = `interco_${selectedMap.split('i')[1]}`;
  const reg = `reg_${selectedMap.slice(2,4)}`;

  let departements: any = useRef(null);
  let intercos: any = useRef(null);
  let regions: any = useRef(null);

  useEffect(() => {
    if(isRegion){
      const intercos = filters?.intercos?.intercosFiltered
      intercos?.forEach((section: Interco) => {
        const item = data?.[section.code_int];
        const colorIntensity: 'dark' | 'medium' | 'light' | 'lighter' | 'disabled'  = legend ? getColor(legend, item?.value) : 'disabled';
        regions.current?.getElementById((`interco_${section.code_int}`))?.setAttribute("fill", colors[colorIntensity])
        regions.current?.getElementById(`interco_${section.code_int}`)?.addEventListener("mousemove", (event : any) => handleHover(event, section.code_int))
        regions.current?.getElementById(`interco_${section.code_int}`)?.addEventListener("mouseleave", (event : any) => handleLeave())
      })
    } else if(isDepartement){
      const intercos = filters?.intercos?.intercosFiltered
      intercos?.forEach((section: any) => {
        const item = data?.[section.code_int];
        const colorIntensity: 'dark' | 'medium' | 'light' | 'lighter' | 'disabled'  = legend ? getColor(legend, item?.value) : 'disabled';
        const box = departements?.current?.getAttribute('viewBox').split(' ')[3]*1/600;
        departements?.current?.getElementById(`${dep}`)?.setAttribute('stroke-width', box)
        departements?.current?.getElementById(`interco_${section.code_int}`)?.setAttribute("fill", colors[colorIntensity])
        departements.current?.getElementById(`interco_${section.code_int}`)?.addEventListener("mousemove", (event : any) => handleHover(event, section.code_int))
        departements.current?.getElementById(`interco_${section.code_int}`)?.addEventListener("mouseleave", (event : any) => handleLeave())
        departements?.current?.setAttribute('display', 'block')
      })
    } else if (isInterco){
      const item = data?.[int];
      const colorIntensity: 'dark' | 'medium' | 'light' | 'lighter' | 'disabled'  = legend ? getColor(legend, item?.value) : 'disabled';
      intercos.current?.getElementById(int)?.setAttribute("fill", colors["medium"])
      // intercos.current?.getElementById(int)?.addEventListener("mousemove", (event : any) => handleHover(event, item.id))
    }
    
  }, [legend, regions.current, departements.current, filters?.intercos?.intercosFiltered])

  return (
    <>
    <Container ref={Wrapper}>
      {
        isRegion ?
        <SVG cacheRequests={false} src={mapsLoaded?.regions?.[reg]?.[`${reg}.svg`]} innerRef={regions} width={"50rem"} height={"40rem"} /> :
        isDepartement ? 
        <SVG display={'none'} id={`svg_${dep}`} src={mapsLoaded?.departements?.[dep]?.[`${dep}.svg`]} width={"50rem"} height={"40rem"} innerRef={departements} />
        : 
        isInterco ?
        <SVG src={mapsLoaded?.intercos?.[int]?.[`${int}.svg`]} width={"20rem"} height={"20rem"}  innerRef={intercos} /> 
        :
        <svg viewBox={viewboxMap[view]} version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMin slice" width={size.width && size.width <= sizeMobile ? "70rem" : view === "rd94" ?  "20rem" : view === "rd75" ? "30rem" : "50rem"} >
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="regions2018" transform="translate(2, 2)" fill-rule="nonzero" stroke="#FFF" stroke-width="0.2">
                  {
                    mapsState?.map((section: any) => {
                      const item = data?.[section.props.id.split('_')[1]];
                      const colorIntensity: 'dark' | 'medium' | 'light' | 'lighter' | 'disabled'  = legend ? getColor(legend, item?.value) : 'disabled';
                      return (
                        React.cloneElement(section, {
                          onMouseMove : (event : any) => handleHover(event, section.props.id),
                          onMouseLeave : () => handleLeave(),
                          fill : colors[colorIntensity],
                          key : section.props.id
                        })
                      )}
                    )
                  }
                </g>
            </g>
        </svg>
      }
      { 
        hover && (typeof data?.[section]?.value === 'number' || typeof data?.[section?.split('_')?.[1]]?.value === 'number')/* && !isNaN(data?.[section]) */  &&
        <ContainerCard
          positionX={positionX}
          positionY={positionY}
          // onMouseEnter={()=>setHover(true)}
          // onMouseLeave={()=>setHover(false)}
        >
          <OverlayCard section={section} tooltipData={isRegion || isDepartement || isInterco ? data[section] : data?.[section?.split('_')?.[1]]} color={colors.medium} />
        </ContainerCard>
      }
    </Container>
      </>

  )
}

export default Map;

interface ContainerCardProps {
  positionX : number;
  positionY : number;
}

const Container = styled.div`
  width     : 100%; 
  height    : 100%;
  max-width : 900px;
  display   : flex;
  justify-content: center;
`;

const ContainerCard = styled.div<ContainerCardProps>`
  position : absolute;
  left : ${({positionX}) => `${positionX}px`};   
  top  : ${({positionY}) => `${positionY}px`};
`;
