import defaultState from '../defaultState';

const filtersReducer = (state = defaultState.filters, action : Action) => {
  switch (action.type) {
    case '@@RESET':
    case 'LOGIN_FAILED':
      return defaultState.filters;
        
    case 'UPDATE_FILTERS':
      return {
        ...state,
        [action.filter] : {
          ...state[action.filter],
          selected : action.value
        }
    };

    case 'FILTER_LOADED' : 
      return {
        ...state,
        [action.filter] : {
          ...state[action.filter],
          data : action.data ? [...action.data] : []
        }
      }

    case 'DEPARTEMENTS_FILTERED' : 
      return {
        ...state,
        departements : {
          ...state.departements,
          departementsFiltered : action.data ? [...action.data] : []
        }
      }

    case 'INTERCOS_FILTERED' : 
      return {
        ...state,
        intercos : {
          ...state.intercos,
          intercosFiltered : action.data ? [...action.data] : []
        }
      }

    case 'DEFAULT_FILTER' : 
      return {
        ...state,
        [action.filter] : {
          ...state[action.filter],
          defaultValue : action.value
        }
      }

    case 'RESET_FILTER' : 
      return {
        ...state,
        [action.filter] : {
          ...state[action.filter],
          selected : ''
        }
      }

    default:
      return state;
  }
};

export default filtersReducer;

interface Action {
  type : string;
  filter : Filters;
  value : [] | string;
  data : [];
}

type Filters = 'years'