import React from 'react';
import styled from 'styled-components';

import { sizeMobile } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { openPopup } from '../../store/actions/popup';
import { t } from '../../i18n';

const Footer : React.FC<{}> = () => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Methodo onClick={() => dispatch(openPopup('methodo'))}>{t('Footer.methodo')}</Methodo>
      <VerticalBar />
      <Components onClick={() => dispatch(openPopup('mentions'))}>{t('Footer.mentions')}</Components>
      <VerticalBar />
      <Contact href='mailto:contact@oir.fr' >Contact</Contact>
    </Container>
  )
}

export default Footer;

const Container = styled.div`
  height          : 66px;
  width           : 100%;
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  align-items     : center;
  margin-top      : 21px;
  background-color: ${({theme}) => theme.colors.neutral};
  margin-bottom   : 11rem;
  
  @media screen and (min-width : ${sizeMobile}px) {
    margin-top      : 50px;
    margin-bottom   : 0px;
  }
`;

const Methodo = styled.div`
  color          : ${({theme}) => theme.colors.text};
  font           : ${({theme}) => theme.fonts.footer};
  cursor         : pointer;
`;

const VerticalBar = styled.div`
  height : 50%;
  background-color: ${({theme}) => theme.colors.text};
  margin : 0 10rem;

  @media screen and (max-width : ${sizeMobile}px) {
    margin : 0 5rem;
  }
`;

const Components = styled.div`
  color          : ${({theme}) => theme.colors.text};
  font           : ${({theme}) => theme.fonts.footer};
  cursor         : pointer;
`;

const Contact = styled.a`
  color          : ${({theme}) => theme.colors.text};
  font           : ${({theme}) => theme.fonts.footer};
  cursor         : pointer;
  text-decoration: none;
`;