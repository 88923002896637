import React from 'react'
import styled from 'styled-components';

import { Logo } from '../../assets/svg-images/logo';
import { t } from '../../i18n';
import { sizeMobile } from '../../utils/constants';

const LogoHeader: React.FC<{}> = () => {
  return (
    <Container>
      <Logo height={32} width={32} />
      <Text>{t('Header.logo')}</Text>
    </Container>
  )
}

export default LogoHeader;

const Container = styled.div`
  padding-left    : 2.2rem;
  padding-top     : 1rem;
  display         : flex;
  justify-content : center;
  flex-direction  : column;

  @media screen and (max-width : ${sizeMobile}px) {
    padding-left    : 2.2rem;
    padding-top     : 0px;
  }
`;

const Text = styled.div`
  color       : ${({theme})=> theme.colors.text};
  font-family : work-sans-medium;
  font-size   : ${({theme})=> theme.fonts.medium};
  @media screen and (max-width : ${sizeMobile}px) {
    font-family : work-sans-regular;
    font-size : 14px;
  }
`;