import { call, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import { apiCall } from './axiosApiCallWrapper';
import { filterLoaded, selectFilter, resetFilter, departementsFiltered, intercosFiltered, defaultFilter } from '../actions/filters';
import { loadingEnd, loadingBegin } from '../actions/loading';
import { datasetsLoaded, selectDataset } from '../actions/datasets';
import { Dataset, GenericData, RootState } from '../../types';
import { nomenclaruresLoaded, selectNomenclature } from '../actions/nomenclature';
import { updateParamsFiles } from '../actions/files';
import { updateComparisonLabel, updateCurrentLabel, updateTotal } from '../actions/global';
import { getTerritory } from '../../utils/helpers';
import _ from 'lodash';

function* generateParamsFile(){
  const { years, quarterly, regions, departements, intercos, comparison, groupsAgeCttNiv1, groupsAgeCttNiv2, groupsAgeSector } = yield select((state: RootState) => state.filters);
  const { selected } = yield select((state: RootState) => state.datasets);
  const importId = selected?.importId || null;
  const datasetPeriod = selected?.period;
  let territory = '';
  let period = '';
  let sectorAge = '';
  let cttNiv1Age = '';
  let cttNiv2Age = '';
  const yearSelected = years?.data.find((d:any) => d?.value === years.selected/*  || d?.value === years.defaultValue */);
  if(quarterly.selected || quarterly.defaultValue){
    period = (yearSelected?.label || years.defaultValue)+(quarterly.selected || quarterly.defaultValue);
  } else {
    period = yearSelected?.label|| years.defaultValue;
  }
  if(intercos.selected){
    territory = `i${intercos.selected}`;
    const {label} = intercos?.data?.find((d: any) => d.value === intercos.selected);
    yield put(updateCurrentLabel(label));
  } else if (departements.selected){
    territory = `d${departements.selected}`;
    const {label} = departements?.data?.find((d: any) => d.value === departements.selected);
    yield put(updateCurrentLabel(label));
  } else if (regions.selected) {
    territory = `r${regions.selected}`;
    const {label} = regions?.data?.find((d: any) => d.value === regions.selected);
    yield put(updateCurrentLabel(label));
  } else {
    territory = 'n0';
    yield put(updateCurrentLabel("National"));
  }
  if(groupsAgeCttNiv1?.selected){
    cttNiv1Age = `GA${groupsAgeCttNiv1.selected}`
  } else {
    cttNiv1Age = 'GA-1';
  }

  if(groupsAgeCttNiv2?.selected){
    cttNiv2Age = `GA${groupsAgeCttNiv2.selected}`
  } else {
    cttNiv2Age = 'GA-1';
  }

  if(groupsAgeSector?.selected){
    sectorAge = `GA${groupsAgeSector.selected}`
  } else {
    sectorAge = 'GA-1';
  }
  if(comparison.selected){
    const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
    const comparisonName = getTerritory(comparisonItem);    
    const comparisonCttFileName = period + '.' + 'ctt' + '.' + `sumevolution` + '.' + comparisonName + '.json';
    const comparisonCdiiFileName = period + '.' + 'cdii' + '.' + `sumevolution` + '.' + comparisonName + '.json';

    try {
      const {data: cttComparison} = yield call(apiCall, 'GET', `${window._env_.ACCOUNTS_API_URL}/data/${comparisonCttFileName}?importId=${importId}`);
      console.log("cttComparison", cttComparison);
      const {value: cttValue} = cttComparison.find((d: GenericData) => d.key === datasetPeriod);
      yield put(updateTotal('comparisonTotalCtt', cttValue));
    }
    catch(err){
      yield put(updateTotal('comparisonTotalCtt', 0));
    }

    try {
      const {data: cdiiComparison} = yield call(apiCall, 'GET', `${window._env_.ACCOUNTS_API_URL}/data/${comparisonCdiiFileName}?importId=${importId}`);
      const {value: cdiiValue} = cdiiComparison.find((d: GenericData) => d.key === datasetPeriod);
      yield put(updateTotal('comparisonTotalCdii', cdiiValue));
    }
    catch(err){
      yield put(updateTotal('comparisonTotalCdii', 0));
    }
    
    const {label} = comparison?.data?.find((d: any) => d.value === comparison.selected);
    yield put(updateComparisonLabel(label));
  } else {
    yield put(updateComparisonLabel(""));
    // yield put(updateCurrentLabel(""));
  }
  try {
    const cttFileName = period + '.' + 'ctt' + '.' + `sumevolution` + '.' + territory + '.json';
    const {data: ctt} = yield call(apiCall, 'GET', `${window._env_.ACCOUNTS_API_URL}/data/${cttFileName}?importId=${importId}`);
    const {value: cttValue} = ctt.find((d: GenericData) => d.key === datasetPeriod);
    yield put(updateTotal('totalCtt', cttValue));
  }
  catch(error){
    yield put(updateTotal('totalCtt', 0));
  }

  try {
    if(territory === "n0"){
      const cttHoursFileName = period + '.' + `ctt.totalhours.n.json`;
      const {data: ctt} = yield call(apiCall, 'GET', `${window._env_.ACCOUNTS_API_URL}/data/${cttHoursFileName}?importId=${importId}`);
      const {value: cttValue} = ctt.find((d: GenericData) => d.key === "total");
      yield put(updateTotal('totalHoursCtt', cttValue));
    } else if(territory.slice(0,1) === "r") {
      const cttHoursFileName = period + '.' + `ctt.hours.rd${territory.slice(1,3)}.json`;
      const {total} = yield call(apiCall, 'GET', `${window._env_.ACCOUNTS_API_URL}/data/${cttHoursFileName}?importId=${importId}`);
      yield put(updateTotal('totalHoursCtt', total));
    }
  }
  catch(error){
    yield put(updateTotal('totalHoursCtt', 0));
  }

  try {
    const cdiiFileName = period + '.' + 'cdii' + '.' + `sumevolution` + '.' + territory + '.json';
    const {data : cdii} = yield call(apiCall, 'GET', `${window._env_.ACCOUNTS_API_URL}/data/${cdiiFileName}?importId=${importId}`);
    const {value: cdiiValue} = cdii.find((d: GenericData) => d.key === datasetPeriod);
    yield put(updateTotal('totalCdii', cdiiValue));
  }
  catch(error){
    yield put(updateTotal('totalCdii', 0));
  }
  yield put(updateParamsFiles({period, territory, age : {ctt : {niv1 : cttNiv1Age, niv2 : cttNiv2Age}, sector : sectorAge}}));
}

function* filesSaga() {
  yield takeEvery('GET_PARAMS_FILES',  generateParamsFile);
}

export default filesSaga;