import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import ArrowDownPng from '../assets/images/table_arrow_down.png';
import { theme } from '../../assets/theme';
/**
 * ColumnSortLabel
 * @param {string} label
 * @param {number} total on the ritgh of label
 * @param {function} onAscendingSort behavior when click on above arrow
 * @param {function} onDescendingSort behavior when click on below arrow
 */

const ColumnSortLabel: React.FC<ColumnSortLabelProps> = ({
  label,
  total=0,
  onAscendingSort,
  onDescendingSort,
  type,
  sortType,
  ...props
}) => {
  const [sortDirecion, setSortDirection] = useState<SortDirection>(null);

  const handleClickAscendingSort = () => {
    setSortDirection('up');
    onAscendingSort();
  }

  const handleClickDescendingSort = () => {
    setSortDirection('down');
    onDescendingSort();
  }
  return (  
    <Container>
      <ContainerButton>
        {
          sortType !== type ?
          <Button justifyContent='flex-end' onClick={handleClickAscendingSort}>
            <Icon icon='arrow-up' width={10} height={7} color={theme.colors.text}/>
          </Button> : 
          sortDirecion === 'down' || !sortDirecion ?
          <Button justifyContent='flex-end' onClick={handleClickAscendingSort}>
            <Icon icon='arrow-up' width={10} height={7} color={theme.colors.text}/>
          </Button> :        
          <Empty></Empty>
        }
        {
          sortType !== type ? 
          <Button justifyContent='flex-start' onClick={handleClickDescendingSort}>
            <Icon icon='arrow-down' width={10} height={7} color={theme.colors.text}/>
          </Button> :
          sortDirecion === 'up' || !sortDirecion ? 
          <Button justifyContent='flex-start' onClick={handleClickDescendingSort}>
            <Icon icon='arrow-down' width={10} height={7} color={theme.colors.text}/>
          </Button> :     
          <Empty></Empty>
        }
      </ContainerButton>
      <Label>{label}</Label>
      {total > 0 && <Total>({total})</Total>}
    </Container>
  );
};

export default ColumnSortLabel;

export interface ColumnSortLabelProps {
  label: string;
  total? : number;
  onAscendingSort: () => void;
  onDescendingSort: () => void;
  type : string;
  sortType : string;
}

type SortDirection = 'up' | 'down' | null;

interface ButtonProps {
  justifyContent : string;
}

const Container = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  height         : 40px;
`;

const ContainerButton = styled.div`
  display        : flex;
  flex-direction : column;
`;

const Button = styled.button<ButtonProps>`
  border           : 0;
  background-color : transparent;
  display          : flex;
  flex-direction   : column;
  justify-content  : ${({justifyContent}) => justifyContent};
  cursor           : pointer;
  height           : 20px;
  outline-color    : ${({theme})=> theme.colors.primary};
`;

const Empty = styled.div`
  height           : 10px;
`;

const IconUp = styled.img`
  width         : 9px;
  height        : 6px;
  margin-bottom : 1px;
  filter        : brightness(0.4);
`;

const IconDown = styled.img`
  width      : 9px;
  height     : 6px;
  margin-top : 1px;
  filter     : brightness(0.4);
`;

const Label = styled.div`
  margin-left : 3px;
  font-family : work-sans-regular;
  color       : ${({theme}) => theme.colors.text};
`;

const Total = styled.div`
  margin-left : 5px;
  font-family : work-sans-regular;
  color       : ${({theme}) => theme.colors.lightText};
`;


