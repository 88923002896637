import { IconProps } from "../../types";

export const IconActivity : React.FC<IconProps> = ({width=32.952, height=27, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 32.952 27">
  <g id="Group_8" data-name="Group 8" transform="translate(1 0.241)">
    <path id="Stroke_13" data-name="Stroke 13" d="M0,20.238H30.952V0H0Z" transform="translate(0 5.521)" fill="none" stroke="#d54c74" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Stroke_15" data-name="Stroke 15" d="M0,4.762V2.105A2.119,2.119,0,0,1,2.132,0h6.45a2.119,2.119,0,0,1,2.132,2.105V4.762" transform="translate(10.714 0.759)" fill="none" stroke="#d54c74" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Stroke_16" data-name="Stroke 16" d="M10.714.5H0" transform="translate(20.238 15.14)" fill="none" stroke="#d54c74" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Stroke_17" data-name="Stroke 17" d="M10.714.5H0" transform="translate(0 15.14)" fill="none" stroke="#d54c74" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Fill_18" data-name="Fill 18" d="M8.661,3.571H2.053A1.934,1.934,0,0,1,0,1.786,1.934,1.934,0,0,1,2.053,0H8.661a1.934,1.934,0,0,1,2.053,1.786A1.934,1.934,0,0,1,8.661,3.571" transform="translate(10.714 13.854)" fill="none" stroke="rgba(0,0,0,0)" strokeWidth="1"/>
    <path id="Stroke_19" data-name="Stroke 19" d="M8.661,3.571H2.053A1.934,1.934,0,0,1,0,1.786,1.934,1.934,0,0,1,2.053,0H8.661a1.934,1.934,0,0,1,2.053,1.786A1.934,1.934,0,0,1,8.661,3.571Z" transform="translate(10.714 13.854)" fill="none" stroke="#d54c74" strokeMiterlimit="10" strokeWidth="2"/>
  </g>
</svg>
)