import React from 'react';
import styled from 'styled-components';
import Filters from './Filters';
import pack from '../../../package.json';
interface MenuProps {

}

const Menu: React.FC<MenuProps> = () => {
  return (
    <Container> 
      <ContainerButton>
        <Filters />
      </ContainerButton>
      <Version>{pack.version}</Version>
    </Container>
  )
}

export default Menu;

const Container = styled.div`
  overflow-y       : auto;
  height           : 0px;
  flex             : 1 1 auto;
  width            : 100%;
  display          : flex;
  flex-direction   : column;
  background-color : ${({theme})=> theme.colors.menu};
`;

const ContainerButton = styled.div`
  display        : flex;
  flex-direction : column;
  margin-top     : 10px;
`;

const Version = styled.div`
  text-align  : center;
  font-size   : 1.5rem;
  color       : white;
  font-family : work-sans-regular;
  position    : absolute;
  bottom      : 1rem;
  width       : 24rem;
`;