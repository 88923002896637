import React from 'react';
import styled from 'styled-components';
import { t } from '../../i18n';
import { sizeMobile, statisticSecretValue } from '../../utils/constants';
import pxToRem from '../../utils/dimension';
import CompareBarIndicator from '../molecules/CompareBarIndicator';
import RightComparisonBarIndicator from './RightComparisonBarIndicator';

export interface BarListProps {
  label: string;
  description: string;
  color: string;
  value: number | string;
  comparingValue: number | undefined;
  total: number;
  isComparing : boolean;
  totalComparingValue?: number;
}

interface PercentageProps {
  color : string;
}

const BarList: React.FC<BarListProps> = ({color, description, label, value, comparingValue=0, total, isComparing=false, totalComparingValue=0}) => {
  const valueNumber = value as number;
  return (
    <Container id={description} >
      <Left color={color} >
        <Label color={color} >{label}</Label>
        <Description>{description}</Description>
      </Left>
      {
        value === statisticSecretValue ?
        <StatisticSecret>
          <SecretText>{t('StatisticSecret.lessThan3')}</SecretText>
        </StatisticSecret> :
        <IndicatorContainer>
          <Middle>
            <RightComparisonBarIndicator 
              color={color}
              percentage={Math.floor(valueNumber/total*1000)/10}
              comparisonPercentage={comparingValue !== undefined ? Math.floor((comparingValue/totalComparingValue)*1000)/10 : 0}
              width={150}
              height={16}
              isComparing={isComparing}
              showPercentage={false}
            />
          </Middle>
          <Right>
            <div style={{display : 'flex', flexDirection : 'column'}}>
              <Percentage color={color} >
                {Math.floor(valueNumber / total * 1000) / 10}%
              </Percentage>
              {isComparing && <Percentage color={color} style={{fontFamily : 'work-sans-medium'}} >{ comparingValue!== undefined ? `${Math.floor(comparingValue / totalComparingValue * 1000) / 10}%` : 'Pas de valeur'}</Percentage>}
            </div>

            <div style={{display : 'flex', flexDirection : 'column'}} >
              <Value>
                {value.toLocaleString()}
              </Value>
              {isComparing && <Value>{comparingValue!== undefined ? comparingValue.toLocaleString() : 'Pas de valeur'}</Value>}
            </div>
          </Right>
        </IndicatorContainer>
      }
    </Container>
  )
}

export default BarList;

const Container = styled.div`
  height           : 7rem;
  width            : auto;
  background-color : ${({theme}) => theme.colors.barList};
  border-radius    : 0.5rem;
  margin-bottom    : 1.9rem;
  display          : flex;
  flex-direction   : row;
  padding-left      : 2rem;
  justify-content : space-between;
  @media screen and (max-width : ${sizeMobile}px) {
    height : 10rem;
    flex-direction : column;
    justify-content : flex-start;
  }
`;

const IndicatorContainer = styled.div`
  display : flex;
  flex : 1;
  flex-direction : row;
`;

const Left = styled.div`
  border-right : 1px solid ${({color}) => `${color}`};
  flex : 1;
  overflow      : hidden;
  @media screen and (max-width : ${sizeMobile}px) {
    border-right : none;
  } 
`;

const Right = styled.div`
  flex        : 1;
  display     : flex;
  height      : 100%;
  align-items : center;
  justify-content : space-between;
  padding-left : 2rem;
  padding-right : ${pxToRem(33.3)}rem;
`;

const Middle = styled.div`
  flex : 1;
  display : flex;
  height : 100%;
  align-items : center;
  justify-content : flex-start;
  padding-left : 2rem;
`

const Label = styled.div`
  font-family   : work-sans-bold;
  font-size     : 1.2rem;
  color         : ${({color}) => color};
  margin-top    : 1.5rem;
  white-space   : nowrap;
  text-overflow : ellipsis;
  overflow      : hidden;
  max-width      : 30rem;
`;

const Description = styled.div`
  font-family : work-sans-regular;
  font-size   : 1.4rem;
  color       : ${({theme}) => theme.colors.text};
  margin-top  : 0.5rem;

  @media screen and (max-width : ${sizeMobile}px) {
    font-size : 1.2rem;
  }
`;

const Percentage = styled.div<PercentageProps>`
  font-size : ${pxToRem(16)}rem;
  font-family : work-sans-bold;
  color : ${({color}) => color};
`;

const Value = styled.div`
  font-size : ${pxToRem(16)}rem;
  font-family : work-sans-medium;
  color : #A4A2A0;
  @media screen and (max-width : ${sizeMobile}px) {
    display : none;
  }
`;

const StatisticSecret = styled.div`
  flex    : 1;
`;

const SecretText = styled.div`
  font-family  : work-sans-regular;
  font-size    : 1.3rem;
  line-height  : 1.5rem;
  padding      : 1rem;
`;

