import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { History }  from 'history';

import filtersReducer from './filters';
import loadingReducer from './loading';
import authReducer    from './auth';
import datasetsReducer from './datasets'; 
import nomenclatureReducer from './nomenclature';
import popupReducer from './popup';
import filesReducer from './files';
import globalReducer from './global';
import mapsReducer from './maps';

const createRootReducer = (history : History) => combineReducers({
    router : connectRouter(history),
    // rest of your reducers,
    login : authReducer,
    filters : filtersReducer,
    loading : loadingReducer,
    datasets : datasetsReducer,
    nomenclatures : nomenclatureReducer,
    popup : popupReducer,
    paramsFiles : filesReducer,
    global : globalReducer,
    maps: mapsReducer

});

export default createRootReducer;