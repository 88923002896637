import styled from 'styled-components';
import ToggleButton from './ToggleButton';

const StyledToggleButton = styled(ToggleButton)`
  min-width     : 38px;
  height        : 38px;
  margin-left   : 7px;
  border-radius : 5px;
  ::selection {
  background-color:transparent;
  }
  outline-color    : ${({theme}) => theme.colors.primary};
  outline-width    : 1px;
  border            : ${({selected, theme, color}) => selected ? `1px solid ${color}` : `1px solid ${theme.colors.border}`}
`;

export default StyledToggleButton;