import React from 'react';
import styled from 'styled-components';
import { IconLabel } from '../../types';
import pxToRem from '../../utils/dimension';
import Icon from './Icon';

const GenericMobileButton: React.FC<GenericMobileButtonProps> = ({ color, iconName, iconColor, onClick, iconWidth }) => {

    return (
        <ButtonContainer color={color} onClick={onClick}>
            <Icon icon={iconName} color={iconColor} width={iconWidth} />
        </ButtonContainer>
    )
}

export default GenericMobileButton;

export interface GenericMobileButtonProps {
    color: string;
    iconColor: string;
    iconName: IconLabel;
    onClick: () => void;
    iconWidth: number;
}

interface ButtonContainerProps {
    color: string;
}

const ButtonContainer = styled.button<ButtonContainerProps>`
display : flex;
align-items : center;
justify-content : center;
height : ${pxToRem(60)}rem;
width : ${pxToRem(60)}rem;
${({ color }) => color === 'white' ?
`
border : 1px solid #D9D9D9;
`
:
`
border : none;
`
}
border-radius : 100%;
background-color : ${({ color }) => color};
transition-property : opacity, transform;
transition-duration : 0.1s, 0.1s;
&:active{
    opacity : 0.5;
}
`;