import axios, { Method, AxiosResponse } from 'axios'

/**
 * Create an Axios Client with defaults
 */
export const client = axios.create({
    // baseURL: 'http://localhost:8101',
    // auth: { Authorization: 'Bearer ' + { getToken } }
});

/**
* Request Wrapper with default success/error actions
*/
export const apiCall : any = function (method : Method, route: string, body = null, token = null) {
    const onSuccess = function (response : AxiosResponse) {
        // console.debug('Request Successful!', response);
        return response.data;
    }

    const onError = function (error : any) {
        // console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            // console.error('Status:', error.response.status);
            // console.error('Data:', error.response.data);
            // console.error('Headers:', error.response.headers);

        } else {
            // Something else happened while setting up the request
            // triggered the error
            // console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    }

    return client({
        method,
        url: route,
        data: body
    }).then(onSuccess)
        .catch(onError);
}