import { IconProps } from "../../types";

export const IconHours : React.FC<IconProps> = ({width=34.904, height=26.012, color='00b3d4', ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g id="noun-hours-4298401" transform="translate(-6 -6)">
      <path id="Tracé_467" data-name="Tracé 467" d="M18,6a1,1,0,0,0,0,2A10,10,0,1,1,8,18a1,1,0,0,0-2,0A12,12,0,1,0,18,6Z" fill={color}/>
      <path id="Tracé_468" data-name="Tracé 468" d="M11,19h7a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6H11a1,1,0,0,0,0,2Z" fill={color}/>
    </g>
  </svg>

)