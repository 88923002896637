import React, { useEffect, Suspense, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Loader } from '../components/atoms/Loader';
import GenericLineChart from '../components/atoms/GenericLineChart';
import { GenericCard } from '../components/organisms/GenericCard';
import GenericPage from '../components/templates/GenericPage';
import ListBarIndicators from '../components/graphs/ListBarIndicators';
import CompareTwoQuarters from '../components/graphs/CompareTwoQuarters';
import ContainerMap from '../components/organisms/ContainerMap';

import { theme } from '../assets/theme';
import { i18next, t } from '../i18n';
import { CttLoading, GenericData, GroupAgeData, GroupSexeData, ListBarData, MapCut, ResponseGroupAge, ResponseGroupHours, RootState, MapTypes } from '../types/index';
import { CTT_FILES, labelGroupAges, labelGroupHours, sizeMobile, groupsAge, labelSource } from '../utils/constants';
import { client } from '../store/sagas/axiosApiCallWrapper';
import { IconDemographic } from '../assets/svg-images/IconDemographics';
import { IconHours } from '../assets/svg-images/IconHours';
import LegendGraph from '../components/atoms/LegendGraph';
import { getTerritory } from '../utils/helpers';
import { Trans } from 'react-i18next';


const CttDemographicsData: React.FC = () => {
  const {period, territory} = useSelector((state: RootState) => state.paramsFiles);
  const {comparison, regions, departements, intercos} = useSelector((state: RootState) => state.filters);
  const {selected} = useSelector((state: RootState) => state.datasets);
  const {selected: nm}: {selected : {[key: string] : any[]}} = useSelector((state: RootState) => state.nomenclatures);
  const importId = selected?.importId || null;
  const {totalCtt, totalHoursCtt, comparisonTotalCtt, currentLabel} = useSelector((state: RootState) => state.global);
  
  const [sumevolutionData, setSumevolutionData] = useState<any[]>([]);
  const [sumData, setSumData] = useState<any[]>([]);
  const [hoursData, setHoursData] = useState<any[]>([]);
  const [grouphoursData, setGrouphoursData] = useState<ListBarData[]>([]);
  const [groupsexeData, setGroupsexeData] = useState<GroupSexeData | null>(null);
  const [groupageData, setGroupageData] = useState<ListBarData[]>([]);
  const [loadingEvolution, setLoadingEvolution] = useState<boolean>(false);
  const [loadingGroupSexe, setLoadingGroupSexe] = useState<boolean>(false);
  const [loadingGroupAge, setLoadingGroupAge] = useState<boolean>(false);
  const [loadingGroupHours, setLoadingGroupHours] = useState<boolean>(false);
  const [loadingSum, setLoadingSum] = useState<boolean>(false);
  const [loadingHours, setLoadingHours] = useState<boolean>(false);
  const [sumCut, setSumCut] = useState<MapTypes>('');
  const [hoursCut, setHoursCut] = useState<MapTypes>('');
  const [comparisonLabel, setComparisonLabel] = useState('');
  const [territoryLabel, setTerritoryLabel] = useState('');

  const territoryType = (cut: string) => {
    if(cut === 'nr'){return 'regions'}
    if(cut === 'nd'){return 'departements'}
    if(cut.slice(0,2) === 'rd'){return 'departements'}
    if(cut.slice(0,2) === 'ri'){return 'intercos'}
    if(cut.slice(0,1) === 'd'){return 'intercos'}
    return 'regions'
  }

  useEffect(() => {
    setLoadingGroupAge(true);
    setLoadingGroupSexe(true);
    setLoadingEvolution(true);
    setLoadingGroupHours(true);
    if(period){
      CTT_FILES.forEach(async (fileType: string) => {
          let fileName = '';
          if(fileType === 'sum'){
            fileName = period + '.' + 'ctt' + '.' + `${fileType}` + '.' + sumCut + '.json';
          } else {
            fileName = period + '.' + 'ctt' + '.' + `${fileType}` + '.' + territory + '.json';
          }
          let comparisonData: any;
          if(comparison.selected){
            const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
            const comparisonName = getTerritory(comparisonItem); 
            const comparisonFileName = period + '.' + 'ctt' + '.' + `${fileType}` + '.' + comparisonName + '.json';
            try {
              const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${comparisonFileName}?importId=${importId}`);
              comparisonData = data;
            } 
            catch(err){
              comparisonData = [];
            }
          }
          try {
            const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`)
            // SUM_EVOLUTION
            if(fileType === 'sumevolution'){
              const dataMaped = data.map((d: GenericData, index: number) => (
                { 
                  name : d.key.slice(4,5) ? `${d.key.slice(0,4)}-T${d.key.slice(4,5)}` : d.key, 
                  value : d.value, 
                  comparisonValue : comparisonData?.[index]?.value || 0
                }
              ))
              const dataSorted = _.sortBy(dataMaped, (o) => o.name)
              setSumevolutionData(dataSorted);
              setLoadingEvolution(false);
            }
            // GROUP_HOURS
            else if (fileType === 'grouphours') { 
              const comparisonTotal = _.sumBy(comparisonData?.map((d: ResponseGroupHours) => d.value)); 
              const sortedData = _.sortBy(
                data.map(
                  (d: ResponseGroupHours, index: number) => (
                    { 
                      description : labelGroupHours[d.key], 
                      percentage : Math.floor(d.value/totalCtt*10000)/100, 
                      comparisonPercentage : Math.floor(comparisonData?.find((dc: ResponseGroupHours) => dc.key === d.key)?.value/comparisonTotal*10000)/100, 
                      comparisonValue : comparisonData?.[index]?.value, 
                      value : d.value,
                      key : d.key
                    }
                  )
                ), [(d) => parseFloat(d.key)]
              )
              setGrouphoursData(sortedData);
              setLoadingGroupHours(false);
            } 
            // GROUP_SEXE
            else if(fileType === 'groupsexe'){
              let groupSexe: GroupSexeData = {};
              data.forEach((d: GenericData) => {
                if(d.key === '0'){
                  groupSexe['women'] = d.value;
                } else if(d.key === '1'){
                  groupSexe['men'] = d.value;
                } else if(d.key === '3'){
                  groupSexe['nr'] = d.value;
                }
              });
              comparisonData?.forEach((d: GenericData) => {
                if(d.key === '0'){
                  groupSexe['comparisonwomen'] = d.value;
                } else if(d.key === '1'){
                  groupSexe['comparisonmen'] = d.value;
                } else if(d.key === '3'){
                  groupSexe['comparisonnr'] = d.value;
                }
              });
              setGroupsexeData(groupSexe);
              setLoadingGroupSexe(false);
            } 
            // GROUP_AGE
            else if(fileType === 'groupage'){
              const comparisonTotal = _.sumBy(comparisonData?.map((d: ResponseGroupHours) => d.value));
              const sortedData = _.sortBy(
                data.map(
                  (d: ResponseGroupAge, index: number) => (
                    { 
                      description : labelGroupAges[d.key], 
                      percentage : Math.floor(d.value/totalCtt*10000)/100, 
                      comparisonPercentage : Math.floor(comparisonData?.find((dc: ResponseGroupAge) => dc.key === d.key)?.value/comparisonTotal*10000)/100,
                      comparisonValue : comparisonData?.[index]?.value, 
                      value : d.value,
                      key: d.key
                    }
                  )
                ), [(d) => parseFloat(d.key)]
              ).reverse()

              setGroupageData(sortedData);
              setLoadingGroupAge(false);
            }
          } catch(error){
            if(fileType === 'sumevolution'){
              setSumevolutionData([]);
              setLoadingEvolution(false);
            } else if (fileType === 'grouphours') {
              setGrouphoursData([]);
              setLoadingGroupHours(false);
            } else if(fileType === 'groupsexe'){
              setGroupsexeData({});
              setLoadingGroupSexe(false);
            } else if(fileType === 'groupage'){
              setGroupageData([]);
              setLoadingGroupAge(false);
            } 
          }
        })
      }
  }, [period, territory, comparison]);

  useEffect(() => {
    setLoadingSum(true);
    const loadData = async() => {
      let fileName: string;
      if(sumCut === 'd75') {
        fileName = period + '.' + 'ctt' + '.' + `sum` + '.' + 'rd11' + '.json';
      } else {
        fileName = period + '.' + 'ctt' + '.' + `sum` + '.' + sumCut + '.json';
      }
      let comparisonData: any;
      try {
        const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`)
        const code = territoryType(sumCut).slice(0,3);
        let items;
        if(sumCut === 'd75'){
          items = [data.find((d: GenericData) => d.key === '75')];
        } else {
          items = data;
        }
        setSumData(items
          .filter((d: GenericData) => d.key !== "99")
          .map((d: GenericData) => {
          const terr: any = nm[territoryType(sumCut)].find((t) =>{ 
            return t?.[`code_${code}`] === d.key
          });
          return ({ 
            name : sumCut === 'd75' ? '200054781' : d.key , 
            label : sumCut === 'd75' ? 'Paris' : terr?.libelle, 
            value : d.value, 
            comparisonValue : _.sumBy(comparisonData, 'value')
          })
        }));
        setLoadingSum(false);
      } 
      catch (e) {
        setLoadingSum(false);
        setSumData([]);
      }
    }
    if(period && sumCut){
      loadData();
    }
  }, [sumCut, period, comparison]);

  useEffect(() => {
    const loadData = async() => {
      setLoadingHours(true);
      let fileName: string;
      if(sumCut === 'd75') {
        fileName = period + '.' + 'ctt' + '.' + `hours` + '.' + 'rd11' + '.json';
      } else {
        fileName = period + '.' + 'ctt' + '.' + `hours` + '.' + hoursCut + '.json';
      }
      let comparisonData: any;
      if(comparison.selected){
        let comparisonName;
        const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
        if(comparisonItem?.code_reg && !comparisonItem?.code_dep){
          comparisonName = `hours` + '.' + `rd${comparisonItem.value}`;
        } else if(comparisonItem?.code_dep) {
          comparisonName = `hours` + '.' + `d${comparisonItem?.value}`;
        } else if(comparisonItem?.code_int) {
          comparisonName = `hours` + '.' + `d${comparisonItem?.value}`;
        } else {
          comparisonName = `totalhours.n`;
        }
        const comparisonFileName = period + '.' + 'ctt' + '.' + comparisonName + '.json';
        try {
          const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${comparisonFileName}?importId=${importId}`);
          comparisonData = data;
        }
        catch(err) {
          comparisonData = [];
        }
      }
      try {
        const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
        const code = territoryType(hoursCut).slice(0,3);
        let items;
        if(hoursCut === 'd75'){
          items = [data.find((d: GenericData) => d.key === '75')];
        } else {
          items = data;
        }
        setHoursData(items.map((d: GenericData) => {
            const terr: any = nm[territoryType(hoursCut)].find((t) =>{ 
              return t?.[`code_${code}`] === d.key
            });
            return ({ 
              name : hoursCut === 'd75' ? '200054781' : d.key, 
              value : d.value, 
              label : hoursCut === 'd75' ? 'Paris' : terr?.libelle, 
              comparisonValue : _.sumBy(comparisonData, 'value')
            })
          })
        );
        setLoadingHours(false);
      } 
      catch (e) {
        setLoadingHours(false);
        setHoursData([]);
      }
    }
    if(period && hoursCut){
      loadData();
    }
  }, [hoursCut, period, comparison])

  useEffect(() => {
    if(territory){
      if(territory.includes('r')){
        const region = `rd${territory.split('r')[1]}` as MapTypes
        setHoursCut(region);
        setSumCut(region);
        const terr = regions?.data?.find((d: any) => d.value === regions.selected);
        setTerritoryLabel(terr?.label);
      } else if (territory.includes('d')){
        setHoursCut(territory);
        setSumCut(territory);
        const terr = departements?.data?.find((d: any) => d.value === departements.selected);
        setTerritoryLabel(terr?.label);
      } else if (territory.includes('i')){
        setHoursCut(territory);
        setSumCut(territory);
        const terr = intercos?.data?.find((d: any) => d.value === intercos.selected);
        setTerritoryLabel(terr?.label);
      } else {
        setHoursCut('nr');
        setSumCut('nr');
      }
    }
  }, [territory]) 

  useEffect(() => {
    const comparisonData = comparison?.data?.find((d: any) => d.value === comparison.selected);
    setComparisonLabel(comparisonData?.label);
  }, [comparison.selected])

  return (
    <GenericPage>
      <ContainerCard>
        <GenericCard
          cardName='cttEvolution'
          color={theme.colors.ctt.primary.border}
          title={t('Ctt.Evolution.title')}
          data={sumevolutionData.map((d: any) => !d.comparisonValue ? ({"Année" : d.name, Total : d.value}) : ({"Année" : d.name, Total : d.value, [comparisonLabel] : d.comparisonValue}))}
          fileName={i18next.t('Filename.Ctt.evolution', {period, label : currentLabel})}
          Source1={<Trans
            i18nKey="Ctt.Evolution.source" // optional -> fallbacks to defaults if not provided
            values={{ 
              year : period.slice(4,5) ? `${period.slice(0,4)}-T${period.slice(4,5)}` : period,
              count : totalCtt?.toLocaleString()
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.ctt.primary.border}} />}}
          />}
        >
        {
          sumevolutionData.length ?
          <ContainerAreaChart>
            <GenericLineChart 
              key='sum' 
              data={sumevolutionData}
              isComparing={!!comparison.selected}
              color={theme.colors.ctt.secondary.medium}
            />
            {
              comparison.selected ? 
              <div style={{marginLeft : '10%'}} >
                <LegendGraph 
                  color={theme.colors.ctt.secondary.medium}
                  comparisonLabel={comparisonLabel}
                  territoryLabel={territoryLabel}
                />
              </div>
                : null
            }
          </ContainerAreaChart> :
          <Loader size={100} color={theme.colors.ctt.secondary.medium} isLoading={loadingEvolution} />
        }
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='cttNumberWorkers'
          color={theme.colors.ctt.secondary.border}
          title={t('Ctt.NumbWorkers.title')}
          Source1={sumData.length ? <Trans
            i18nKey={territory.slice(0,1) !== 'i' ? "Ctt.NumbWorkers.source" : "Ctt.NumbWorkers.sourceInterco"} // optional -> fallbacks to defaults if not provided
            values={{ 
              count : _.maxBy(sumData, (o) => o.value)?.value?.toLocaleString(), 
              territory : _.maxBy(sumData, (o) => o.value)?.label,
              in : labelSource[territoryType(sumCut)],
              interpolation: { escapeValue: false },
              interco : currentLabel
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.ctt.secondary.border}} />}}
          /> : undefined}
          label={t('Ctt.NumbWorkers.label')}
          total={totalCtt}
          Icon={() => <IconDemographic width={"4rem"} height={"4rem"} color={theme.colors.ctt.secondary.border} />}
          comparisonTotal={comparisonTotalCtt}
          isComparing={!!comparison.selected}
          data={sumData.map((d: any) => ({Code : d.name, Territoire : d.label, Total : d.value}))}
          fileName={i18next.t('Filename.Ctt.workers', {period, label : currentLabel})}
          >
            {
              sumData.length && !loadingSum ? 
              <ContainerMap 
                data={sumData} 
                hoursData={hoursData}
                dataView={sumCut}
                colors={{
                  dark : theme.colors.ctt.primary.dark, 
                  medium : theme.colors.ctt.primary.medium, 
                  light : theme.colors.ctt.primary.light, 
                  lighter : theme.colors.ctt.primary.lighter,
                  disabled : theme.colors.ctt.primary.disabled
                }}
                onClickSwitchButton={(value: MapTypes) => {
                  if(territory.slice(0,1) === 'n'){
                    setSumCut(`${territory.slice(0,1)}${value}` as any);
                  } else if(territory.slice(0,1) === 'r') {
                    setSumCut(`${territory.slice(0,1)}${value}${territory.split('r')[1]}` as any);
                  } else {
                    setSumCut(`${territory}${value}` as any);
                  }
                }}
                selectedMap={sumCut}
                dataType={territory.slice(0,1) as 'n' | 'r' | 'd' | 'i'} 
                color={theme.colors.ctt.primary.medium}             
              /> :
              <Loader size={100} color={theme.colors.ctt.primary.medium} isLoading={loadingSum} />
            }
        </GenericCard>
      </ContainerCard>
      {
        territory.slice(0,1) !== 'i' &&
        <ContainerCard>
          <GenericCard
            cardName='cttNumbersHours'
            color={theme.colors.ctt.primary.border}
            title={t('Ctt.NumbHours.title')}
            Source1={hoursData.length ? <Trans
              i18nKey="Ctt.NumbHours.source" // optional -> fallbacks to defaults if not provided
              values={{ 
                count : _.maxBy(hoursData, 'value')?.value?.toLocaleString(),  
                territory : _.maxBy(hoursData, 'value')?.label,
                in : labelSource[territoryType(hoursCut)],
                interpolation: { escapeValue: false },
              }}
              components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.ctt.primary.border}} />}}
            /> : undefined}
            label={t('Ctt.NumbHours.label')}
            total={territory.slice(0,1) === "n" || territory.slice(0,1) === "r" ? totalHoursCtt : _.sumBy(hoursData, 'value')}
            Icon={() => <IconHours width={25} height={25} color={theme.colors.ctt.primary.border} />}
            comparisonTotal={hoursData?.[0]?.comparisonValue}
            isComparing={!!comparison.selected}
            data={hoursData.map((d: any) => ({Code : d.name, Territoire : d.label, Total : d.value}))}
            fileName={i18next.t('Filename.Ctt.hours', {period, label : currentLabel})}
            >
              {
                hoursData.length && !loadingHours ?
                <ContainerMap  
                  data={hoursData} 
                  dataView={hoursCut}  
                  colors={{
                    dark : theme.colors.ctt.secondary.dark, 
                    medium : theme.colors.ctt.secondary.medium, 
                    light : theme.colors.ctt.secondary.light, 
                    lighter : theme.colors.ctt.secondary.lighter,
                    disabled : theme.colors.ctt.secondary.disabled
                  }}
                  onClickSwitchButton={(value: MapTypes) => {
                    if(territory.slice(0,1) === 'n'){
                      setHoursCut(`${territory.slice(0,1)}${value}` as any);
                    } else if(territory.slice(0,1) === 'r') {
                      setHoursCut(`${territory.slice(0,1)}${value}${territory.split('r')[1]}` as any);
                    } else {
                      setHoursCut(`${territory}${value}` as any);
                    }
                  }}
                  selectedMap={hoursCut}   
                  dataType={territory.slice(0,1) as 'n' | 'r' | 'd' | 'i'}  
                  color={theme.colors.ctt.secondary.medium}
                /> :
                <Loader size={100} color={theme.colors.ctt.secondary.medium} isLoading={loadingHours} />
              }
          </GenericCard>
        </ContainerCard>
      }
      <ContainerCard>
        <GenericCard
          cardName='cttDynamic'
          color={theme.colors.ctt.secondary.border}
          title={t('Ctt.Hours.title')}
          label={t('Ctt.Hours.label')}
          total={totalCtt}
          Icon={() => <IconDemographic width={"4rem"} height={"4rem"} color={theme.colors.ctt.secondary.border} />}
          data={grouphoursData.map((d: any) => !comparison.selected ? ({Tranches : d.description, Total : d.value}) : ({Tranches : d.description, Total : d.value, [comparisonLabel] : d.comparisonValue}))}
          fileName={i18next.t('Filename.Ctt.groupHours', {period, label : currentLabel})}
          Source1={grouphoursData.length ? <Trans
            i18nKey="Ctt.Hours.source" // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : _.maxBy(grouphoursData, (o) => o.value)?.percentage,
              groupHours : _.maxBy(grouphoursData, (o) => o.value)?.description === "< 50 heures" ? _.maxBy(grouphoursData, (o) => o.value)?.description.replace('<', 'moins de') : _.maxBy(grouphoursData, (o) => o.value)?.description.replace('<', ''),
              months : period.length === 4 ? 12 : 3
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", background : theme.colors.ctt.secondary.border, color : 'black'}} />}}
          /> : undefined}
        >
          {
            grouphoursData.length ? 
            <ContainerGraphBigBar>
              <ListBarIndicators 
                data={grouphoursData}
                color={theme.colors.ctt.secondary.border}
                size={'big'}
                label={t('Chart.Ctt.label')}
                isComparing={!!comparison.selected}
                width={600}
                labelWidth={210}
              />
              {
                comparison.selected ? 
                <LegendGraph 
                  color={theme.colors.ctt.secondary.border}
                  comparisonLabel={comparisonLabel}
                  territoryLabel={territoryLabel}
                /> : null
              }
            </ContainerGraphBigBar> : 
            <Loader height={44.5} size={100} color={theme.colors.ctt.secondary.border} isLoading={loadingGroupHours} />

          }
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='cttGenre'
          color={theme.colors.ctt.primary.border}
          title={t('Ctt.Sexe.title')}
          Source1={groupsexeData?.men || groupsexeData?.women ? <Trans
            i18nKey="Ctt.Sexe.source" // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : (groupsexeData?.men || 0) > (groupsexeData?.women || 0) ? Math.round((groupsexeData?.men || 0)/ ((groupsexeData?.men || 0) + (groupsexeData?.women || 0)) * 1000)/10 : Math.round((groupsexeData?.women || 0)/ ((groupsexeData?.men || 0) + (groupsexeData?.women || 0)) * 1000)/10,
              sexe : (groupsexeData?.men || 0) > (groupsexeData?.women || 0) ? "Hommes" : "Femmes"
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.ctt.primary.border}} />}}
          /> : undefined}       
          label={t('Ctt.Sexe.label')}
          data={
            !comparison.selected ? [{Hommes : groupsexeData?.men, Femmes : groupsexeData?.women, "Non renseigné" : groupsexeData?.nr}] :
            [{Hommes : groupsexeData?.men, Femmes : groupsexeData?.women, "Non renseigné" : groupsexeData?.nr, [`${comparisonLabel} hommes`] : groupsexeData?.['comparisonmen'], [`${comparisonLabel} femmes`] : groupsexeData?.['comparisonwomen']}]
          }
          fileName={i18next.t('Filename.Ctt.sexe', {period, label : currentLabel})}
        >
          {
            groupsexeData?.['men'] || groupsexeData?.['women'] || groupsexeData?.['nr'] ?
            <ContainerQuarterGraph>
              <CompareTwoQuarters 
                firstQuarterText={t('Chart.Quarter.first')}
                firstQuarterNumber={groupsexeData?.['women'] || 0}
                firstNationalQuarterNumber={groupsexeData?.['comparisonwomen'] ||  0}
                secondQuarterText={t('Chart.Quarter.second')}
                secondQuarterNumber={groupsexeData?.['men'] || 0}
                secondNationalQuarterNumber={groupsexeData?.['comparisonmen'] || 0}
                comparisonText={comparisonLabel === 'Comparaison nationale' ? 'National' : comparisonLabel}
                firstColor={theme.colors.ctt.secondary.medium}
                secondColor={theme.colors.ctt.ternary}
                workContract={t('Chart.Ctt.label')}
                isComparing={!!comparison.selected}
              />
            </ContainerQuarterGraph> :
            <Loader height={33.5} size={100} color={theme.colors.ctt.secondary.medium} isLoading={loadingGroupSexe} />
          }
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='cttAge'
          color={theme.colors.ctt.secondary.border}
          title={t('Ctt.Age.title')}
          data={groupageData.map((d: any) => !comparison.selected ? ({Tranches : d.description, Total : d.value}) : ({Tranches : d.description, Total : d.value, [comparisonLabel] : d.comparisonValue}))}
          fileName={i18next.t('Filename.Ctt.age', {period, label : currentLabel})}
          // label={t('Ctt.Age.label')}
          // total={` ans`}
          Source1={groupageData.length ? <Trans
            i18nKey="Ctt.Age.source" // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : _.maxBy(groupageData, (o) => o.value)?.percentage,
              groupAge : _.maxBy(groupageData, (o) => o.value)?.description
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.ctt.secondary.border}} />}}
          /> : undefined}  
        >
          {
            groupageData.length > 0 ?
            <ContainerGraphSmallBar>
              <ListBarIndicators 
                data={groupageData}
                color={theme.colors.ctt.secondary.border}
                size={'small'}
                label={t('Chart.Ctt.label')}
                isComparing={!!comparison.selected}
                width={700}
                labelWidth={110}
              />
              {
                comparison.selected ? 
                <LegendGraph 
                  color={theme.colors.ctt.secondary.border}
                  comparisonLabel={comparisonLabel}
                  territoryLabel={territoryLabel}
                /> : null
              }
            </ContainerGraphSmallBar> :
            <Loader height={35} size={100} color={theme.colors.ctt.secondary.border} isLoading={loadingGroupAge} />
          }
        </GenericCard>
      </ContainerCard>
    </GenericPage>

  )
}

export default CttDemographicsData;

const ContainerCard = styled.div`
  margin-bottom : 4.5rem;
`;

const ContainerGraphBigBar = styled.div`
  height         : 40rem;
  margin-top     : 4.5rem;
  padding-left   : 8.35rem;
  display        : flex;
  flex-direction : row;
  padding-bottom : 5rem;

  @media screen and (max-width : ${sizeMobile}px) {
    padding-left : 0rem;
  }
`;

const ContainerGraphSmallBar = styled.div`
  height         : 30rem;
  margin-top     : 4.5rem;
  padding-left   : 8.35rem;
  display        : flex;
  flex-direction : row;

  @media screen and (max-width : ${sizeMobile}px) {
    padding-left : 0rem;
  }
`;

const ContainerQuarterGraph = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: center;
  width          : 100%;
  padding-top    : 3.3rem;
  padding-bottom : 2rem;
`;

const ContainerAreaChart = styled.div`
  width           : 70%;
  padding-left    : 6.7rem;
  display         : flex;
  flex-direction  : row;
  align-items     : center;
  justify-content : space-between;
`;