import { call, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import { apiCall } from './axiosApiCallWrapper';
import { filterLoaded, selectFilter, resetFilter, departementsFiltered, intercosFiltered, defaultFilter, allFiltersLoaded } from '../actions/filters';
import { loadingEnd, loadingBegin } from '../actions/loading';
import { datasetsLoaded, selectDataset } from '../actions/datasets';
import { Dataset, Departement, Interco, Region, RootState } from '../../types';
import { nomenclaruresLoaded, selectNomenclature } from '../actions/nomenclature';
import { getParamsFiles } from '../actions/files';
import { useLocation } from 'react-router';
import { disablePeriod } from '../actions/global';
import _ from 'lodash';
import { getTrimestre } from '../../utils/helpers';

const excludeRegions: any = [/* "01", "02", "03", "04", "06" */];


function* loadFilters() {
  const {router}  = yield select((state: any) => state);
  const { query } = router.location;
  if(query.datasets){
    try {
      const {importId, nomenclature, period} = yield call(apiCall, 'GET', `${window._env_.MODEL_API_URL}/datasets/${query.datasets}`);
      yield put(datasetsLoaded([{id : "", period : period, importId : importId, nomenclature : nomenclature, valid : false}]))
      yield put(filterLoaded("years", [{label : period?.slice(0,4), value : period?.slice(0,4)}]))
      const { data : nomenclarures } = yield call(apiCall, 'GET', `${window._env_.MODEL_API_URL}/nomenclatures`);
      const pcs = nomenclarures.find(({id}: any)=> id === 'pcs');
      const sectors = nomenclarures.find(({id}: any)=> id === 'secteurs');
      yield put(filterLoaded("pcs_niv1", pcs['pcs.json'].pcs_niv1.map((pcs: any) => ({label : pcs.libelle, value : pcs.code_pcs}))))
      yield put(filterLoaded("pcs_niv2", pcs['pcs.json'].pcs_niv2.map((pcs: any) => ({label : pcs.libelle, value : pcs.code_pcs}))))
      yield put(filterLoaded("pcs_niv3", pcs['pcs.json'].pcs_niv3.map((pcs: any) => ({label : pcs.libelle, value : pcs.code_pcs}))))
      yield put(filterLoaded("sectors_niv1", sectors['secteurs.json'].secteurs_niv1.map((sector: any) => ({label : sector.libelle, value : sector.code_secteur}))))
      yield put(filterLoaded("sectors_niv2", sectors['secteurs.json'].secteurs_niv2.map((sector: any) => ({...sector, label : sector.libelle, value : sector.code_secteur}))))
      yield put(filterLoaded("sectors_niv5", sectors['secteurs.json'].secteurs_niv5.map((sector: any) => ({...sector, label : sector.libelle, value : sector.code_secteur}))))
      const nomenclaturesMaped = nomenclarures.map((d: any) => {
        const territoriesData = Object.entries(d)[0][1]
        return {
          [d.id] : territoriesData
        }
      })
      yield put(nomenclaruresLoaded(nomenclaturesMaped));
      if(period?.slice(4,5)){
        yield put(defaultFilter("years", period.slice(0,4)));
        yield put(filterLoaded("quarterly", [{label : getTrimestre(period.slice(4,5)), value : period.slice(4,5)}]))
        yield put(selectFilter("quarterly", period.slice(4,5)))
      } else {
        yield put(selectFilter("years", period.slice(0,4)))
      }
      yield put(disablePeriod());
      yield put(allFiltersLoaded())
    }
    catch(err) {
      console.error(err);
    }
  } else {
    try {
      const { data: datasets } : { data : Dataset[]} = yield call(apiCall, 'GET', `${window._env_.MODEL_API_URL}/datasets?valid=true`);
      yield put(datasetsLoaded(datasets))
      const years = _.uniqBy(datasets.map((dataset) => ({label : dataset.period.slice(0,4), value : dataset.period.slice(0,4)})).sort((a, b) => {if(a.label < b.label) return -1;else return 1;}), 'value');
      const quaterly = datasets.filter((dataset) =>dataset.period.slice(0,4) === years[years.length-1].label && dataset.period.slice(4,5)).map((dataset) => ({label : getTrimestre(dataset.period.slice(4,5)), value : dataset.period.slice(4,5)})).sort((a, b) => {if(a.label < b.label) return -1;else return 1;});
      const hasLastYearDataset = _.some(datasets, {period : years[years.length-1].label});
      yield put(filterLoaded("years", years))
      yield put(filterLoaded("quarterly", quaterly))
      const { data : nomenclarures } = yield call(apiCall, 'GET', `${window._env_.MODEL_API_URL}/nomenclatures`);
    
      const pcs = nomenclarures.find(({id}: any)=> id === 'pcs');
      const sectors = nomenclarures.find(({id}: any)=> id === 'secteurs');
      yield put(filterLoaded("pcs_niv1", pcs['pcs.json'].pcs_niv1.map((pcs: any) => ({label : pcs.libelle, value : pcs.code_pcs}))))
      yield put(filterLoaded("pcs_niv2", pcs['pcs.json'].pcs_niv2.map((pcs: any) => ({label : pcs.libelle, value : pcs.code_pcs}))))
      yield put(filterLoaded("pcs_niv3", pcs['pcs.json'].pcs_niv3.map((pcs: any) => ({label : pcs.libelle, value : pcs.code_pcs}))))
      yield put(filterLoaded("sectors_niv1", sectors['secteurs.json'].secteurs_niv1.map((sector: any) => ({label : sector.libelle, value : sector.code_secteur}))))
      yield put(filterLoaded("sectors_niv2", sectors['secteurs.json'].secteurs_niv2.map((sector: any) => ({...sector, label : sector.libelle, value : sector.code_secteur}))))
      yield put(filterLoaded("sectors_niv5", sectors['secteurs.json'].secteurs_niv5.map((sector: any) => ({...sector, label : sector.libelle, value : sector.code_secteur}))))
    
      const nomenclaturesMaped = nomenclarures.map((d: any) => {
        const territoriesData = Object.entries(d)[0][1]
        return {
          [d.id] : territoriesData
        }
      })
      yield put(nomenclaruresLoaded(nomenclaturesMaped));
      if(!hasLastYearDataset){
        yield put(selectFilter("years", years[years.length-2].label));
        yield put(defaultFilter("years", years[years.length-2].label));
      } else {
        yield put(selectFilter("years", years[years.length-1].label));
        yield put(defaultFilter("years", years[years.length-1].label));
      }
      yield put(allFiltersLoaded())
    }
    catch(err) {
      console.error(err);
    }
  }
}

function* updateNomenclature(data: any){
  const { filter, value } = data;
  const { data: datasets} = yield select((state: RootState) => state.datasets);
  const { data: nomenclatures} = yield select((state: RootState) => state.nomenclatures);
  const {departements, intercos, regions, years, quarterly} = yield select((state: RootState) => state.filters);
  if(filter === "years"){
    let dataset: Dataset;
    if(quarterly.selected){
      dataset = datasets.find((dataset: Dataset) => dataset.period === `${value}${quarterly.selected}` || (dataset.period === `${years.defaultValue}${quarterly.selected}` && !value));
    } else {
      dataset = datasets.find((dataset: Dataset) => dataset.period === value || (dataset.period === years.defaultValue && !value));
    }
    const quarterlyList = datasets
    .filter((dataset: Dataset) =>(dataset.period.slice(0,4) === years.selected || (!years.selected && dataset.period.slice(0,4) === years.defaultValue)) && dataset.period.slice(4,5))
    .map((dataset: Dataset) => ({label : getTrimestre(dataset.period.slice(4,5)), value : dataset.period.slice(4,5)}))
    .sort((a: any, b: any) => a.label < b.label ? -1 : 1);
    yield put(selectDataset(dataset))
    yield put(filterLoaded("quarterly", quarterlyList))
    const nomenclature = nomenclatures?.find((nomenclature: any) => {
      return Object.keys(nomenclature)[0] === dataset?.nomenclature
    });
    yield put(selectNomenclature(nomenclature?.[dataset.nomenclature]));
    yield put(filterLoaded("regions", nomenclature?.[dataset.nomenclature].regions?.filter((d: any) => !_.includes(excludeRegions, d.code_reg)).map((reg: any) => ({...reg, label : reg.libelle, value : reg.code_reg}))));
    yield put(filterLoaded("departements", nomenclature?.[dataset.nomenclature].departements?.map((dep: any) => ({...dep, label : dep.libelle, value : dep.code_dep}))));
    yield put(filterLoaded("intercos", nomenclature?.[dataset.nomenclature].intercos?.map((int: any) => ({...int, label : int.libelle, value : int.code_int}))));

  } else if(filter === "quarterly" && value){
    const dataset = datasets?.find((dataset: Dataset) => dataset.period === `${years.selected || years.defaultValue}${value}`);
    yield put(selectDataset(dataset))
    const nomenclature = nomenclatures?.find((nomenclature: any) => {
      return Object.keys(nomenclature)?.[0] === dataset?.nomenclature
    });
    yield put(selectNomenclature(nomenclature?.[dataset?.nomenclature]));
    yield put(filterLoaded("regions", nomenclature?.[dataset?.nomenclature]?.regions?.filter((d: any) => !_.includes(excludeRegions, d.code_reg)).map((reg: any) => ({...reg, label : reg.libelle, value : reg.code_reg}))));
    yield put(filterLoaded("departements", nomenclature?.[dataset?.nomenclature]?.departements?.map((dep: any) => ({...dep, label : dep.libelle, value : dep.code_dep}))));
    yield put(filterLoaded("intercos", nomenclature?.[dataset?.nomenclature]?.intercos?.map((int: any) => ({...int, label : int.libelle, value : int.code_int}))));
  }
  else if(filter === "quarterly" && !value){
    const dataset = datasets.find((dataset: Dataset) => dataset.period === `${years.selected || years.defaultValue}`);
    yield put(selectDataset(dataset || {}))
    if(dataset){
      const nomenclature = nomenclatures.find((nomenclature: any) => {
        return Object.keys(nomenclature)?.[0] === dataset?.nomenclature
      });
      yield put(selectNomenclature(nomenclature?.[dataset?.nomenclature]));
      yield put(filterLoaded("regions", nomenclature?.[dataset?.nomenclature]?.regions?.filter((d: any) => !_.includes(excludeRegions, d.code_reg)).map((reg: any) => ({...reg, label : reg.libelle, value : reg.code_reg}))));
      yield put(filterLoaded("departements", nomenclature?.[dataset?.nomenclature]?.departements?.map((dep: any) => ({...dep, label : dep.libelle, value : dep.code_dep}))));
      yield put(filterLoaded("intercos", nomenclature?.[dataset?.nomenclature]?.intercos?.map((int: any) => ({...int, label : int.libelle, value : int.code_int}))));
    }
  }
  else if (filter === "regions" && !value){
    yield put(intercosFiltered([]));
    yield put(selectFilter("departements", ""))
    yield put(selectFilter("intercos", ""))
    yield put(selectFilter("comparison", ""))
    yield put(departementsFiltered([]));
  } else if (filter === "departements" && !value){
    yield put(filterLoaded("comparison", [{label: "Aucune comparaison", value : ""}, {label: "Comparaison nationale", value : "n0"}]))
    yield put(selectFilter("comparison", ""))
    if(regions.selected){
      yield put(intercosFiltered(intercos.data.filter((int: any) => _.includes(int.code_reg, regions.selected))));
    } else {
      yield put(intercosFiltered([]));
    }
  } else if (filter === "intercos" && !value){
    if(departements.selected){
      const dep: Departement = departements.data.find((d: Departement) => d.code_dep === departements.selected);
      const region = dep.code_reg;
      const regionFiltered = regions.data.filter((reg: Region) => _.includes(region, reg.code_reg));
      yield put(filterLoaded("comparison", [{label: "Aucune comparaison", value : ""}, {label: "Comparaison nationale", value : "n0"}, ...regionFiltered]))
      yield put(selectFilter("comparison", ""))
    } else {
      yield put(filterLoaded("comparison", [{label: "Aucune comparaison", value : ""}, {label: "Comparaison nationale", value : "n0"}]))
      yield put(selectFilter("comparison", ""))
    }
  } else if (filter === "regions" && value) {
    yield put(selectFilter("departements", ""))
    yield put(selectFilter("intercos", ""))
    yield put(departementsFiltered(departements.data.filter((dep: any) => dep.code_reg === value)));
    yield put(intercosFiltered(intercos.data.filter((int: any) => _.includes(int.code_reg, value))));
    yield put(filterLoaded("comparison", [{label: "Aucune comparaison", value : ""}, {label: "Comparaison nationale", value : "n0"}]))
    yield put(selectFilter("comparison", ""))
  } else if (filter === "departements" && value) {
    const dep: Departement = departements.data.find((d: Departement) => d.code_dep === value);
    const region = dep.code_reg;
    const regionFiltered = regions.data.filter((reg: Region) => _.includes(region, reg.code_reg));
    yield put(selectFilter("intercos", ""))
    yield put(intercosFiltered(intercos.data.filter((int: any) => _.includes(int.code_dep, value))));
    yield put(filterLoaded("comparison", [{label: "Aucune comparaison", value : ""}, {label: "Comparaison nationale", value : "n0"}, ...regionFiltered]))
    yield put(selectFilter("comparison", ""))
  } else if (filter === "intercos" && value){
    const int: Interco = intercos.data.find((d: Interco) => d.code_int === value);
    const departement = int.code_dep;
    const region = int.code_reg;
    const depFiltered = departements.data.filter((dep: Departement) => _.includes(departement, dep.code_dep));
    const regionFiltered = regions.data.filter((reg: Region) => _.includes(region, reg.code_reg));
    yield put(filterLoaded("comparison", [{label: "Aucune comparaison", value : ""}, {label: "Comparaison nationale", value : "n0"}, ...regionFiltered, ...depFiltered]))
    yield put(selectFilter("comparison", ""))
  } else if (filter === "intercos" && !value){
    const int: Interco = intercos.data.find((d: Interco) => d.code_int === value);
    const departement = int.code_dep;
    const region = int.code_reg;
    const depFiltered = departements.data.filter((dep: Departement) => _.includes(departement, dep.code_dep));
    const regionFiltered = regions.data.filter((reg: Region) => _.includes(region, reg.code_reg));
    yield put(filterLoaded("comparison", [{label: "Aucune comparaison", value : ""}, {label: "Comparaison nationale", value : "n0"}, ...regionFiltered, ...depFiltered]))
    yield put(selectFilter("comparison", ""))
  }
  
  yield put(getParamsFiles())
}

function* filterSaga() {
  yield takeEvery('INIT_SUCCEEDED',  loadFilters);
  yield takeEvery('LOGIN_SUCCEEDED',  loadFilters);
  yield takeEvery('UPDATE_FILTERS',  updateNomenclature);
  // yield takeEvery('DEFAULT_FILTER',  updateNomenclature);
}

export default filterSaga;