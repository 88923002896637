import React from 'react';
import styled from 'styled-components';

import { Video } from '../../assets/svg-images/Video';
import { Methodo } from '../../assets/svg-images/Methodo';
import { calcHeightMobileRem, calcRem, calcWidthMobileRem } from '../../utils/dimension';
import { sizeMobile } from '../../utils/constants';

interface CardProps {
  children: React.ReactNode;
  type : 'methodo' | 'video';
  style?: any;
  onClick: () => void;
}

const Card: React.FC<CardProps> = ({type, style, children, onClick, ...props}) => {
  return (
    <Container 
      dataType={type} 
      style={style}
      onClick={onClick}
      {...props}
    >
      <ContainerSvg>
        {
          type === 'video' ?
          <Video width={`100%`} height={`100%`} /> :
          type === 'methodo' ?
          <Methodo width={`100%`} height={`100%`} /> :
          null
        }
      </ContainerSvg>
      {children}
    </Container>
  )
}

export default Card;

const Container = styled.div<any>`
  width            : 100%;
  height           : ${calcRem(24.8)}rem;
  border-radius    : 2rem;
  background-color : ${({theme, dataType}) => dataType === 'video' ? theme.colors.primary : theme.colors.thirdary};
  box-shadow       : 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 20px 60px rgba(131, 131, 145, 0.75);
  overflow         : hidden;
  display          : flex;
  align-items      : center;
  cursor           : pointer;

  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction : column;
    align-items    : center;
    height         : ${calcWidthMobileRem(38)}rem;
  }
`;

const ContainerSvg = styled.div`
  width : ${calcRem(28.8)}rem;
  height : ${calcRem(28.8)}rem;
  margin-left : ${calcRem(4.8)}rem;
  margin-top  : ${calcRem(2.8)}rem;

  @media screen and (max-width : ${sizeMobile}px) {
    width : ${calcWidthMobileRem(18)}rem;
    height : ${calcWidthMobileRem(18)}rem;
    margin-left : 0;
    margin-top : ${calcWidthMobileRem(-1)}rem;;
  }
`;