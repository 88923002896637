import i18next from 'i18next';

import en from './translations/en.json';
import fr from './translations/fr.json';

i18next.init({
  lng         : 'fr',
  fallbackLng : 'fr',
  resources   : {
    en : {translation : en},
    fr : {translation : fr}
  },
});

const t = (...args : [any]) => i18next.t(...args);

export {i18next, t};
