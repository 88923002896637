import React from 'react';
import logo from './logo.svg';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { theme } from './assets/theme';
import './App.css';

import configureStore from './store/store';
import history from './utils/history';
import MyRoutes from './routes/Routes';

function App() {
  const initialState = {};
  const store = configureStore(initialState, history);
  const persistor = persistStore(store);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <MyRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
