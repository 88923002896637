import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../atoms/Logo';
import NavigationHeader from '../atoms/NavigationHeader';
import FiltersChips from '../molecules/FiltersChips';
import Menu from '../organisms/Menu';
import Footer from '../atoms/Footer';
import MentionsPage from '../../pages/Mentions';
import MethodologiePage from '../../pages/Methodologie';
import HeaderMobile from '../atoms/HeaderMobile';
import MobileFilters from '../organisms/MobileFilters';
import AllMethodo, { Methodos } from '../organisms/AllMethodo';
import { IconClose } from '../../assets/svg-images/IconClose';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types';
import { closePopup } from '../../store/actions/popup';
import useWindowSize from '../../hooks/useWindowSize';
import { sizeMobile } from '../../utils/constants';
import Icon from '../atoms/Icon';
import { theme } from '../../assets/theme';
import GenericMobileButton from '../atoms/GenericMobileButton';
import { t } from '../../i18n';
import { selectFilter } from '../../store/actions/filters';
import useOutsideClick from '../../hooks/useOutsideClick';

interface GenericPageProps {
  children: React.ReactNode;
}

const GenericPage: React.FC<GenericPageProps> = ({children}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1];
  const view : any = path;
  const size = useWindowSize();
  const location = useLocation();

  const { show, name : popupName } = useSelector((state : RootState) => state.popup);
  const {popupColor} = useSelector((state: RootState) => state.global);
  const filters = useSelector((state: RootState) => state.filters);
 
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [verticalAnimation, setVerticalAnimation] = useState('');
  const [horizontalAnimation, setHorizontalAnimation] = useState('');

  const methodoRef = useRef(null);
  useOutsideClick(methodoRef, () => dispatch(closePopup(popupName)));

  useEffect(()=> {
    return () => setShowMenu(false);
  }, [])

  useEffect(()=> {
    setVerticalAnimation('');
    setHorizontalAnimation('');
    setShowMenu(false);
    return () => setShowMenu(false);
  }, [view])

  useEffect(() => {
    if(verticalAnimation === 'verticalslideout'){
      setTimeout(() => {
        setShowFilters(false);
      }, 600) 
    }
  }, [verticalAnimation])

  useEffect(() => {
    if(horizontalAnimation === 'horizontalslideout'){
      setTimeout(() => {
        setShowMenu(false);
      }, 600) 
    }
  }, [horizontalAnimation])

  useEffect(() => {
    if(size.width && size.width <= sizeMobile){
      dispatch(selectFilter('comparison', ""))
    }
  }, [size])

  return (
    <>
      {
        size.width && size.width <= sizeMobile ?
        <ContainerMobile>
          <HeaderMobile 
            LeftItem={() => <div onClick={() => navigate('accueil')}><Logo /></div>}
            RightItem={showMenu ? () =>
              <GenericMobileButton 
                color={theme.colors.primary} 
                iconColor={theme.colors.neutral} 
                iconName='close' 
                onClick={() => {
                  setHorizontalAnimation('horizontalslideout');
                }} 
                iconWidth={15} 
              /> :
              () => 
              <GenericMobileButton 
                color="white"
                iconColor={theme.colors.primary} 
                iconName='hamburger' 
                onClick={() => {
                  setHorizontalAnimation('horizontalslidein');
                  setShowMenu(true);
                }} 
                iconWidth={15} 
              />
            }
          />
          {
            show && popupName === 'mentions' ? 
            <MentionsPage onClose={() => dispatch(closePopup(''))} /> :
            show && popupName === 'methodo' ?
            <MethodologiePage onClose={() => dispatch(closePopup(''))} /> :
            <MarginMobile>
              <MobileTitle>
                <Title>{t(`View.${view}.title`)}</Title>
                {/* <SubTitle>{t(`View.${view}.title`)}</SubTitle> */}
              </MobileTitle>
              <FiltersChips filters={filters} />
              <Content>
                {children}
                <Footer />
              </Content>
            </MarginMobile>
          }
          {
            showMenu ?
            <ContainerMenu animation={horizontalAnimation}>
              <NavigationHeader pathname={location.pathname} />
            </ContainerMenu> : null
          }
          <StickyBar>
            <ContainerIconFilter onClick={() => {setShowFilters(true); setVerticalAnimation('verticalslidein');}}>
              <Icon icon='filterButton' height={25} width={25} color={theme.colors.primary} />
            </ContainerIconFilter>
          </StickyBar>
          
          {
            showFilters ? 
            <ContainerFilters animation={verticalAnimation}>
              <ContainerIconClose>
                <CloseButton onClick={()=> setVerticalAnimation('verticalslideout')}>
                  <Icon icon='close' height={20} width={20} color={theme.colors.neutral} />
                </CloseButton>
              </ContainerIconClose>
              <MobileFilters />
            </ContainerFilters> : null
          }
          
        </ContainerMobile> :
        size.width && size.width > sizeMobile ?
        <Container>
          {
            show && popupName === 'mentions' ? 
            <MentionsPage onClose={() => dispatch(closePopup(''))} /> :
            show && popupName === 'methodo' ?
            <MethodologiePage onClose={() => dispatch(closePopup(''))} /> :
            <>
              <Left>
                <ContainerLogo onClick={() => navigate('accueil')}>
                  <Logo />
                </ContainerLogo>
                <Menu />
              </Left>
              <Right>
                <ContainerHeader>
                  <NavigationHeader pathname={location.pathname} />
                </ContainerHeader>
                <Margin>
                  <FiltersChips filters={filters} />
                  <Content>
                    {children}
                    <Footer />
                  </Content>
                </Margin>
              </Right>
            </>
          }
        </Container> : null
      }
      {
        show && popupName.split('-')[0] === 'methodo' && popupName.split('-').length === 2  ? 
        <Methodo color={popupColor}>
          <ClosePopupButton onClick={() => dispatch(closePopup(popupName))} >
            <Close>{t('Homepage.close')}</Close>
            <IconClose height={"1.8rem"} width={"1.8rem"} color={theme.colors.text} />
          </ClosePopupButton>
          <ContainerMethodoSvg ref={methodoRef}>
            <AllMethodo name={popupName.split('-')[1] as Methodos} /> 
          </ContainerMethodoSvg>  
        </Methodo> : null
      }
    </>
  )
}

export default GenericPage;

const Container = styled.div`
  display        : flex;
  flex-direction : row;
  min-height     : 100%;
`;

const Left = styled.div`
  flex : 0;
  z-index : 1;
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContainerLogo = styled.div`
  height : 9rem;
  box-shadow: 1px 0px 10px rgba(0,0,0,0.12);
  cursor : pointer;
`;

const ContainerHeader = styled.div`
  height : 9rem;
  flex : 0;
`;

const Margin = styled.div`
  padding-left     : 4.4rem;
  padding-top      : 2.1rem;
  padding-right    : 4.4rem;
  display          : flex;
  flex-direction   : column;
  flex             : 1 1 auto;
  background-color : rgb(244, 244, 248);
  height           : 0px;
  overflow         : auto;
`;

const Content = styled.div`
  margin-top       : 3.3rem;  
`;

const ContainerMobile = styled.div`
  width : 100%;
  display        : flex;
  flex-direction : column;
  min-height     : 100%;
`;

const MarginMobile = styled.div`
  padding-left     : 1rem;
  padding-top      : 2.1rem;
  padding-right    : 1rem;
  background-color : #F9F8F6;
  flex             : 1 1 auto;
  height           : 0px;
  overflow         : auto;
`;

const ContainerFilters = styled.div<any>`
  z-index   : 1;
  position  : absolute;
  width     : 100%;
  height    : 100%;
  top       : 130%;
  background-color          : ${({theme}) => theme.colors.neutral};
  animation-duration        : ${({animation}) => animation === 'verticalslidein' ? '1s' : '600ms'};
  animation-name            : ${({animation}) => animation};
  animation-iteration-count : 1;
  animation-fill-mode       : forwards;

  @keyframes verticalslidein {
    from {
      top : 130%;
    }
    to {
      top : 23rem;
    }
  }

  @keyframes verticalslideout {
    from {
      top : 23rem;
    }
    to {
      top : 130%;
    }
  }

  @media screen and (min-width : ${sizeMobile}px) {
    display : none;
  }
`;

const StickyBar = styled.div`
  position         : absolute;
  bottom           : 0px;
  height           : 65px;
  width            : 100%;
  background-color : ${({theme}) => theme.colors.neutral};
  display          : flex;
  justify-content  : center;
  align-items      : center;
  z-index          : 1;
  
  @media screen and (min-width : ${sizeMobile}px) {
    display : none;
  }
`;

const ContainerIconFilter = styled.div`
  position        : relative;
  width           : 50px;
  height          : 50px;
  border-radius   : 50%;
  display         : flex;
  justify-content : center;
  align-items     : center;
  background-color: ${({theme}) => theme.colors.primary};
`;

const ContainerIconClose = styled.div`
  width           : 100%;
  position        : absolute; 
  display         : flex;
  justify-content : center;
  top             : -25px;
`;

const CloseButton = styled.div`
  position        : relative;
  width           : 50px;
  height          : 50px;
  border-radius   : 50%;
  display         : flex;
  justify-content : center;
  align-items     : center;
  background-color: ${({theme}) => theme.colors.primary};
`;

const ContainerMenu = styled.div<any>`
  z-index   : 2;
  position  : absolute;
  height    : 100%;
  width     : 100%;
  top       : 12rem;
  background-color          : ${({theme}) => theme.colors.neutral};
  animation-duration        : ${({animation}) => animation === 'horizontalslidein' ? '600ms' : '600ms'};
  animation-name            : ${({animation}) => animation};
  animation-iteration-count : 1;
  animation-fill-mode       : forwards;

  @keyframes horizontalslidein {
    display   : block;
    from {
      left : 100%;
    }
    to {
      left : 0%;
    }
  }

  @keyframes horizontalslideout {
    display   : block;
    from {
      left : 0%;
    }
    to {
      left : 100%;
    }
  }

  @media screen and (min-width : ${sizeMobile}px) {
    display : none;
  }
`;

const MobileTitle = styled.div`

`;

const Title = styled.div`
  background-color : transparent;
  top              : 0;
  margin-top       : 7px;
  font-size        : 1.4rem;
  font-family      : work-sans-semiBold;
  font-weight      : 600;
  margin-bottom    : 2rem; 

  @media screen and (max-width : ${sizeMobile}px) {
    margin-left      : 1rem;
    font-size        : 1.9rem;
    margin-top       : 2rem;
  }
`;


const SubTitle = styled.div`
  background-color : transparent;
  text-align       : start;
  font-size        : ${({theme}) => theme.fonts.large};
  font-family      : work-sans-bold;
  line-height      : 2.3rem;
  text-overflow    : ellipsis;
  overflow         : hidden;
  margin-bottom    : 3rem; 

  @media screen and (max-width : ${sizeMobile}px) {
    font-size        : 2.2rem;
    margin-left      : 1rem;
  }
`;

const Methodo = styled.div`
  position         : absolute;
  top              : 0;
  left             : 0;
  width            : 100%;
  height           : 100%;
  display          : flex;
  flex-direction   : column;
  align-items      : center;
  justify-content  : center;
  background-color : ${({color}) => color};
  opacity          : 0.95;
  z-index          : 2;
`;

const ClosePopupButton = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  margin-bottom  : 2rem;
  cursor         : pointer;
  z-index        : 1;
`;

const Close = styled.div`
  font-size      : 2.5rem;
  font-family    : work-sans-semiBold;
  text-transform : uppercase;
  color          : ${({theme}) => theme.colors.text};
  letter-spacing : 0.2rem;
  margin-right   : 1rem;
`;

const ContainerMethodoSvg = styled.div`
  width : 100rem;
  height : 60rem;

  @media screen and (max-width : ${sizeMobile}px) {
    width : 100%;
    height : 50rem;
  }
`;