import React from 'react';
import styled from 'styled-components';
import { ListBarData } from '../../types';
import CompareBarIndicator from '../molecules/CompareBarIndicator';

export interface ListBarIndicatorsProps{
  data : ListBarData[];
  color : string;
  size : Size;
  width : number;
  label : string;
  isComparing : boolean;
  labelWidth?: number;
}

type Size = 'small'| 'big';


const ListBarIndicators : React.FC<ListBarIndicatorsProps> = ({ data, color, size, width, label, isComparing, labelWidth }) => {

  return(
    <Container>
      {data?.map((element, index)=> (
        <CompareBarIndicator 
          key={`bar-indicator-${index}`}  
          color={color} 
          percentage={element.percentage} 
          comparisonPercentage={element.comparisonPercentage} 
          height={ size == 'big' ? 41 : 23 } 
          width={width} 
          label={`${element.value} ${label}`}
          labelWidth={labelWidth} 
          description={element.description} 
          withDot={false} 
          isComparing={isComparing} 
          value={element.value}
        />
      ))}
    </Container>
  )
}

export default ListBarIndicators;

const Container = styled.div`
  display        : flex;
  flex-direction : column;
  height         : 100%;
  justify-content : space-between;
`;