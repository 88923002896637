import { IconProps } from "../../types";

export const IconContract : React.FC<IconProps>= ({width=38.625, height=40.007, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 38.625 40.007">
  <g id="Groupe_1177" data-name="Groupe 1177" transform="translate(12236.27 18885.154)">
    <g id="noun-profile-profession-1726238" transform="translate(-12236.27 -18885.154)">
      <path id="Tracé_469" data-name="Tracé 469" d="M373.117,276H342.741a.743.743,0,0,0-.741.741v38.526a.743.743,0,0,0,.741.741h30.376a.743.743,0,0,0,.741-.741V276.741A.743.743,0,0,0,373.117,276Zm-.741,38.526H343.482V277.482h28.894Z" transform="translate(-342 -276)" fill="#405693"/>
    </g>
    <line id="Ligne_8" data-name="Ligne 8" x2="22.004" transform="translate(-12230.949 -18869.709)" fill="none" stroke="#45558f" strokeWidth="2"/>
    <line id="Ligne_12" data-name="Ligne 12" x2="22.004" transform="translate(-12230.949 -18877.568)" fill="none" stroke="#45558f" strokeWidth="2"/>
    <line id="Ligne_9" data-name="Ligne 9" x2="22.004" transform="translate(-12230.949 -18865.779)" fill="none" stroke="#405693" strokeWidth="2"/>
    <line id="Ligne_13" data-name="Ligne 13" x2="22.004" transform="translate(-12230.949 -18873.639)" fill="none" stroke="#405693" strokeWidth="2"/>
    <line id="Ligne_10" data-name="Ligne 10" x2="22.004" transform="translate(-12230.949 -18861.85)" fill="none" stroke="#405693" strokeWidth="2"/>
    <line id="Ligne_11" data-name="Ligne 11" x2="22.004" transform="translate(-12230.949 -18857.922)" fill="none" stroke="#405693" strokeWidth="2"/>
    <line id="Ligne_14" data-name="Ligne 14" x2="9.43" transform="translate(-12230.949 -18853.992)" fill="none" stroke="#405693" strokeWidth="2"/>
    <path id="noun-pen-308320" d="M42.926,6.84H39.554V25.558l1.686,2.461,1.686-2.461V9.415h.491v4.671h1.1V8.312H42.926Zm-1.1,18.376-.582.85-.582-.85V7.944h1.165Z" transform="translate(-12242.165 -18875.352)" fill="#405693"/>
  </g>
</svg>
)