import React, { useEffect, Suspense, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Loader } from '../components/atoms/Loader';
import GenericLineChart from '../components/atoms/GenericLineChart';
import { GenericCard } from '../components/organisms/GenericCard';
import GenericPage from '../components/templates/GenericPage';
import ListBarIndicators from '../components/graphs/ListBarIndicators';
import CompareTwoQuarters from '../components/graphs/CompareTwoQuarters';
import ContainerMap from '../components/organisms/ContainerMap';
import { IconDemographic } from '../assets/svg-images/IconDemographics';

import { theme } from '../assets/theme';
import { i18next, t } from '../i18n';
import { CttLoading, GenericData, GroupSexeData, ListBarData, MapTypes, ResponseGroupAge, RootState } from '../types/index';
import { CDII_FILES, labelGroupAges, sizeMobile } from '../utils/constants';
import { client } from '../store/sagas/axiosApiCallWrapper';
import LegendGraph from '../components/atoms/LegendGraph';
import { getTerritory } from '../utils/helpers';
import { Trans } from 'react-i18next';

const Cdii: React.FC = () => {
  const {period, territory} = useSelector((state: RootState) => state.paramsFiles);
  const {selected} = useSelector((state: RootState) => state.datasets);
  const importId = selected?.importId || null;
  const {totalCdii, comparisonLabel, currentLabel, comparisonTotalCdii} = useSelector((state: RootState) => state.global);
  const {selected: nm}: {selected : {[key: string] : any[]}} = useSelector((state: RootState) => state.nomenclatures);
  const {comparison, regions, departements, intercos} = useSelector((state: RootState) => state.filters);

  const [sumevolutionData, setSumevolutionData] = useState<any[]>([]);
  const [sumData, setSumData] = useState<any[]>([]);
  const [groupsexeData, setGroupsexeData] = useState<GroupSexeData | null>(null);
  const [groupageData, setGroupageData] = useState<ListBarData[]>([]);
  const [loadingCharts, setLoadingCharts] = useState<CttLoading>({});
  const [loadingEvolution, setLoadingEvolution] = useState<boolean>(false);
  const [loadingGroupSexe, setLoadingGroupSexe] = useState<boolean>(false);
  const [loadingGroupAge, setLoadingGroupAge] = useState<boolean>(false);
  const [loadingSum, setLoadingSum] = useState<boolean>(false);
  const [sumCut, setSumCut] = useState<MapTypes>('');
  const [totalAge, setTotalAge] = useState<number>(0);

  const territoryType = () => {
    if(sumCut === 'nr'){return 'regions'}
    if(sumCut === 'nd'){return 'departements'}
    if(sumCut.slice(0,2) === 'rd'){return 'departements'}
    if(sumCut.slice(0,2) === 'ri'){return 'intercos'}
    if(sumCut.slice(0,1) === 'd'){return 'intercos'}
    // if(territory.slice(0,1) === 'r'){return 'departements'}
    // if(territory.slice(0,1) === 'd'){return 'intercos'}
    return 'regions'
  }

  useEffect(() => {
    setLoadingCharts({['sumevolution'] : true, ['groupsexe'] : true, ['groupage'] : true});
    setLoadingEvolution(true)
    setLoadingGroupSexe(true)
    setLoadingGroupAge(true)
    if(period){
      CDII_FILES.forEach(async (fileType: string) => {
          const fileName = period + '.' + 'cdii' + '.' + `${fileType}` + '.' + territory + '.json';

          let comparisonData: any;
          if(comparison.selected){
            const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
            const comparisonName = getTerritory(comparisonItem); 
            const comparisonFileName = period + '.' + 'cdii' + '.' + `${fileType}` + '.' + comparisonName + '.json';
            try {
              const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${comparisonFileName}?importId=${importId}`);
              comparisonData = data;
            }
            catch (err){
              comparisonData = [];
            }
          }
          try {
            const { data : {data, total} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`)
            // SUM_EVOLUTION
            if(fileType === 'sumevolution'){
              const dataMaped = data.map((d: GenericData, index: number) => (
                { 
                  name : d.key.slice(4,5) ? `${d.key.slice(0,4)}-T${d.key.slice(4,5)}` : d.key, 
                  value : d.value, 
                  comparisonValue : comparisonData?.[index]?.value || 0
                }
              ));
              const dataSorted = _.sortBy(dataMaped, (o) => o.name)
              setSumevolutionData(dataSorted);
              setLoadingEvolution(false)
            } 
            // GROUP_SEXE
            else if(fileType === 'groupsexe'){
              let groupSexe: GroupSexeData = {};
              data?.forEach((d: GenericData) => {
                if(d.key === '0'){
                  groupSexe['women'] = d.value;
                } else if(d.key === '1'){
                  groupSexe['men'] = d.value;
                } else if(d.key === '3'){
                  groupSexe['nr'] = d.value;
                }
              });
              comparisonData?.forEach((d: GenericData) => {
                if(d.key === '0'){
                  groupSexe['comparisonwomen'] = d.value;
                } else if(d.key === '1'){
                  groupSexe['comparisonmen'] = d.value;
                } else if(d.key === '3'){
                  groupSexe['comparisonnr'] = d.value;
                }
              });
              setGroupsexeData(groupSexe);
              setLoadingGroupSexe(false)
            } 
            // GROUP_AGE
            else if(fileType === 'groupage'){
              const comparisonTotal = _.sumBy(comparisonData?.map((d: GenericData) => d.value)); 
              setTotalAge(total); 
              const sortedData = _.sortBy(
                data.map(
                  (d: ResponseGroupAge, index: number) => (
                    { 
                      description : labelGroupAges[d.key], 
                      percentage : Math.floor(d.value/totalCdii*10000)/100, 
                      comparisonPercentage : Math.floor(comparisonData?.find((dc: ResponseGroupAge) => dc.key === d.key)?.value/comparisonTotal*10000)/100,
                      comparisonValue : comparisonData?.[index]?.value,
                      value : d.value,
                      key: d.key
                    }
                  )
                ), [(d) => parseFloat(d.key)]
              ).reverse()
              setGroupageData(sortedData);
              setLoadingGroupAge(false)
            }
          } catch(error){
            if(fileType === 'sumevolution'){
              setSumevolutionData([]);
            } else if(fileType === 'groupsexe'){
              setGroupsexeData({});
            } else if(fileType === 'groupage'){
              setGroupageData([]);
            } else if(fileType === 'sum'){
              setSumData([]);
            }
            setLoadingCharts({[fileType] : false})
          }
        })
      }
  }, [period, territory, comparison]);

  useEffect(() => {
    setLoadingSum(true)
    const loadData = async() => {
      let fileName: string;
      if(sumCut === 'd75') {
        fileName = period + '.' + 'cdii' + '.' + `sum` + '.' + 'rd11' + '.json';
      } else {
        fileName = period + '.' + 'cdii' + '.' + `sum` + '.' + sumCut + '.json';
      }
      let comparisonData: any;
      try {
        const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`) 
        const code = territoryType().slice(0,3);
        let items;
        if(sumCut === 'd75'){
          items = [data.find((d: GenericData) => d.key === '75')];
        } else {
          items = data;
        }
        setSumData(items
          .filter((d: GenericData) => d.key !== "99")
          .map((d: GenericData, index: number) => {
          const terr: any = nm[territoryType()].find((t) =>{ 
            return t?.[`code_${code}`] === d.key
          });
          return ({ 
            name : sumCut === 'd75' ? '200054781' : d.key, 
            value : d.value, 
            label : sumCut === 'd75' ? 'Paris' : terr?.libelle, 
            comparisonValue : comparisonData?.[index]?.value
          })
        }));
        setLoadingSum(false)
      } 
      catch (e) {
        setSumData([]);
        setLoadingSum(false)
      }
    }
    if(period && sumCut){
      loadData();
    }
  }, [sumCut, period, comparison]);

  useEffect(() => {
    if(territory){
      if(territory.includes('r')){
        const region = `rd${territory.split('r')[1]}` as MapTypes
        setSumCut(region);
      } else if (territory.includes('d')){
        setSumCut(territory);
      } else if (territory.includes('i')){
        setSumCut(territory);
      } else {
        setSumCut('nr');
      }
    }
  }, [territory]) 

  return (
    <GenericPage>
      <ContainerCard>
        <GenericCard
          cardName='cdiiEvolution'
          color={theme.colors.cdii.primary.border}
          title={t('Cdii.Evolution.title')}
          data={sumevolutionData.map((d: any) => !d.comparisonValue ? ({"Année" : d.name, Total : d.value}) : ({"Année" : d.name, Total : d.value, [comparisonLabel] : d.comparisonValue}))}
          fileName={i18next.t('Filename.Cdii.evolution', {period, label : currentLabel})}
          Source1={totalCdii ? <Trans
            i18nKey='Cdii.Evolution.source' // optional -> fallbacks to defaults if not provided
            values={{ 
              year : period.slice(4,5) ? `${period.slice(0,4)}-T${period.slice(4,5)}` : period,
              count : totalCdii?.toLocaleString()
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'white', background : theme.colors.cdii.primary.border}} />}}
          /> : undefined}
        >
        {
          sumevolutionData.length && !loadingEvolution ?
          <ContainerAreaChart>
            <GenericLineChart
              key='sum' 
              data={sumevolutionData}
              isComparing={!!comparison.selected}
              color={theme.colors.cdii.primary.medium}
            />
            {
              comparison.selected ? 
              <div style={{marginLeft : '10%'}} >
                <LegendGraph 
                  color={theme.colors.cdii.primary.medium}
                  comparisonLabel={comparisonLabel}
                  territoryLabel={currentLabel}
                />
              </div>
                : null
            }
          </ContainerAreaChart>
          :
          <Loader size={100} color={theme.colors.cdii.primary.border} isLoading={loadingEvolution} />
        }
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='cdiiNumber'
          color={theme.colors.cdii.secondary.border}
          title={t('Cdii.NumbWorkers.title')}
          Source1={sumData.length ? <Trans
          i18nKey={territory.slice(0,1) !== 'i' ? "Cdii.NumbWorkers.source" : "Cdii.NumbWorkers.sourceInterco"} // optional -> fallbacks to defaults if not provided
            values={{ 
              count : _.maxBy(sumData, (o) => o.value)?.value?.toLocaleString(), 
              territory : _.maxBy(sumData, (o) => o.value)?.label,
              interco : currentLabel
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'white', background : theme.colors.cdii.secondary.border}} />}}
          /> : undefined}
          label={totalCdii > 0 ? t('Cdii.NumbWorkers.label') : undefined}
          total={totalCdii}
          Icon={() => <IconDemographic width={"4rem"} height={"4rem"} color={theme.colors.cdii.secondary.border} />}
          comparisonTotal={comparisonTotalCdii}
          isComparing={!!comparison.selected}
          data={sumData.map((d: any) => ({Code : d.name, Territoire : d.label, Total : d.value}))}
          fileName={i18next.t('Filename.Cdii.workers', {period, label : currentLabel})}
        >
         {
            sumData.length && !loadingSum ? 
            <ContainerMap 
              data={sumData} 
              dataView={sumCut}
              colors={{
                dark : theme.colors.cdii.secondary.dark, 
                medium : theme.colors.cdii.secondary.medium, 
                light : theme.colors.cdii.secondary.light, 
                lighter : theme.colors.cdii.secondary.lighter,
                disabled : theme.colors.cdii.secondary.disabled
              }}
              onClickSwitchButton={(value: MapTypes) => {
                if(territory.slice(0,1) === 'n'){
                  setSumCut(`${territory.slice(0,1)}${value}` as any);
                } else if(territory.slice(0,1) === 'r') {
                  setSumCut(`${territory.slice(0,1)}${value}${territory.split('r')[1]}` as any);
                } else {
                  setSumCut(`${territory}${value}` as any);
                }
              }}
              selectedMap={sumCut} 
              dataType={territory.slice(0,1) as 'n' | 'r' | 'd' | 'i'} 
              color={theme.colors.cdii.secondary.medium} 
              // isComparing={!!comparison.selected}  
              // territoryLabel={currentLabel}
              // comparisonLabel={comparisonLabel}          
            /> :
            <Loader size={100} color={theme.colors.cdii.primary.medium} isLoading={loadingSum} />
          } 
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='cdiiGenre'
          color={theme.colors.cdii.primary.border}
          title={t('Cdii.Sexe.title')}
          Source1={groupsexeData?.men || groupsexeData?.women ? <Trans
            i18nKey='Cdii.Sexe.source' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : (groupsexeData?.men || 0) > (groupsexeData?.women || 0) ? Math.round((groupsexeData?.men || 0)/ ((groupsexeData?.men || 0) + (groupsexeData?.women || 0)) * 1000)/10 : Math.round((groupsexeData?.women || 0)/ ((groupsexeData?.men || 0) + (groupsexeData?.women || 0)) * 1000)/10,
              sexe : (groupsexeData?.men || 0) > (groupsexeData?.women || 0) ? "Hommes" : "Femmes"
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'white', background : theme.colors.cdii.primary.border}} />}}
          /> : undefined}
          label={t('Cdii.Sexe.label')}
          data={
            !comparison.selected ? [{Hommes : groupsexeData?.men, Femmes : groupsexeData?.women, "Non renseigné" : groupsexeData?.nr}] :
            [{Hommes : groupsexeData?.men, Femmes : groupsexeData?.women, "Non renseigné" : groupsexeData?.nr, [`${comparisonLabel} hommes`] : groupsexeData?.['comparisonmen'], [`${comparisonLabel} femmes`] : groupsexeData?.['comparisonwomen']}]
          }
          fileName={i18next.t('Filename.Cdii.sexe', {period, label : currentLabel})}
        >
          {
           !loadingGroupSexe && (groupsexeData?.['men'] || groupsexeData?.['women'] || groupsexeData?.['nr'])  ?
            <ContainerQuarterGraph>
              <CompareTwoQuarters 
                firstQuarterText={t('Chart.Quarter.first')}
                firstQuarterNumber={groupsexeData?.['women'] || 0}
                firstNationalQuarterNumber={groupsexeData?.['comparisonwomen'] ||  0}
                secondQuarterText={t('Chart.Quarter.second')}
                secondQuarterNumber={groupsexeData?.['men'] || 0}
                secondNationalQuarterNumber={groupsexeData?.['comparisonmen'] || 0}
                comparisonText={comparisonLabel === 'Comparaison nationale' ? 'National' : comparisonLabel}
                firstColor={theme.colors.cdii.ternary}
                secondColor={theme.colors.cdii.primary.medium}
                workContract={t('Chart.Cdii.label')}
                isComparing={!!comparison.selected}
              />
            </ContainerQuarterGraph> :
            <Loader height={33.5} size={100} color={theme.colors.cdii.primary.border} isLoading={loadingGroupSexe} />
          }
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='cdiiAge'
          color={theme.colors.cdii.secondary.border}
          title={t('Cdii.Age.title')}
          data={groupageData.map((d: any) => !comparison.selected ? ({Tranches : d.description, Total : d.value}) : ({Tranches : d.description, Total : d.value, [comparisonLabel] : d.comparisonValue}))}
          fileName={i18next.t('Filename.Cdii.age', {period, label : currentLabel})}
          Source1={groupageData.length ? <Trans
            i18nKey='Cdii.Age.source' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : _.maxBy(groupageData, (o) => o.value)?.percentage,
              groupAge : _.maxBy(groupageData, (o) => o.value)?.description
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'white', background : theme.colors.cdii.secondary.border}} />}}
          /> : undefined}
        >
          {
            groupageData.length > 0 && !loadingGroupAge ?
            <ContainerGraphSmallBar>
              <ListBarIndicators 
                data={groupageData}
                color={theme.colors.cdii.secondary.border}
                size={'small'}
                label={t('Chart.Cdii.label')}
                isComparing={!!comparison.selected}
                width={700}
                labelWidth={110}
              />
              {
                comparison.selected ? 
                <LegendGraph 
                  color={theme.colors.cdii.secondary.medium}
                  comparisonLabel={comparisonLabel}
                  territoryLabel={currentLabel}
                /> : null
              }
            </ContainerGraphSmallBar> :
            <Loader height={35} size={100} color={theme.colors.cdii.secondary.border} isLoading={loadingGroupAge} />
        }
        </GenericCard>
      </ContainerCard>
    </GenericPage>
  )
}

export default Cdii;

const ContainerCard = styled.div`
  margin-bottom : 4.5rem;
`;

const ContainerGraphBigBar = styled.div`
  height : 40rem;
  margin-top : 4.5rem;
  padding-left : 8.35rem;


`;

const ContainerGraphSmallBar = styled.div`
  height : 30rem;
  margin-top : 4.5rem;
  padding-left : 8.35rem;
  display : flex;
  flex-direction : row;

  @media screen and (max-width : ${sizeMobile}px) {
    padding-left : 0rem;
  }
`;

const ContainerQuarterGraph = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: center;
  width          : 100%;
  padding-top    : 3.3rem;
  padding-bottom : 2rem;
`;

const ContainerAreaChart = styled.div`
  width           : 70%;
  padding-left    : 6.7rem;
  display         : flex;
  flex-direction  : row;
  align-items     : center;
  justify-content : space-between;
`;