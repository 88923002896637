import React from 'react';
import styled from 'styled-components';

import { IconHome } from '../../assets/svg-images/IconHome';
import { IconInfo } from '../../assets/svg-images/IconInfo';
import { IconMention } from '../../assets/svg-images/IconMention';

import { theme } from '../../assets/theme';
import useWindowSize from '../../hooks/useWindowSize';
import { t } from '../../i18n';
import { HOMEPAGE, MENTIONS, METHODO } from '../../routes/paths';
import { sizeMobile } from '../../utils/constants';
import { calcWidthMobileRem, calcRem, calcHeightMobileRem } from '../../utils/dimension';

interface FitMenuProps {
  selected: string;
  onClick: (value: string) => void;
}

const FitMenu: React.FC<FitMenuProps> = ({selected, onClick}) => {
  const size = useWindowSize();
  return (
    <Container>
      <Item 
        selected={selected === 'accueil'} height={`${calcRem(2.5)}rem`}
        onClick={() => onClick(HOMEPAGE)}
      >
        <IconHome color={theme.colors.neutral}  />
        <Name>{t('Homepage.FitMenu.home')}</Name>
      </Item>
      <Item 
        selected={selected === 'methodo'}
        onClick={() => onClick(METHODO)}
      >
        {size?.width && <IconInfo color={theme.colors.neutral} height={size?.width < sizeMobile ? `${calcRem(3.5)}rem` : `${calcRem(2.5)}rem`} />}
        <Name>{t('Homepage.FitMenu.methodo')}</Name>
      </Item>
      <Item 
        selected={selected === 'mentions'}
        onClick={() => onClick(MENTIONS)}
      >
       {size?.width && <IconMention color={theme.colors.neutral} height={size?.width < sizeMobile ? `${calcRem(3.5)}rem`: `${calcRem(2.5)}rem`} />}
        <Name>{t('Homepage.FitMenu.mentions')}</Name>
      </Item>
    </Container>
  )
}

export default FitMenu;

const Container = styled.div`
  height           : 100%;
  width            : 100%;
  display          : flex;
  flex-direction   : column;
  background-color : ${({theme})=> theme.colors.menu};
  padding-top      : ${calcRem(14.8)}rem; 

  @media screen and (max-width : ${sizeMobile}px) {
    flex-direction   : row;
    padding-top      : ${calcRem(0)}rem; 
    height           : ${calcHeightMobileRem(6.4)}rem;
  }
`;

const Item = styled.div<any>`
  height           : ${calcRem(8)}rem; 
  background-color : ${({selected}) => selected ? "#3F3F3F" : "#2A2A2A"};
  border-right     : ${({selected}) => selected ? `4px solid ${theme.colors.primary}` : `4px solid #3F3F3F`}; 
  display          : flex;
  flex-direction   : column;
  justify-content  : center;
  align-items      : center; 
  cursor           : pointer;

  @media screen and (max-width : ${sizeMobile}px) {
    flex              : 1;
    height            : 100%;
    border-bottom     : ${({selected}) => selected ? `4px solid ${theme.colors.primary}` : `4px solid #3F3F3F`}; 
    border-right      : none;
  }
`;

const Name = styled.div`
  font-family : work-sans-regular;
  color       : ${({theme}) => theme.colors.neutral};
  font-size   : 1rem;
  margin-top  : 0.5rem;
  max-width   : ${calcRem(6.7)}rem;
  text-align  : center;

  @media screen and (max-width : ${sizeMobile}px) {
    display : none;
  }
`;