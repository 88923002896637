import defaultState from '../defaultState';

const datasetsReducer = (state = defaultState.datasets, action : Action) => {
  switch (action.type) {
    case '@@RESET':
    case 'LOGIN_FAILED':
      return defaultState.datasets;
        
    case 'DATASETS_LOADED':
      return {
        ...state,
        data : action.data
      }

    case 'SELECT_DATASET':
      return {
        ...state,
        selected : action.data
      }

    default: return state;

  };
}

interface Action {
  type : string;
  value : [] | string;
  data : [];
}

export default datasetsReducer;