import React, { useState, useEffect, useRef, useMemo, memo, Suspense, StyleHTMLAttributes } from 'react';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';
import Downshift from 'downshift';
import Highlighter from 'react-highlight-words';

import useOutsideClick from '../../hooks/useOutsideClick';

import Input from '../atoms/Input';
import Checkbox from '../atoms/Checkbox';
import Icon from '../atoms/Icon';
import FilterButton from '../atoms/FilterButton';
import DropdownFilterInput from '../atoms/DropDownFilterInput';


import { theme } from '../../assets/theme';

import { t } from '../../i18n';

/**
  Dropdown
  * @param {string} label above of dropDown
  * @param {string} placeholder text in dropDown if no selected value
  * @param {Item[]} items dropdown list
  * @param {string} selected value 
  * @param {()=>void} onSelect function when select value
  * @param {boolean} disabled dropdown
  * @param {boolean} showSearchBar include search bar in list
  * @param {string} searchBarPlaceholder placeholder text of the searchbar
  * @param {boolean} showFilters add button filter on the right side of dropdown
  * @param {boolean} showTotal show the total of the list on the right corner 
  * @param {React.FC} ItemComponent personalize item showed in list
  * @param {Item[]} filters list of items in filter button
  * @param {string[]} selectedFilters selected filters
  * @param {()=>void} onFilterSelect function when valid selection of filters
**/

const Dropdown: React.FC<DropdownProps> = ({
  label,
  placeholder,
  items, 
  selected, 
  onSelect, 
  disabled=false, 
  disabledPlaceholder,
  showSearchBar=false,
  searchBarPlaceholder='',
  showFilters,
  showTotal,
  ItemComponent,
  filters = [],
  selectedFilters = [],
  defaultValue,
  hideClearButton,
  onFilterSelect = () => {},
  colors,
  style,
  ...props
}) => {

  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);
  const [selectedFiltersTemp, setSelectedFiltersTemp] = useState<any>({}); // filters selected temporary
  const [itemSelected, setItemSelected] = useState<any>({});

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => {
    setSelectedFiltersTemp({});
    setFilterIsOpen(false);
  });

  useEffect(() => {
    const items : any = {};
    selectedFilters?.forEach((item: string) => {
      items[item] = item;
    })
    setSelectedFiltersTemp({...selectedFiltersTemp,  ...items });
  }, [filterIsOpen])

  useEffect(() => {
    if(selected){
      const [item] = items.filter(({value}) => value === selected)
      setItemSelected(item);
    }
    else {
      const [item] = items.filter(({value}) => value === defaultValue)
      setItemSelected(item);
    }
  }, [selected])

  useEffect(() => {
    if(defaultValue && !selected){
      const [item] = items.filter(({value}) => value === defaultValue)
      setItemSelected(item);
    }
  }, [defaultValue])

  const handleClickFilterButton = () => {
    if(filterIsOpen){
      setSelectedFiltersTemp({});
    }
    setFilterIsOpen(!filterIsOpen);
  }

  const handleSelectItem = ({value}: Item) => {
    onSelect(value);
  }

  const handleClearSelection = () => {
    onSelect();
  }

  const handleFilterSelect = ({value}: Item) => {
    setSelectedFiltersTemp({...selectedFiltersTemp, [value] : selectedFiltersTemp[value] ? null : value});
  }

  const handleConfirmFilterSelected = () => {
    setFilterIsOpen(false);
    const itemsFiltered = Object.values(selectedFiltersTemp).filter((value) => value);
    const items : any = itemsFiltered?.length ? itemsFiltered : [];
    onFilterSelect(items);
    setSelectedFiltersTemp({});
  }

  const handleClearFilterSelectedTemp = () => {
    setFilterIsOpen(false);
    setSelectedFiltersTemp({});
    onFilterSelect([]);
  }


  const ItemRenderer = ({ data, index, style } : any) => {
    const { filteredItems, inputValue, itemProps} = data;
    const [item, setItem] = useState<any>();

    useEffect(() => {
      const item = filteredItems[index];
      setItem(item);
    }, [data]);

    const props = item ? {...itemProps(item, index)} : {}

    return (
      <>
      {
        ItemComponent ? 
        <ItemComponent 
          key={item?.value}
          item={item}
          last={index === filteredItems?.length-1}
          first={index === 0}
          {...props}
        /> :
      <CustomizedItem
        key={item?.value}
        style={{...style, width : '92%'}}
        {...props}
      >
        <Highlighter
          highlightClassName='highLight'
          searchWords={[inputValue || '']}
          autoEscape={true}
          textToHighlight={item?.label || ''}
          activeClassName='active-highlight'
        />
      </CustomizedItem>
  }
  </>
    )
  }

  return (
    <Container ref={wrapperRef} {...props} style={style}>
      <Downshift
        itemToString={item => (item ? item.label : '')}
        onSelect={(item, state) => { /* if(!filterIsOpen)handleSelectItem(item); */ state.setState({inputValue : ''})}}
      >
          {({
            getLabelProps,
            getInputProps,
            getToggleButtonProps,
            getMenuProps,
            getItemProps,
            isOpen,
            clearSelection,
            selectedItem,
            inputValue,
            highlightedIndex,
            setState
          }) => {
            const filteredItems = inputValue ? items?.filter(({label}) => {
              let input;
              if(inputValue === '+60 ans'){
                input = '60'
              } else {
                input = inputValue
              }
              return label?.toLowerCase()?.match(input?.toLowerCase())
            }) : items;
            return (
            <div style={{display : 'flex', flexDirection : 'column'}}>
              {
                label && 
                <Header>
                  <Label>
                    {label}
                  </Label>
                  {
                    showTotal && !disabled && items?.length > 0 &&
                      <Total>
                        ({items.length})
                      </Total> 
                  }
                </Header>
              }
              <div style={{position : 'relative'}}>
                <ContainerButton>
                    <MainButton 
                      isOpen={isOpen} 
                      selected={selected?.trim() !== '' && selected !== undefined || defaultValue?.trim() !== '' && defaultValue !== undefined}
                      disabled={disabled}
                      colors={colors}
                    >
                      {
                        selected && !hideClearButton && !disabled &&
                        <IconClearButton onClick={(disabled ? ()=>{} : () => {handleClearSelection();})}>
                          <Icon icon='close-circle' width={12} height={12} color={colors ? colors.pictos : "rgba(255,255,255,0.55)"} />
                        </IconClearButton>
                      }
                      {
                        ItemComponent && itemSelected ? 
                        <ItemComponent cantBeHovered item={itemSelected} {...getToggleButtonProps({ onClick : () => {setFilterIsOpen(false)}})}/> : 
                        <ItemSelected
                          className='item' 
                          selected={selected?.trim() !== '' && selected !== undefined}
                          isDefault={defaultValue === selected && selected || !!defaultValue && !selected} 
                          disabled={disabled}
                          {...getToggleButtonProps({ onClick : () => {setFilterIsOpen(false); setState({inputValue : ''})}})}
                        >
                          <div>{disabledPlaceholder && disabled ? disabledPlaceholder : selected ? itemSelected?.label : defaultValue ? itemSelected?.label : placeholder}</div>
                        <IconButton onClick={(disabled || !selected || hideClearButton ? ()=>{} : () => {handleClearSelection();})}>
                          {
                            disabled ?
                              <Icon icon='arrow-down' width={9} height={9} color={theme.colors.border} /> :
                            defaultValue && !selected ? 
                              <Icon icon={'arrow-right'} width={9} height={9} color={ colors ? colors.pictos : theme.colors.neutral} /> :
                            selected && hideClearButton ?
                            <Icon icon={isOpen ? 'arrow-up' : 'arrow-down'} width={9} height={9} color={ colors ? colors.pictos : theme.colors.primary} /> :
                            selected ?
                            <Icon icon='arrow-right' width={9} height={9} color={ colors ? colors.pictos : theme.colors.neutral} /> :
                              <Icon icon={isOpen ? 'arrow-up' : 'arrow-down'} width={9} height={9} color={ colors ? colors.pictos : theme.colors.primary} /> 
                          }
                        </IconButton>
                        </ItemSelected>
                      }
                    </MainButton>
                  {
                    showFilters && !disabled && !selected &&
                    <FilterButton
                      onClick={() => { handleClickFilterButton(); setState({isOpen : false})}}
                      selected={filterIsOpen || selectedFilters?.length > 0}
                      color={theme.colors.primary}
                    >
                      <Icon 
                        icon="filter" 
                        color={filterIsOpen || selectedFilters?.length > 0 ? theme.colors.neutral : theme.colors.primary}
                        width={17}
                        height={20}
                      /> 
                    </FilterButton>
                  }
                  </ContainerButton>
                  {
                    isOpen &&
                    <Menu colors={colors} >
                      {
                        showSearchBar &&
                        <DropdownFilterInput
                          placeholder={searchBarPlaceholder}
                          Icon={() => <InputIcon src={'../assets/images/search.png'} />}
                          type='text'
                          disabled={!showSearchBar}
                          {...getInputProps({isOpen})}
                        >
                          <Icon 
                            icon="search" 
                            color={theme.colors.primary}
                            width={14}
                            height={14}
                          />
                        </DropdownFilterInput>
                      }
                      <FixedSizeList
                        itemCount={filteredItems.length}
                        height={175}
                        width='100%'
                        itemSize={50}
                        style={{overflowX : 'clip', margin : '2px 0'}}
                        itemData={{
                            filteredItems, 
                            inputValue, 
                            itemProps : (item : any, index : any) => getItemProps({
                            onClick : () =>handleSelectItem(item),
                            item,
                            index,
                          })
                        }}
                      >
                        {ItemRenderer}
                      </FixedSizeList>
                    </Menu>
                  }
                  
                  {
                  filterIsOpen &&
                  <div style={{position : 'absolute', width : '100%', zIndex : 1}}>
                    <MenuFilter {...getMenuProps()}>
                      <List>
                        {
                          filters?.map((item: Item, index: number) => (
                            <ItemWithCheckbox 
                            key={item.value}
                            {...getItemProps({
                                onClick : () =>handleFilterSelect(item),
                                item,
                                index,
                              })}
                            >
                              <Checkbox 
                                {...getItemProps({
                                  onClick : () =>handleFilterSelect(item),
                                  item,
                                  index,
                                })} 
                                name='filter' 
                                selected={selectedFiltersTemp[item.value] ? true : false} 
                                value={item.label} 
                              />
                              <CheckboxLabel>
                                {item.label}
                              </CheckboxLabel>
                            </ItemWithCheckbox>
                          ))
                        }
                      </List>
                    </MenuFilter>
                    <Footer>
                      <Left onClick={handleClearFilterSelectedTemp}>{t('Dropdown.clear')}</Left>
                      <Right onClick={handleConfirmFilterSelected}>{t('Dropdown.validate')}</Right>
                    </Footer>
                  </div>
                }
              </div>
            </div>
          )}}
        </Downshift>
      </Container>
    )
  }

export default Dropdown;

export interface DropdownProps {
  label? : string; // Dropdown title
  placeholder : string; // Dropdown button placeholder
  items : Item[] // List of items that can be selected
  selected ?: string; // Value of the selected item
  defaultValue? : string;
  onSelect : (value ?: string) => void // Callback used when user selects an item
  disabled ?: boolean;
  disabledPlaceholder? : string;

  showSearchBar ?: boolean;
  searchBarPlaceholder ?: string;

  showFilters ?: boolean;
  filters ?: Item[];
  selectedFilters ?: string[]; // Value of selected filters
  onFilterSelect ?: (value ?: string[]) => void

  showTotal ?: boolean;

  ItemComponent ?: React.FC<any>;

  hideClearButton? : boolean;
  colors? : Colors;
  style?: React.CSSProperties;
}

interface Colors {
  button: string;
  pictos: string;
}

export interface Filter {
  value: string;
  label: string;
}

export interface Item {
  value: string;
  label: string;
  color? : string;
}

interface CustomizedItemProps {
  first : boolean;
  last  : boolean;
}

interface ElementProps {
  selected? : boolean;
  isOpen?   : boolean;
  disabled? : boolean;
  isDefault?: boolean;
  colors?: Colors;
}


const Container = styled.div`
  width : 100%;

  .highLight {
    color            : inherit;
    font-weight      : bold;
    background-color : transparent;

    :hover {
      color            : ${({theme}) => theme.colors.primary};
    }
  }
  .active-highlight {
    color            : ${({theme}) => theme.colors.primary};
  }
`;

const Header = styled.div`
  width           : 100%;
  display         : flex;
  flex-direction  : row;
  align-items     : center;
  justify-content : space-between;
  margin-bottom   : 7px;
`;

const ContainerButton = styled.div`
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
`;

const MainButton = styled.div<ElementProps>`
  width            : 100%;
  height           : 3.8rem;
  display          : flex;
  flex-direction   : row;
  align-items      : center;
  justify-content  : space-between;
  border-radius    : 0px;
  background-color : ${({disabled, theme, selected, colors}) => disabled ? theme.colors.dropdownDisabled : colors ? colors.button : selected ? theme.colors.primary : theme.colors.lightBackground};
  cursor           : pointer;
  outline-color    : ${({theme}) => theme.colors.primary};
  outline-width    : 1px;
  position         : relative;
  `;
  // border           : ${({isOpen, selected, theme, disabled}) => `1px solid ${isOpen || selected && !disabled ? theme.colors.selected : theme.colors.border}`};

const IconButton = styled.div`
  height      : 100%;
  display     : flex;
  align-items : center;
`;

const IconClearButton = styled.button`
  height        : 100%;
  display       : flex;
  align-items   : center;
  border        : none;
  background    : none;
  outline-color : ${({theme}) => theme.colors.primary};
  outline-width : 1px;
  cursor        : pointer;
  padding-right : 1rem;
`;

const ItemSelected = styled.button<ElementProps>`
  font        : ${({theme}) => theme.fonts.general.dropdown};
  color       : ${({disabled, theme, selected}) => disabled ? theme.colors.lightText : selected ? theme.colors.neutral : theme.colors.lightText};
  margin-left : 0px;
  padding-left : ${({selected, isDefault, disabled}) => isDefault && !selected || disabled ? "15px" : isDefault && selected ? "0px" : selected ? "0px" : "15px"};
  border      : none;
  cursor      : pointer;
  width       : 100%;
  height      : 100%;
  background  : none;
  text-align  : left;
  overflow    : hidden;
  white-space : nowrap;
  text-overflow    : ellipsis;
  display          : flex;
  align-items      : center;
  justify-content  : space-between;
  outline-color    : ${({theme}) => theme.colors.primary};
  outline-width    : 1px;
  ::selection {
    background-color:transparent;
  }
`;

const Label = styled.div`
  font  : ${({theme}) => theme.fonts.general.dropdownLabel};
  color : ${({theme}) => theme.colors.lightText};
`;

const Total = styled.div`
  font  : ${({theme}) => theme.fonts.general.count};
  color : ${({theme}) => theme.colors.lightText};
`;

const InputStyle : any = styled(Input)`
  border-radius : 8px;
  margin        : 9px 9px 0 9px;
  div {
    height        : 30px;
    border-radius : 4px;
    padding-right : 10px;
  }
  input {
    padding-left : 9px;
    caret-color  : ${({theme}) => theme.colors.placeholder};
    color        : ${({theme}) => theme.colors.text} !important;
    font         : ${({theme}) => theme.fonts.general.filterInput};
    margin       : 0;

    ::placeholder {
    color: ${({theme}) => theme.colors.placeholder};
    }

    :focus-visible {
      outline : none;
    }
  }
`;

const Menu = styled.div<ElementProps>`
  max-height       : 233px;
  width            : 100%;
  background-color : ${({theme}) => theme.colors.neutral};
  border           : ${({theme, colors}) => `1px solid ${colors ? colors.button : theme.colors.primary}`};
  border-radius    : 0px;
  box-shadow       : ${({theme}) => theme.shadow.list};
  z-index          : 1;
  position         : absolute;
  padding          : 0;
  margin           : 9px 0 0 0;
`;

const MenuFilter = styled.div`
  max-height       : 200px;
  width            : 100%;
  background-color : ${({theme}) => theme.colors.neutral};
  border           : ${({theme}) => `1px solid ${theme.colors.selected}`};
  border-radius    : 5px 5px 0 0;
  box-shadow       : ${({theme}) => theme.shadow.list};
  z-index          : 1;
  padding          : 0;
  margin           : 9px 0 0 0;
`;

const List = styled.ul`
  padding          : 0;
  margin           : 0;
  background-color : ${({theme}) => theme.colors.neutral};
  overflow-y       : scroll;
  max-height       : 160px;
  border-radius    : 0px;
  margin           : 2px 0;
`;

const CustomizedItem = styled.li`
  height           : 10px;
  display          : flex;
  flex-direction   : row;
  align-items      : center;
  justify-content  : start;
  padding-left     : 15px;
  color            : ${(props) => props?.[`aria-selected`] ? props.theme.colors.selected : props.theme.colors.text}};
  background-color : ${(props) => props?.[`aria-selected`] ? props.theme.colors.border : props.theme.colors.neutral}};
  font             : ${({theme}) => theme.fonts.general.dropdownLabel};
  cursor           : pointer;
  line-height      : 2rem;
  :hover {
    color            : ${({theme}) => theme.colors.selected} !important;
    background-color :  ${({theme}) => theme.colors.border};
  }
`;

const ItemWithCheckbox = styled.li`
  display         : flex;
  flex-direction  : row;
  align-items     : center;
  justify-content : space-between;
  margin          : 5px 0;
  position        : relative;
  color           : ${({theme}) => theme.colors.text};
  font            : ${({theme}) => theme.fonts.general.dropdownLabel};
`;

const CheckboxLabel = styled.div`
  flex   : 1;
  cursor : pointer;
  outline-color : white; 
  ::selection {
    background-color:transparent;
  }
`;

const Footer = styled.div`
  display          : flex;
  flex-direction   : row;
  justify-content  : space-between;
  align-items      : center;
  background-color : ${({theme}) => theme.colors.primary};
  height           : 35px;
  width            : 101%;
  border-radius    : 0 0 5px 5px;
`;

const Left = styled.button`
  color         : ${({theme}) => theme.colors.neutral};
  font          : ${({theme}) => theme.fonts.general.dropdownFooter};
  margin-left   : 15px;
  background    : transparent;
  border        : none;
  cursor        : pointer;
  outline-color : white;
  :hover {
    font  : ${({theme}) => theme.fonts.general.dropdownFooterBold};
  }
`;

const Right = styled.button`
  color         : ${({theme}) => theme.colors.neutral};
  font          : ${({theme}) => theme.fonts.general.dropdownFooter};
  margin-right  : 15px;
  background    : transparent;
  outline-color : white;
  border        : none;
  cursor        : pointer;
  :hover {
    font  : ${({theme}) => theme.fonts.general.dropdownFooterBold};
  }
`;

const InputIcon = styled.img`
  width  : 16px;
  margin : 0 13px;
`;
