import { MapTypes } from "../types";
import { Departements } from "./maps/france/departements";
import { Regions } from "./maps/france/regions";
import { r01d } from "./maps/regions/r01d";
import { r02d } from "./maps/regions/r02d";
import { r03d } from "./maps/regions/r03d";
import { r04d } from "./maps/regions/r04d";
import { r06d } from "./maps/regions/r06";
import { r11d } from "./maps/regions/r11d";
import { r24d } from "./maps/regions/r24d";
import { r27d } from "./maps/regions/r27d";
import { r28d } from "./maps/regions/r28d";
import { r32d } from "./maps/regions/r32d";
import { r44d } from "./maps/regions/r44d";
import { r52d } from "./maps/regions/r52d";
import { r53d } from "./maps/regions/r53d";
import { r75d } from "./maps/regions/r75d";
import { r76d } from "./maps/regions/r76d";
import { r84d } from "./maps/regions/r84d";
import { r93d } from "./maps/regions/r93d";
import { r94d } from "./maps/regions/r94d";

export const LOGIN_REQUESTED  = 'LOGIN_REQUESTED';
export const LOGIN_FAILED     = 'LOGIN_FAILED';
export const LOGIN_SUCCEEDED  = 'LOGIN_SUCCEEDED';
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';

export const LOADING_BEGIN   = 'LOADING_BEGIN';
export const LOADING_END     = 'LOADING_END';

export const LOAD_FILTER_REQUESTED = 'LOAD_FILTER_REQUESTED';
export const LOAD_FILTER_SUCCEEDED = 'LOAD_FILTER_SUCCEEDED';

export const sizeMobile = 1000;

const SUM_EVOLUTION = 'sumevolution';
const SUM = 'sum';
const GROUP_SEXE = 'groupsexe';
const GROUP_HOURS = 'grouphours';
const GROUP_AGE = 'groupage';
const HOURS = 'hours';

export const CTT_FILES = [SUM_EVOLUTION, GROUP_HOURS, GROUP_SEXE, GROUP_AGE];
export const CDII_FILES = [SUM_EVOLUTION, GROUP_SEXE, GROUP_AGE];

export const labelGroupHours = {
  '-1000000' : '< 50 heures',
  '50' : 'de 50 à < 150 heures',
  '150' : 'de 150 à < 400 heures',
  '400' : 'de 400 à < 800 heures',
  '800' : 'plus de 800 heures'
}

export const labelGroupAges = {
  '0' : '-25 ans',
  '1' : '25-45 ans',
  '2' : '46-60 ans',
  '3' : '+60 ans'
}

export const groupsAge = [{
  label : '-25 ans', value : '0'
}, {
  label : '25-45 ans', value : '1'
}, {
  label : '46-60 ans', value : '2'
}, {
  label : '+60 ans', value : '3'
}]

export const labelCompanySizes = {
  '0' : 'Moins de 10 salariés',
  '1' : '10 à 49 salariés',
  '2' : '50 à 249 salariés',
  '3' : '250 à 1999 salariés',
  '4' : '2000 salariés et plus'
}

export const labelSource = {
  'regions' : 'dans la région',
  'departements' : 'dans le département',
  'intercos' : "dans l'intercommunalité"
}

export const maps: any = {
  'nr' : Regions,
  'nd' : Departements,
  'r' : [],
  'd' : [],
  'i' : [],
  'ri' : [],
  'di' : [],
  'rd01' : r01d,
  'rd02' : r02d,
  'rd03' : r03d,
  'rd04' : r04d,
  'rd06' : r06d,
  'rd11' : r11d,
  'rd24' : r24d,
  'rd27' : r27d,
  'rd28' : r28d,
  'rd32' : r32d,
  'rd44' : r44d,
  'rd52' : r52d,
  'rd53' : r53d,
  'rd75' : r75d,
  'rd76' : r76d,
  'rd84' : r84d,
  'rd93' : r93d,
  'rd94' : r94d,
}

export const viewboxMap: any = {
  'nr' : "0 0 697.78 564",
  'nd' : "0 0 697.78 564",
  'r' : "0 0 1481 902",
  'd' : "",
  'i' : "",
  'ri' : "",
  'di' : "",
  'rd01' : "0 0 467 368",
  'rd02' : "0 0 337 402",
  'rd03' : "0 0 376 426",
  'rd04' : "0 0 402 359",
  'rd06' : "0 0 246 328",
  'rd11' : "0 0 1266 1025",
  'rd24' : "0 0 2126 2397",
  'rd27' : "0 0 1340 1018",
  'rd28' : "0 0 992 825",
  'rd32' : "0 0 1756 2052",
  'rd44' : "0 0 2900 2444",
  'rd52' : "0 0 2163 2063",
  'rd53' : "0 0 1481 902",
  'rd75' : "0 0 979 1328",
  'rd76' : "0 0 1832 1239",
  'rd84' : "0 0 2299 1793",
  'rd93' : "0 0 2276 1958",
  'rd94' : "0 0 701 1508",
}

export const statisticSecretValue = 0;