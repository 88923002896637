import * as d3 from 'd3';
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Tooltip from '../atoms/Tooltip';
import _ from 'lodash';
import { sizeMobile } from '../../utils/constants';
interface BubbleProps {
  data: {key: string, value: number}[];
  id: string;
  total: number;
  label?: (d: any) => void;
  value?: (d: any) => void;
  group?: (d: any) => void;
  title?: (d: any) => void;
  link?: (d: any) => void;
  width?: number;
  height?: number; 
  linkTarget?: '_blank';
  padding?: number;
  margin?: number; 
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number; 
  marginLeft?: number; 
  groups?: any[];
  colors?: string[]; 
  fill?: string;
  fillOpacity?: number;
  stroke?: string; 
  strokeWidth?: number; 
  strokeOpacity?: number;
  selectedColor?: string
}

export const BubbleChart: React.FC<BubbleProps> = ({
  data,
  label= d => [...d.label.split(/(?=[" "])/g), /* d.value.toLocaleString("en") */].join("\n"),
  value= d => d.value,
  group= d => d.key.split(".")[1],
  title= d => `${d.key}\n${d.value.toLocaleString("en")}`,
  link= d => ``,
  width= 600,
  linkTarget="_blank",
  height = width, // outer height, in pixels
  padding = 3, // padding between circles
  margin = 1, // default margins
  marginTop = margin, // top margin, in pixels
  marginRight = margin, // right margin, in pixels
  marginBottom = margin, // bottom margin, in pixels
  marginLeft = margin, // left margin, in pixels
  groups, // array of group names (the domain of the color scale)
  colors = ["#F19000"], // an array of colors (for groups)
  selectedColor = "#D07200", 
  fill = "#ccc", // a static fill color, if no group channel is specified
  fillOpacity = 1, // the fill opacity of the bubbles
  stroke = "", // a static stroke around the bubbles
  strokeWidth = 0, // the stroke width around the bubbles, if any
  strokeOpacity = 0, // the stroke opacity around the bubbles, if any
  id,
  total
}: BubbleProps) => {
  // Compute the values.

  
  const dataSlice = _.orderBy(data, (d) => typeof d.value === "number" ? d.value : 0, ["desc"]).map((d) => d.value).slice(0,4);
  let x;
  let y;
  const D = d3.map(data, (d: any) => d.key);
  const V: any = d3.map(data, (d: any) => d.value);
  const G: any = group == null ? null : d3.map(data, group);
  const I: any = d3.range(V.length).filter((i: any) => V[i] > 0);
  const Y: any = d3.map(data, (d: any) => d.label.split(/(?=[" "])/g));
  // Unique the groups.
  if (G && groups === undefined) groups = I.map((i: any) => G[i]);
  const myGroups: any = G && new d3.InternSet(groups);

  // Construct scales.
  const color = G && d3.scaleOrdinal(myGroups, colors);

  // Compute labels and titles.
  const L: any = label == null ? null : d3.map(data, label);
  const T: any = title === undefined ? L : title == null ? null : d3.map(data, title);
  // Compute layout: create a 1-deep hierarchy, and pack it.
  const root = d3.pack()
      .size([width - marginLeft - marginRight, height - marginTop - marginBottom])
      .padding(padding)
    (d3.hierarchy({children: I})
      .sum((i: any) => V[i]));

  const svg = d3/* .select(`#${id}`) */.create("svg")
  .on("mouseleave", (d: any) => {setTooltipData({})})
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-marginLeft, -marginTop, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .attr("fill", "currentColor")
      .attr("font-size", 10)
      .attr("font-family", "sans-serif")
      .attr("text-anchor", "middle");

  const leaf = svg.selectAll("a")
    .data(root.leaves())
    .join("a")
    .attr("transform", (d: any) => `translate(${d.x},${d.y})`)
    .on("mouseover", (d: any, tooltipData: any) => {setTooltipData({...tooltipData, ...d, label : L[tooltipData.data]});});
    // .on("mouseleave", () => {});

      
  leaf.append("circle")
      .attr("class", "circle")
      // .attr("stroke", stroke)
      // .attr("stroke-width", strokeWidth)
      // .attr("stroke-opacity", strokeOpacity)
      .attr("fill", G ? (d: any) => color(G[d.data]) : fill == null ? "none" : fill)
      .attr("fill-opacity", fillOpacity)
      .attr("r", (d: any) => `${d.r}`)
      

  // if (T) leaf.append("title")
  //     .text((d: any) => `${T[d.data]}`)
  //     .attr("style", "fill: white")

  if (L) {
    // A unique identifier for clip paths (to avoid conflicts).
    const uid = `O-${Math.random().toString(16).slice(2)}`;

    leaf.append("clipPath")
        .attr("id", (d: any) => `${uid}-clip-${d.data}`)
        .append("circle")
        .attr("r", (d: any) => `${d.r}`);

    leaf.append("text")
      .selectAll("tspan")
      .data((d: any) => { 
        return L[d.data] ? 
        [...L?.[d?.data]?.split(/\n/g)?.slice(0,5).map((l: any) => ({label : l, canShow : _.includes(dataSlice, d.value), r : d.r})), {label : "...", canShow : L?.[d?.data]?.split(/\n/g)?.slice(0,5).length < L?.[d?.data].length && _.includes(dataSlice, d.value), r : d.r}] : []
      })
      .join("tspan")
        .attr("x", 0)
        .attr("y", (d: any, i: any, D: any) => `${i - D.length / 2 + 0.85}em`)
        .attr("fill-opacity", (d: any, i: any, D: any) => i === D.length - 1 ? 1 : null)
        .attr("style", (d) => {return `font-size: ${d.r/100*2 > 1.5 ? 1.5 : d.r/100*2}rem; color : white; font-family : work-sans-semiBold`})
        .text((d: any) => {return d.canShow ? d.label : ''}  /* V[d.data] > V[4] ? `${L[d.data].split(/\n/g)}` : "" */ );
  }

  const svgRef: any = useRef(null);
  const svgElement = Object.assign(svg.node() || {}, {scales: {color}});

  useEffect(()=>{
    if(svgRef?.current){
      
      svgRef?.current?.appendChild(svgElement)
    }
    return () => svgRef?.current?.removeChild(svgElement) 
  }, [data]);
 
  const [tooltipData, setTooltipData] = useState<any>({});
  const labelData : any = leaf;
  return (
    <Container id={id} ref={svgRef} color={selectedColor}>
      {tooltipData.x && tooltipData.y && 
        <ContainerTooltip onMouseOver={() => setTooltipData(tooltipData)} x={tooltipData.x} y={tooltipData.y}>
          <Tooltip
            label={tooltipData.label} 
            total={tooltipData.value} 
            percentage={(Math.floor((tooltipData.value/ total * 10000))/100).toString()} 
            color={selectedColor} 
          />
        </ContainerTooltip>}
      {/* {
        labelData._groups[0].slice(0,4).map((d: any) => {
          const {x, y, r, data} = d.__data__;
          return (
            <Label x={x} y={y} r={r} onMouseOver={() => setTooltipData({...d.__data__, label : D[data]})} >{D[data]}</Label>
          )
        })
      } */}
    </Container>
  )
}

const Container = styled.div`
  width : 100%;
  position : relative;
  max-width : 53rem;
  margin-left : 12rem;
  .circle:hover {
    fill: ${({color}) => color};
  }
  @media screen and (max-width : ${sizeMobile}px) {
    margin-left : 0;
  }
`;

const ContainerTooltip = styled.div<any>`
  left : ${({x}) => `${x/10*0.88}rem`};
  top : ${({y}) => `${y/10*0.85}rem`};
  z-index          : 1;
  position         : absolute;
`;

const Label = styled.div<any>`
  position       : absolute;
  left           : ${({x}) => `${x/10*0.47}rem`};
  top            : ${({y}) => `${y/10*0.9}rem`};
  width          : ${({r}) => `${r/7}rem`};
  height         : ${({r}) => `${r/10}rem`};
  font-family    : work-sans-semiBold;
  font-size      : ${({r}) => `${r*1.4/50}rem`};
  text-overflow  : ellipsis;
  overflow       : hidden;
  color          : ${({theme}) => theme.colors.lightText};
  text-align     : center;
`;