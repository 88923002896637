import { Routes, Route, Navigate } from 'react-router-dom';

import CttDemographicsData from '../pages/CttDemographicsData';
import CttJobs from '../pages/CttJobs';
import CttSectors from '../pages/CttSectors';
import Cdii from '../pages/Cdii';
import Homepage from '../pages/Homepage';
import MentionsPage from '../pages/MentionsPage';
import MethodoPage from '../pages/MethodoPage';

import { CTT_DATA, CTT_JOBS, CTT_SECTORS, CDII, HOMEPAGE, METHODO, MENTIONS } from './paths';

const MyRoutes = () => {
  return (   
    <Routes>
      <Route
          path="*"
          element={<Navigate to={HOMEPAGE} replace />}
      />
      <Route path={HOMEPAGE} element={<Homepage />} />
      <Route path={METHODO} element={<MethodoPage />} />
      <Route path={MENTIONS} element={<MentionsPage />} />
      <Route path={CTT_DATA} element={<CttDemographicsData />} />
      <Route path={CTT_JOBS} element={<CttJobs />} />
      <Route path={CTT_SECTORS} element={<CttSectors />} />
      <Route path={CDII} element={<Cdii />} />
    </Routes>
  )
}

export default MyRoutes;