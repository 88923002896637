import React, { useState } from 'react';
import styled from 'styled-components';
import pxToRem from '../../utils/dimension';


const QuarterPieIndicator : React.FC<QuarterPieIndicatorProps> = ({percentage = 10, comparisonPercentage = 20, color = 'blue', direction, isComparing}) => {
    return(
        <QuarterPieContainer direction={direction}>
          <PathContainer percentage={percentage} color={color} direction={direction} />
          { isComparing &&
            <DottedPathIndicator percentage={comparisonPercentage} comparisonPercentage={percentage} color={color} direction={direction} />
          }
        </QuarterPieContainer>
    )
}

export default QuarterPieIndicator;

export interface QuarterPieIndicatorProps {
  percentage? : number;
  comparisonPercentage : number;
  color : string;
  direction : Direction;
  isComparing : boolean;
}

const QuarterPieContainer = styled.div<QuarterPieContainerProps>`
display          : flex;
flex-direction   : row;
position         : relative;
background-color : #F9F8F6;
width            : ${pxToRem(225)}rem;
height           : ${pxToRem(225)}rem;
${({direction}) => direction === 'left' ? 'border-top-left-radius' : 'border-top-right-radius'} : 100%;
margin           : 1.05px;
`;

const PathContainer = styled.div<PathContainerProps>`
position : absolute;
${({direction}) => direction === 'right' ? 'left : 0;' : 'right : 0;'}
bottom : 0;
${({direction}) => direction === 'left' ? 'border-top-left-radius' : 'border-top-right-radius'} : 100%;
width : ${({percentage}) =>   pxToRem(225) * Math.sqrt(percentage / 100)}rem;
height : ${({percentage}) =>  pxToRem(225) * Math.sqrt(percentage / 100)}rem;
max-width : ${pxToRem(225)}rem;
max-height : ${pxToRem(225)}rem;
background-color : ${({color}) => color};
`;

const DottedPathIndicator = styled(PathContainer)<DottedPathIndicatorProps>`
background-color : transparent;
border : solid;
border-width : 2px;
border-color : ${({percentage, comparisonPercentage, color}) => percentage >= comparisonPercentage ? color : 'white'};
border-style : dotted;
`;
interface QuarterPieContainerProps {
  direction : Direction;
}

interface PathContainerProps {
  percentage : number;
  color : string;
  direction : Direction;
}

interface DottedPathIndicatorProps {
  percentage : number;
  color : string;
  direction : Direction;
  comparisonPercentage : number;
}

export type Direction = 'right' | 'left';