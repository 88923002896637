export const selectFilter = (filter : string, value : any) => ({
  type : 'UPDATE_FILTERS',
  filter,
  value
})

export const filterLoadRequest = (filter : string) => ({
  type : 'FILTER_LOAD_REQUEST',
  filter
})

export const filterLoaded = (filter : string, data : any) => ({
  type : 'FILTER_LOADED',
  filter,
  data
})

export const defaultFilter = (filter : string, value : string) => ({
  type : 'DEFAULT_FILTER',
  filter,
  value
})

export const intercosFiltered = (data : any) => ({
  type : 'INTERCOS_FILTERED',
  data
});

export const departementsFiltered = (data : any) => ({
  type : 'DEPARTEMENTS_FILTERED',
  data
});

export const resetFilter = (filter: string) => ({
  type : 'RESET_FILTER',
  filter
})

export const allFiltersLoaded = () => ({
  type : 'ALL_FILTERS_LOADED'
})