import { StringifyOptions } from 'querystring';
import React from 'react';
import styled from 'styled-components';

import Icon from '../atoms/Icon';

/**
 * Generic Input
 * @param {string} label above of input
 * @param {React.FC} Icon on the right of input
 * @param {string} iconColor the color of the icon
 * @param {string} placeholder 
 * @param {string} value
 * @param {string} type
 * @param {boolean} disabled
 * @param {function} onChange  
 *  
 */

const Input: React.FC<InputProps> = ({
  label,
  iconColor,
  value='',
  placeholder='',
  type,
  onChange=()=>{},
  disabled=false,
  hidden,
  children,
  ...props
}) => {
  return (
    <Container hidden={hidden} {...props}>
      {
        label && <Label>{label}</Label>
      }
      <InputContainer>
        <InputStyle
          placeholder={placeholder}
          value={value}
          type={type}
          onChange={(event) => onChange(event)}
          disabled={disabled}
          {...props}
        />
          { iconColor && <Icon icon='search' color={iconColor} width={14} height={14} />}
          {children}
      </InputContainer>
    </Container>
  );
};

export default Input;

export interface InputProps {
  label?: string;
  iconColor? : string;
  placeholder?: string;
  value: string;
  type: string;
  disabled?: boolean;
  hidden? : boolean;
  onChange?: (event : React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}

const Container : any = styled.div`
  display : ${(props : any) => props.hidden ? 'none' : 'block'};
`;

const InputContainer = styled.div`
  border          : 1px solid #EDEDF4;
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
  align-items     : center;
`;

const Label = styled.label`
  color         : grey;
  margin-bottom : 10px;
  display       : inline-block;
`;

const InputStyle = styled.input`
  flex              : 1;
  display           : flex;
  flex-direction    : row;
  align-items       : center;
  border            : 0;
  color             : #FFFFFF;
  width             : 100%;
  height            : 98%;
`;