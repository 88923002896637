import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

import { IconDemographic } from '../assets/svg-images/IconDemographics';
import { Loader } from '../components/atoms/Loader';
import ContainerBubbleChart from '../components/organisms/ContainerBubbleChart';
import { GenericCard } from '../components/organisms/GenericCard';
import ContainerBarWithFilter from '../components/organisms/ContainerChartBarFIlter';
import GenericPage from '../components/templates/GenericPage';

import { i18next, t } from '../i18n';
import { theme } from '../assets/theme';
import { GenericData, JobLoading, RootState } from '../types';
import { BarGraphData } from '../types/index';
import { client } from '../store/sagas/axiosApiCallWrapper';
import { selectFilter } from '../store/actions/filters';
import { getTerritory } from '../utils/helpers';
import { Trans } from 'react-i18next';

const CttJobs: React.FC = () => {
  const dispatch = useDispatch();

  const {period, territory, age : {ctt : {niv1, niv2}}} = useSelector((state: RootState) => state.paramsFiles);
  const {pcs_niv1, pcs_niv2, pcs_niv3, groupsAgeCttNiv2} = useSelector((state: RootState) => state.filters);
  const {selected} = useSelector((state: RootState) => state.datasets);
  const importId = selected?.importId || null;
  const {totalCtt, comparisonTotalCtt, comparisonLabel, currentLabel} = useSelector((state: RootState) => state.global);
  const {comparison, regions, departements, intercos} = useSelector((state: RootState) => state.filters);

  const [pcsCategoriesData, setPcsCategoriesData] = useState<any[]>([]);
  const [aggregateData, setAggregateData] = useState<any[]>([]);
  const [detailData, setDetailData] = useState<any[]>([]);
  const [dataView, setDataView] = useState<'aggregate' | 'detail'>('aggregate');
  const [loadingMajorsPcs, setLoadingMajorsPcs] = useState<boolean>(false);
  const [loadingPcs, setLoadingPcs] = useState<boolean>(false);
  const [totalPcsLines, setTotalPcsLines] = useState<number>(1);
  const [totalPcsLinesNr, setTotalPcsLinesNr] = useState<number>(0);

  useEffect(() => {
    setLoadingMajorsPcs(true);
    if(period){
      const getPcsCategories = async () => {
        let comparisonData: any;
        if(comparison.selected){
          const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
          const comparisonName = getTerritory(comparisonItem); 
          const comparisonFileName = period + '.' + 'pcs' + '.' + comparisonName + '.' + niv1 + '.json';
          try {
            const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${comparisonFileName}?importId=${importId}`);
            comparisonData = data;
          } catch(error){
            comparisonData = [];
          }
        }
        try {
          const fileName = period + '.' + 'pcs' + '.' + territory + '.' + niv1 + '.json';
          const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
          const pcsNiv1Key = pcs_niv1.data.map((d: any) => d.value);
          const total = _.sumBy(data.filter((d : GenericData) => _.includes(pcsNiv1Key, d.key)), 'value');
          const dataFiltered = data.filter((d : GenericData) => _.includes(pcsNiv1Key, d.key)).map((d: any) => (
            {
              name : pcs_niv1?.data?.find((n: any) => n.value === d.key)?.label, 
              value : d.value, 
              comparisonValue : comparisonData?.find((c: GenericData) => c.key === d.key).value,
              percentage : Math.floor(d.value/total*10000)/100, 
            }
          ));
          setPcsCategoriesData(dataFiltered);
          setLoadingMajorsPcs(false);
        }
        catch(error){
          setPcsCategoriesData([]);
          setLoadingMajorsPcs(false);
        }
      }
      getPcsCategories()
    }
  }, [period, territory, niv1, comparison])

  useEffect(() => {
    setLoadingPcs(true);
    if(period){
      const getPcsCategories = async () => {
        let comparisonData: any;
        if(comparison.selected){
          const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
          const comparisonName = getTerritory(comparisonItem);
          let comparisonFileName;
          if(pcs_niv1.selected){
            comparisonFileName = period + '.' + 'pcs' + '.' + comparisonName + '.' + niv2 + '.' + `S${pcs_niv1.selected}` + '.json';
          } else {
            comparisonFileName = period + '.' + 'pcs' + '.' + comparisonName + '.' + niv2 + '.json';
          }
          try {
            const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${comparisonFileName}?importId=${importId}`);
            comparisonData = data;
          }
          catch(err) {
            comparisonData = [];
          }
        }
        try {
          let fileName: string = '';
          if(pcs_niv1.selected){
            fileName = period + '.' + 'pcs' + '.' + territory + '.' + niv2 + '.' + `S${pcs_niv1.selected}` + '.json';
          } else {
            fileName = period + '.' + 'pcs' + '.' + territory + '.' + niv2 + '.json';
          }
          const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
          const total = _.sumBy(_.filter(data, (d) => _.some(pcs_niv2.data, {value : d.key})), 'value');
          const aggregate = _.sortBy(
            _.filter(data, (d) => _.some(pcs_niv2.data, {value : d.key})), "value")
            .reverse()
            .map((d) => (
              {
                ...d, 
                category : _.find(pcs_niv1.data, (pcsniv1) => pcsniv1.value === d.key.slice(0,1))?.label,
                label : _.find(pcs_niv2.data, {value : d.key}).label, 
                comparisonValue : _.find(comparisonData, {key : d.key})?.value,
                percentage : Math.floor(d.value/total*10000)/100, 
              })
            );
          setAggregateData(aggregate);
          const detail = _.sortBy(
            _.filter(data, (d) => _.some(pcs_niv3.data, {value : d.key})), "value")
            .reverse()
            .map((d) => (
              {
                ...d, 
                category : _.find(pcs_niv1.data, (pcsniv1) => pcsniv1.value === d.key.slice(0,1))?.label, 
                label : _.find(pcs_niv3.data, {value : d.key}).label, 
                comparisonValue : _.find(comparisonData, {key : d.key})?.value,
                percentage : Math.floor(d.value/totalCtt*10000)/100, 
              })
            );
          setDetailData(detail);
          setLoadingPcs(false);
        }
        catch(error){
          setAggregateData([])
          setDetailData([])
          setLoadingPcs(false);
        }
      }
      getPcsCategories()
    }
  }, [period, territory, niv2, pcs_niv1.selected, comparison])

  useEffect(() => {
    const loadLines = async () => {
      try {
        const fileName = period + "." + "lines" + "." + territory + "." + "naf" + ".json";
        const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
        const total = data?.find((d: GenericData) => d.key === "Total")?.value;
        const totalNr = data?.find((d: GenericData) => d.key === "TotalNr")?.value;
        setTotalPcsLines(total);
        setTotalPcsLinesNr(totalNr);
      } catch(error) {
        setTotalPcsLines(1);
        setTotalPcsLinesNr(0);
      }
    }
    loadLines()
  }, [period, territory])

  console.log('aggregateData', _.sumBy(aggregateData, 'value'))

  return (
    <GenericPage>
      <ContainerCard>
        <GenericCard
          cardName='csp'
          color={theme.colors.job.primary.border}
          title={t('Job.Evolution.title')}
          Source1={pcsCategoriesData.length ? <Trans
            i18nKey='Job.Evolution.source1' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : _.maxBy(pcsCategoriesData, "value")?.percentage,
              pcs : _.maxBy(pcsCategoriesData, "value")?.name,
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.job.primary.border}} />}}
          /> : undefined}  
          source2={pcsCategoriesData.length ? t('Job.Evolution.source2') : undefined}
          Source3={pcsCategoriesData.length ? <Trans
            i18nKey='Job.Evolution.source3' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : Math.round((totalPcsLinesNr/totalPcsLines)*1000)/10
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.job.primary.border}} />}}
          /> : undefined}
          /* label={t('Job.Evolution.label')}
          total={totalCtt} */
          comparisonTotal={comparisonTotalCtt}
          isComparing={!!comparison.selected}
          Icon={() => <IconDemographic width={"4rem"} height={"4rem"} color={theme.colors.job.primary.medium} />}
          data={pcsCategoriesData.map((d: any) => !comparison.selected ? ({Secteur : d?.name, Total : d?.value}) : ({Secteur : d?.name, Total : d?.value, [comparisonLabel] : d?.comparisonValue}))}
          fileName={i18next.t('Filename.Job.csp', {period, label : currentLabel})}
        >
          {
            pcsCategoriesData.length && !loadingMajorsPcs ? 
            <ContainerBarWithFilter 
              onSelectAge={(value: string | undefined)=> dispatch(selectFilter('groupsAgeCttNiv1', value))}
              data={pcsCategoriesData}
              comparison={_.sumBy(pcsCategoriesData, 'comparisonValue')}
              isComparing={!!comparison.selected}  
              comparisonLabel={comparisonLabel}
              territoryLabel={currentLabel}
              color={theme.colors.job.primary.medium}
              total={_.sumBy(pcsCategoriesData, 'value')}
            /> :
            <Loader size={100} height={43} color={theme.colors.job.primary.medium} isLoading={loadingMajorsPcs} />
          }
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='jobs'
          color={theme.colors.job.secondary.border}
          title={t('Job.Work.title')}
          Source1={aggregateData.length ? <Trans
            i18nKey='Job.Evolution.source1' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : dataView === 'aggregate' ? _.maxBy(aggregateData, "value")?.percentage : _.maxBy(detailData, "value")?.percentage,
              pcs : dataView === 'aggregate' ? _.maxBy(aggregateData, "value")?.label : _.maxBy(detailData, "value")?.label
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.job.secondary.border}} />}}
          /> : undefined}
          source2={aggregateData.length ? t('Job.Work.source2') : undefined}
          Source3={aggregateData.length ? <Trans
            i18nKey='Job.Work.source3' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : Math.round((totalPcsLinesNr/totalPcsLines)*1000)/10
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.job.secondary.border}} />}}
          /> : undefined}
          /* label={t('Job.Work.label')}
          total={totalCtt} */
          comparisonTotal={comparisonTotalCtt}
          Icon={() => <IconDemographic width={"4rem"} height={"4rem"} color={theme.colors.job.secondary.light} />}
          data={
            dataView === 'aggregate' ? 
              !comparison.selected ?
              aggregateData.map((d: any) => ({Code : d.key, "Métiers" : d.label, Total : d.value})) : 
              aggregateData.map((d: any) => ({Code : d.key, "Métiers" : d.label, Total : d.value, [comparisonLabel] : d.comparisonValue})) 
            : 
              !comparison.selected ?
              detailData.map((d: any) => ({Code : d.key, "Métiers" : d.label, Total : d.value})) : 
              detailData.map((d: any) => ({Code : d.key, "Métiers" : d.label, Total : d.value, [comparisonLabel] : d.comparisonValue})) 
          }
          fileName={i18next.t('Filename.Job.job', {period, label : currentLabel})}
        >
          <ContainerBubbleChart 
            category='job'
            onSelectNiv1={(value: string | undefined) => dispatch(selectFilter('pcs_niv1', value))}
            onSelectNiv2={(value: string | undefined)=> dispatch(selectFilter('groupsAgeCttNiv2', value))}
            filterDataNiv1={pcs_niv1}
            filterDataNiv2={groupsAgeCttNiv2}
            onClickSwitchButton={(value: 'aggregate' | 'detail') => setDataView(value)}
            data={dataView === 'aggregate' ? aggregateData : dataView === 'detail' ? detailData : []}
            dataView={dataView}
            totalAggregate={aggregateData.length}
            totalDetail={detailData.length}
            color={theme.colors.job.secondary.light}
            comparison={!!comparison.selected}
            comparisonLabel={comparisonLabel}
            territoryLabel={currentLabel}
            isLoading={loadingPcs}
          /> 
        </GenericCard>
      </ContainerCard>
    </GenericPage>
  )
}

export default CttJobs;

const ContainerCard = styled.div`
  margin-bottom : 4.5rem;
`;

const ContainerGraphics = styled.div`
  width : 60rem;
  height : 60rem;
`;

const ContainerGraphBar = styled.div`
  padding-left : 6.9rem;
  height : 50rem;
  width : 70rem;
`;