import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '../../i18n';
import _ from 'lodash';
import { selectFilter } from '../../store/actions/filters';

import Dropdown from '../molecules/DropDown';
import { RootState } from '../../types';
import colors from '../../styles/colors';
import Icon from '../atoms/Icon';
import { theme } from '../../assets/theme';

const Filters : React.FC = () => {

  const filters = useSelector((state: RootState) => state.filters);
  const loading = useSelector((state: RootState) => state.loading);
  const global = useSelector((state: RootState) => state.global);
  const { years, quarterly, regions, departements, intercos, comparison } = filters;
  const dispatch = useDispatch();

  const cancelFilters = () => {
    dispatch(selectFilter('quarterly', ''))
    dispatch(selectFilter('regions', ''))
    dispatch(selectFilter('departements', ''))
    dispatch(selectFilter('intercos', ''))
    dispatch(selectFilter('comparison', ''))
  }

  return (
    <Container>
      <Section style={{height : '13rem'}}>
        <Margin>
          <Dropdown 
            label={t('Filter.year.label')}
            placeholder={t('Filter.year.placeholder')}
            items={years.data}
            defaultValue={years.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('years', value))}
            selected={years.selected}
            disabled={loading?.calculation > 0 || global?.disablePeriod}
          />
          <Dropdown 
            label={t('Filter.quarterly.label')}
            placeholder={t('Filter.quarterly.placeholder')}
            items={quarterly.data}
            defaultValue={quarterly.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('quarterly', value))}
            selected={quarterly.selected}
            disabled={loading?.calculation > 0 || global?.disablePeriod}
          />
        </Margin>
      </Section>
      <Section style={{height : '17.5rem'}}>
        <Margin>
          <Dropdown 
            label={t('Filter.territoryLabel')}
            placeholder={t('Filter.region.placeholder')}
            items={_.sortBy(regions.data, (o) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))}
            defaultValue={regions.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('regions', value))}
            selected={regions.selected}
            disabled={loading?.calculation > 0}
            showSearchBar
            searchBarPlaceholder={t('Filter.region.searchBarPlaceholder')}
          />
          <Dropdown 
            // label={t('Filter.direction.label')}
            placeholder={t('Filter.departement.placeholder')}
            items={departements.departementsFiltered.length ? 
              _.sortBy(departements.departementsFiltered, (o) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) : 
              _.sortBy(departements.data, (o) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }
            defaultValue={departements.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('departements', value))}
            selected={departements.selected}
            showSearchBar
            disabled={loading?.calculation > 0}
            searchBarPlaceholder={t('Filter.departement.searchBarPlaceholder')}
          />
          <Dropdown 
            // label={t('Filter.direction.label')}
            placeholder={t('Filter.interco.placeholder')}
            items={intercos.intercosFiltered.length ? 
              _.sortBy(intercos.intercosFiltered, (o: any) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) : 
              _.sortBy(intercos.data, (o: any) => o.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }
            defaultValue={intercos.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('intercos', value))}
            selected={intercos.selected}
            showSearchBar
            disabled={loading?.calculation > 0}
            searchBarPlaceholder={t('Filter.interco.searchBarPlaceholder')}
          />
        </Margin>
      </Section>
      <Section style={{height : '10.5rem', border: 'none'}}>
        <Margin style={{justifyContent: 'flex-start', marginTop: '0.5rem'}} >
          <Dropdown 
            label={t('Filter.comparison.label')}
            placeholder={t('Filter.comparison.placeholder')}
            items={comparison.data}
            defaultValue={comparison.defaultValue}
            onSelect={(value)=> dispatch(selectFilter('comparison', value))}
            selected={comparison.selected}
            showSearchBar
            disabled={loading?.calculation > 0 || !regions.selected && !departements.selected && !intercos.selected}
            searchBarPlaceholder={t('Filter.comparison.searchBarPlaceholder')}
            disabledPlaceholder={t('Filter.comparison.disabledPlaceholder')}
          />
        </Margin>
      </Section>
      <Cancel onClick={cancelFilters} >
        <CancelText>{t('Filter.cancel')}</CancelText>
        <Icon icon='refresh' color={theme.colors.primary} />
      </Cancel>
    </Container>
  )
}

export default Filters;

const Container = styled.div`
  width            : 100%;
  display          : flex;
  flex-direction   : column;
  background-color : ${({theme}) => theme.colors.menu};
`;

const Section = styled.div`
  width         : 100%;
  border-bottom : 1px solid ${({theme}) => theme.colors.background}; 
  padding       : 10px 0px 10px 0px;
`;

const Margin = styled.div`
  margin           : 0 15px;
  width            : 21.5rem;
  height           : 100%;
  display          : flex;
  flex-direction   : column;
  align-items      : center;
  justify-content  : space-around;
`;

const Cancel = styled.div`
  width           : 100%;
  display         : flex;
  flex-direction  : row;
  justify-content : flex-end;
  align-items     : center;
  padding-right   : 1rem;
  cursor          : pointer;
`;

const CancelText = styled.div`
  font-size   : 1.4rem;
  color       : ${({theme}) => theme.colors.neutral};
  font-family : work-sans-light-italic;
`;