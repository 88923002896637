import React, { useState } from 'react';
import styled from 'styled-components';
import { sizeMobile } from '../../utils/constants';

import pxToRem from '../../utils/dimension';

export interface MenuButtonProps {
  label: string;
  showTitle : boolean;
  Icon : React.FC<{color : string}>,
  color: string;
  selected: boolean;
  onClick: () => void;
}

interface ContainerProps {
  selected: boolean;
}

interface SubtitleProps {
  selected : boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  label,
  showTitle,
  selected,
  Icon,
  color,
  onClick
}) => {

  return (
    <Container color={color} selected={selected} onClick={onClick}>
      {showTitle ? <Title>Contrat de Travail Temporaire</Title> : <Title><div style={{height : '1.25rem'}}></div></Title>}
      <div style={{width : '100%', display : 'flex', flex : 1}}>
        <Section>
          <IconContainer>
            <Icon color={color} />
          </IconContainer>
          <Subtitle selected={selected}>
            {label}
          </Subtitle>
        </Section>
      </div>
    </Container>
  )
}

export default MenuButton;



const Container = styled.div<ContainerProps>`
  flex                : 1;
  user-select         : none;
  height              : ${({selected}) => selected ? "8.5rem" : `8.8rem`};
  -moz-user-select    : none;
  -webkit-user-select : none;
  -ms-user-select     : none;
  display             : flex;
  flex-direction      : column;
  align-items         : center;
  border-bottom       : solid;
  border-bottom-width : ${({selected}) => selected ? "5px" : `2px`};
  border-color        : ${({color, selected}) => selected ? color : 'lightgrey'};
  cursor              : pointer;
  background-color    : white;
  max-width           : 24.4%;

  @media screen and (max-width : ${sizeMobile}px) {
    width     : 100%;
    max-width : 100%;
    border-bottom       : 1px solid ${({theme}) => theme.colors.border};
    border-left         : 5px solid ${({color}) => color};
    align-items         : flex-start;
  }
`;

const Title = styled.div`
  background-color : transparent;
  top              : 0;
  margin-top       : 7px;
  text-align       : center;
  font-size        : 1.4rem;
  font-family      : work-sans-semiBold;
  font-weight      : 600;

  @media screen and (max-width : ${sizeMobile}px) {
    text-align       : center;
    margin-left      : 8%;
    font-size        : 1.9rem;
    margin-top       : 4rem;
  }
`;

const Section = styled.div`
  flex             : 1;
  position         : relative;
  display          : flex;
  align-items      : center;
  background-color : transparent;
`;

const Subtitle = styled.div<SubtitleProps>`
  background-color : transparent;
  text-align       : start;
  font-size        : 1.5rem;
  font-family      : ${({selected}) => selected ? "work-sans-bold" : "work-sans-medium"};
  line-height      : 2.3rem;
  text-overflow    : ellipsis;
  overflow         : hidden; 

  @media screen and (max-width : ${sizeMobile}px) {
    font-size        : 2.2rem;
    margin-left      : 1rem;
  }
`;

const IconContainer = styled.div`
  position         : relative;
  background-color : transparent;
  margin-right     : 1rem;
  margin-left      : 8%;
`;

