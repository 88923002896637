import React from 'react';
import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import { sizeMobile } from '../../utils/constants';

import pxToRem from '../../utils/dimension';

const BarIndicatorWithDot : React.FC<BarIndicatorWithDotProps> = ({ color, percentage, comparisonPercentage, label, isComparing, width, comparisonLabel="National", value }) => {
    const size = useWindowSize();
    return(
        <Container width={width}>
            {!isComparing ?
            <ContainerLine>
                {
                    value > 0 ?
                    <PathIndicator percentage={percentage} width={width} color={color} height={3}>
                        <Dot color={color} percentage={percentage} />
                    </PathIndicator> : null
                }
                <LabelContainer height={3} isComparing={false}>
                    {
                        size.width && size.width > sizeMobile &&
                        <Label>{parseFloat(label)?.toLocaleString()} {label.split(' ')[1]}&nbsp;{`/`}</Label>
                    }
                    <Percentage color={color}>&nbsp;{`${percentage}%`}</Percentage>
                </LabelContainer>
            </ContainerLine>
            :
            <ContainerLine>
                <PathIndicator percentage={percentage > comparisonPercentage ? percentage : comparisonPercentage} width={width} color={color} height={3} comparisonPercentage={comparisonPercentage}>
                    {
                        percentage > comparisonPercentage && comparisonPercentage > 0
                        ?
                        <Dot color={color} percentage={percentage} />
                        :
                        <DashedDot color={color} percentage={comparisonPercentage} />
                    }
                    {
                        percentage <= comparisonPercentage && comparisonPercentage > 0
                        ?
                        <DotIndicator color={color} percentage={percentage} comparisonPercentage={comparisonPercentage} width={width} />
                        :
                        <DashedDotIndicator color={color} percentage={percentage} comparisonPercentage={comparisonPercentage} width={width} />
                    }
                </PathIndicator>
                <LabelContainer height={3} isComparing={true} >
                    <Percentage color={color}>&nbsp;{`${percentage}%`}</Percentage>
                    <Label color={color}>/ {comparisonLabel} : </Label>
                    <Percentage color={color}>&nbsp;{`${comparisonPercentage}%`}</Percentage>
                </LabelContainer>
            </ContainerLine>
            }
        </Container>
    )
}


export default BarIndicatorWithDot;

export interface BarIndicatorWithDotProps {
    color : string;
    percentage : number;
    comparisonPercentage : number;
    label : string;
    isComparing : boolean;
    width : number;
    comparisonLabel?: string;
    value: number;
}

interface PathIndicatorProps {
    height : number;
    percentage : number;
    comparisonPercentage? : number;
    color : string;
    width : number;
}

interface ContainerProps {
    width : number;
}

interface LabelContainerProps {
    height : number;
    isComparing : boolean;
}

interface PercentageProps {
    color : string;
}

interface DotProps {
    color : string;
    percentage? : number;
    comparisonPercentage? : number;
    width? : number;
}

interface LabelProps {
    color? : string;
}

interface DotIndicatorProps {
    color : string;
    percentage : number;
    comparisonPercentage : number;
    width : number;
}

const Container = styled.div<ContainerProps>`
    display          : flex;
    flex-direction   : row;
    justify-content  : flex-start;
    align-items      : center;
    position         : relative;
    width            : 100%;
    height           : ${pxToRem(3)}rem;
    background-color : transparent;
    border-radius    : 6px;
    margin-bottom    : ${pxToRem(4)}rem;
`;

const ContainerLine = styled.div`
    display : flex;
    align-items : center;
`;

const PathIndicator = styled.div<PathIndicatorProps>`
    display          : flex;
    align-items      : center;
    height           : ${({height}) => pxToRem(height)}rem;
    width            : ${({width, percentage, comparisonPercentage}) => `${(percentage || 0)*1*(1+(100-(percentage || 0)))/100}rem`};
    background-color : ${({color}) => color};
    border-radius    : ${pxToRem(6)}rem;
    border-width     : 1px;
    border-color     : transparent;
    position         : relative;
`;

const LabelContainer = styled.div<LabelContainerProps>`
    display          : flex;
    flex-direction   : row;
    background-color : transparent;
    width            : auto;
    padding          : 0px;
    margin           : 0px;
    font-size        : ${pxToRem(14)}rem;
    font-weight      : 300;
    margin-left      : 2rem;
`;

const Label = styled.div<LabelProps>`
    width : auto;
    color : ${({color}) => color === undefined ? '#A4A2A0' : color};
    font-family : work-sans-semiBold;
    white-space : nowrap;
    margin-left : 1rem;
    @media screen and (max-width : ${sizeMobile}px) {
        display : none;
    }
`;

const Percentage = styled.div<PercentageProps>`
    color       : ${({color}) => color};
    font-family : work-sans-semiBold;
`;

const Dot = styled.div<DotProps>`
    position         : absolute;
    min-height       : ${pxToRem(14)}rem;
    width            : ${pxToRem(14)}rem;
    border-radius    : 100%;
    background-color : ${({color}) => color};
    left             : ${({width, percentage, comparisonPercentage}) => percentage ? `${(percentage || 0)*1*(1+(100-(percentage || 0)))/100}rem` : 0};
    ${({color}) =>`
    border-color     : ${color};
    border-width     : 1px;
    border-style     : solid;
    `
}
`;

const DashedDot = styled.div<DotProps>`
    position : absolute;
    height : ${pxToRem(14)}rem;
    width : ${pxToRem(14)}rem;
    border-radius : 100%;
    background-color : white;
    left             : ${({width, percentage, comparisonPercentage}) => percentage ? `${(percentage || 0)*1*(1+(100-(percentage || 0)))/100}rem` : 0};
    ${({color}) => `
    border-color : ${color};
    border-width : 0.5px;
    border-style : dashed;
    `}
`;

const DashedDotIndicator = styled.div<DotIndicatorProps>`
    position : absolute;
    height : ${pxToRem(14)}rem;
    width : ${pxToRem(14)}rem;
    border-radius : 100%;
    background-color : white;
    left : ${({ width, percentage, comparisonPercentage }) => percentage > comparisonPercentage ? `${pxToRem(width * comparisonPercentage / 100)}rem` : `${pxToRem(width * percentage / 100)}rem`};
    ${({color}) => `
    border-color : ${color};
    border-width : 0.5px;
    border-style : dashed;
    `}
    z-index : 1;
`;

const DotIndicator = styled.div<DotIndicatorProps>`
    position : absolute;
    height : ${pxToRem(14)}rem;
    width : ${pxToRem(14)}rem;
    border-radius : 100%;
    background-color : ${({color}) => color};
    left : ${({ width, percentage, comparisonPercentage }) => percentage > comparisonPercentage ? `${pxToRem(-7 + width * comparisonPercentage / 100)}rem` : `${pxToRem(-7 + width * percentage / 100)}rem`};
    ${({color}) =>`
    border-color : ${color};
    border-width : 0.5px;
    border-style     : solid;
    `
    }
`
