import defaultState from '../defaultState';

const filesReducer = (state: any = defaultState.maps, action : Action) => {
  switch (action.type) {
    case '@@RESET':
    case 'LOGIN_FAILED':
      return defaultState.maps;
     
    case 'REGIONS_LOADED' :
      return {
        ...state, 
        regions : action.maps
      }
    case 'DEPARTEMENTS_LOADED':
      return {
        ...state, 
        departements : action.maps
      }

    case 'INTERCOS_LOADED':
      return {
        ...state, 
        intercos : action.maps
      }
    
    default:
      return state;
  }
};

export default filesReducer;

interface Action {
  type : string;
  maps : {};
  year: string;
}