import React from 'react';
import styled from 'styled-components';

/**
 * Toggle Button
 * @param {boolean} selected
 * @param {string} color
 * @param {function} onClick
 */

const ToggleButton: React.FC<ToggleButtonProps> = ({
  onClick,
  selected,
  color,
  children,
  ...props
}) => {
  return (
    <ToggleButtonStyle
      type='button'
      onClick={onClick}
      selected={selected}
      color={color}
      {...props}
    >
     {children}
    </ToggleButtonStyle>
  );
};

export default ToggleButton;

export interface ToggleButtonProps {
  selected?: boolean;
  color: string;
  onClick?: () => void;
  children : React.ReactNode;
}

interface StyledToggleProps {
  selected : boolean;
  color    : string; 
}

const ToggleButtonStyle : any = styled.button<StyledToggleProps>`
    display           : flex;
    flex-direction    : row;
    align-items       : center;
    justify-content   : center;
    cursor            : pointer;
    background-color  : ${({selected, color, theme}) => selected ? color : theme.colors.neutral};
    color             : ${({selected, color, theme}) => selected ? theme.colors.neutral : theme.colors.text };
`;

