import defaultState from '../defaultState';

const filesReducer = (state = defaultState.paramsFiles, action : Action) => {
  switch (action.type) {
    case '@@RESET':
    case 'LOGIN_FAILED':
      return defaultState.filters;
        
    case 'UPDATE_PARAMS_FILES':
      return action.data

    default:
      return state;
  }
};

export default filesReducer;

interface Action {
  type : string;
  data : [];
}