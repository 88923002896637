import { IconProps } from "../../types";

export const IconJob : React.FC<IconProps> = ({width=33.045, height=41.498, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props} viewBox="0 0 33.045 41.498">
  <g id="Groupe_1044" data-name="Groupe 1044" transform="translate(-860.73 -20.701)">
    <path id="Stroke_31_Copy" data-name="Stroke 31 Copy" d="M6.988,7.039H0V3.94A4.35,4.35,0,0,1,2.308,0" transform="translate(873.183 32.724)" fill="none" stroke="#fbbc15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_41_Copy" data-name="Stroke 41 Copy" d="M1.146,1.446a6.315,6.315,0,0,0,4.42-.081A3.033,3.033,0,1,0,.5,4.7a2.933,2.933,0,0,0,.467.55A3.031,3.031,0,0,0,4.7,5.566a2.811,2.811,0,0,0,.4-.314,3.023,3.023,0,0,0,.967-2.188" transform="translate(874.258 27.119)" fill="none" stroke="#fbbc15" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_45_Copy" data-name="Stroke 45 Copy" d="M2.478,0,1.239,1.823,0,0" transform="translate(876.052 33.954)" fill="none" stroke="#fbbc15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_81_Copy" data-name="Stroke 81 Copy" d="M0,0V1.075" transform="translate(877.253 30.19)" fill="#59b8d4" stroke="#fbbc15" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Stroke_84_Copy" data-name="Stroke 84 Copy" d="M2.841,0A1.95,1.95,0,0,1,0,0" transform="translate(875.87 31.803)" fill="none" stroke="#fbbc15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <g id="noun-profile-profession-1726238" transform="translate(860.73 20.701)">
      <path id="Tracé_469" data-name="Tracé 469" d="M374.276,276H342.768a.771.771,0,0,0-.768.768V316.73a.771.771,0,0,0,.768.769h31.508a.771.771,0,0,0,.768-.769V276.768A.771.771,0,0,0,374.276,276Zm-.768,39.961H343.537V277.537h29.971Z" transform="translate(-342 -276)" fill="#fbbc15"/>
      <path id="Tracé_471" data-name="Tracé 471" d="M476.453,352.907A8.453,8.453,0,1,0,468,344.453,8.462,8.462,0,0,0,476.453,352.907Zm0-15.37a6.916,6.916,0,1,1-6.916,6.916A6.928,6.928,0,0,1,476.453,337.537Z" transform="translate(-459.931 -332.158)" fill="#fbbc15"/>
      <path id="Tracé_472" data-name="Tracé 472" d="M402.768,628.611h23.823a.771.771,0,0,0,.768-.768v-3.074a.771.771,0,0,0-.768-.768H402.768a.771.771,0,0,0-.768.768v3.074A.771.771,0,0,0,402.768,628.611Zm.768-3.074h22.286v1.537H403.537Z" transform="translate(-398.158 -601.714)" fill="#fbbc15"/>
      <path id="Tracé_473" data-name="Tracé 473" d="M402.768,712.611h23.823a.771.771,0,0,0,.768-.768v-3.074a.771.771,0,0,0-.768-.769H402.768a.771.771,0,0,0-.768.769v3.074A.771.771,0,0,0,402.768,712.611Zm.768-3.074h22.286v1.537H403.537Z" transform="translate(-398.158 -680.334)" fill="#fbbc15"/>
      <path id="Tracé_474" data-name="Tracé 474" d="M402.768,796.611h23.823a.771.771,0,0,0,.768-.768v-3.074a.771.771,0,0,0-.768-.769H402.768a.771.771,0,0,0-.768.769v3.074A.771.771,0,0,0,402.768,796.611Zm.768-3.074h22.286v1.537H403.537Z" transform="translate(-398.158 -758.955)" fill="#fbbc15"/>
    </g>
  </g>
</svg>
)