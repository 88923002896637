/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { all, fork } from 'redux-saga/effects';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createReducers from './reducers';
import sagas    from './sagas';

interface Window {
__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (traceOptions: object) => Function;
}

export default function configureStore(
  initialState: Object,
  history: History,
) {

  const saga = function* rootSaga() {
  yield all(
      [
          sagas
      ].map(fork)
    );
  };

  const sagaMiddleware = createSagaMiddleware();
  const typedWindow = typeof window !== 'undefined' && (window as Window);

  const persistConfig = {
    key: 'root',
    storage,
    whitelist : ['login']
  }
  const persistedReducer = persistReducer(persistConfig, createReducers(history))

  const composeEnhancers =
      (process.env.NODE_ENV === 'development' &&
          typedWindow &&
          typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
          typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              trace: true,
              traceLimit: 25,
          })) ||
      compose;

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        // add your own middlewares here
    ),
    // add your own enhancers here
    ),
  );

  sagaMiddleware.run(saga);

  return store;
}
