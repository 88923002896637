import React from 'react';
import { ColorsPanel, Legend } from '../../types/index';
import styled from 'styled-components';
import { theme } from '../../assets/theme';
import { sizeMobile } from '../../utils/constants';

const MapLegend : React.FC<MapLegendProps> = ({customization, legend}) => {
 
  return (
    <ContainerLegend>
    {
     legend && legend?.map((item : any, index : number) => {
        const label1 = `${item.label.split('/')[0]} `; 
        const label2 = item.label.split('/')[1] ? `${item.label.split('/')[1]}` : '';

        return (
          <Container key={item?.label+''+index} >
            <Chip color={item.color} customization={customization} />
            <Label>{parseFloat(label1).toLocaleString()} {label2 && <div style={{height : '1px', width : '4px', margin : '0 3px', backgroundColor : theme.colors.darkText}} />} {parseFloat(label2).toLocaleString()}</Label>
          </Container>
        )
      })
    }
    </ContainerLegend>
  )
}

export default MapLegend;

export interface MapLegendProps {
  customization : ColorsPanel;
  legend? : Legend[];
}

export interface ChipProps {
  customization : ColorsPanel;
  color : 'dark' | 'medium' | 'light' | 'lighter';
}

const ContainerLegend = styled.div`
  min-width       : 140px;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;

  @media screen and (max-width : ${sizeMobile}px) {
    display         : flex;
    flex-direction  : row;
    align-items     : center;
    justify-content : space-around;
    width           : 100%;
    flex-wrap       : wrap;
  }
`;

const Container = styled.div`
  flex           : 1;
  display        : flex;
  flex-direction : row;
  align-items    : center;
  margin-bottom  : 13px;
  width          : 100%;
`;

const Chip = styled.div<ChipProps>`
  min-width        : 1.4rem;
  min-height       : 1.4rem;
  border-radius    : 50%;
  background-color : ${({theme, color, customization}) => customization?.[color]};
  margin-right     : 1rem;
  margin-left      : 1rem;
`;

const Label = styled.div`
  color       : ${({theme}) => theme.colors.darkText};
  font-family : work-sans-regular;
  white-space : nowrap;
  display     : flex;
  align-items : center;
  font-size   : 1.4rem;
`;