import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

import { IconDemographic } from '../assets/svg-images/IconDemographics';
import InlineBar from '../components/atoms/InlineBar';
import { Loader } from '../components/atoms/Loader';
import BarGraph from '../components/atoms/BarGraph';
import { GenericCard } from '../components/organisms/GenericCard';
import ContainerBubbleChart from '../components/organisms/ContainerBubbleChart';
import GenericPage from '../components/templates/GenericPage';
import CompareBarIndicatorWithDot from '../components/molecules/CompareBarIndicatorWithDot';

import { i18next, t } from '../i18n';
import { theme } from '../assets/theme';
import { SectorLoading, RootState, ResponseCompanySizes, GenericData } from '../types';
import { client } from '../store/sagas/axiosApiCallWrapper';
import { BarGraphData } from '../types/index';
import { selectFilter } from '../store/actions/filters';
import { labelCompanySizes, sizeMobile } from '../utils/constants';
import LegendGraph from '../components/atoms/LegendGraph';
import { getTerritory } from '../utils/helpers';
import ContainerBarWithFilter from '../components/organisms/ContainerChartBarFIlter';
import { Trans } from 'react-i18next';

const CttSectors: React.FC = () => {
  const dispatch = useDispatch();

  const {period, territory, age : {sector : sectorAge}} = useSelector((state: RootState) => state.paramsFiles);
  const {sectors_niv1, sectors_niv2, sectors_niv5, groupsAgeSector} = useSelector((state: RootState) => state.filters);
  const {selected} = useSelector((state: RootState) => state.datasets);
  const importId = selected?.importId || null;
  const {totalCtt, comparisonTotalCtt, comparisonLabel, currentLabel} = useSelector((state: RootState) => state.global);
  const {comparison} = useSelector((state: RootState) => state.filters);


  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [majorSectors, setMajorSectors] = useState<any[]>([])
  const [aggregateData, setAggregateData] = useState<any[]>([]);
  const [detailData, setDetailData] = useState<any[]>([]);
  const [dataView, setDataView] = useState<'aggregate' | 'detail'>('aggregate');
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
  const [loadingMajorSectors, setLoadingMajorSectors] = useState<boolean>(false);
  const [loadingSectors, setLoadingSectors] = useState<boolean>(false);
  const [totalEntLines, setTotalEntLines] = useState<number>(1);
  const [totalLineEntsNr, setTotalEntLinesNr] = useState<number>(0);
  const [totalNafLines, setTotalNafLines] = useState<number>(1);
  const [totalNafLinesNr, setTotalNafLinesNr] = useState<number>(0);

  const [total, setTotal] = useState<number>(1);

  useEffect(() => {
    setLoadingCompanies(true);
    if(period){
      const getNafCompanies = async () => {
        const fileName = period + '.' + 'companysizes' + '.' + territory + '.json';
        let comparisonData: any;
        if(comparison.selected){
          const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
          const comparisonName = getTerritory(comparisonItem); 
          const comparisonFileName = period + '.' + 'companysizes' + '.' + comparisonName + '.json';
          try {
            const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${comparisonFileName}?importId=${importId}`);
            comparisonData = data;
          }
          catch(err){ 
            comparisonData = [];
          }
        }
        try {
          const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
          const total = _.sumBy(data.slice(0,5), 'value');
          setCompaniesData(data.map((d: ResponseCompanySizes, index: number) => (
            {
              value : d.value, 
              comparisonValue : comparisonData?.[index]?.value, 
              name : labelCompanySizes[d.key],
              percentage : Math.floor(d.value/total*10000)/100, 
            })
          ).slice(0,5));
          if(data.filter((c: any) => c.value).length){
          } else {
            setCompaniesData([]);
            setLoadingCompanies(false);
          }
        }
        catch(error){
          setCompaniesData([]);
          setLoadingCompanies(false);
        }
      }
      getNafCompanies()
    }
  }, [period, territory, comparison])

  useEffect(() => {
    setLoadingMajorSectors(true);
    if(period){
      const getNafCategories = async () => {
        let comparisonData: any;
        if(comparison.selected){
          const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
          const comparisonName = getTerritory(comparisonItem); 
          const comparisonFileName = period + '.' + 'naf' + '.' + comparisonName + '.' + sectorAge + '.json';
          try {
            const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${comparisonFileName}?importId=${importId}`);
            comparisonData = data;
          }
          catch(err) {
            comparisonData = [];
          }
        }
        const fileName = period + '.' + 'naf' + '.' + territory + '.' + sectorAge + '.json';
        try {
          const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
          const nafNiv1Key = sectors_niv1.data.map((d: any) => d.value);
          const dataFiltered = data.filter((d : GenericData) => _.includes(nafNiv1Key, d.key))
          const totalNiv1 = _.sumBy(dataFiltered, 'value');
          const dataMaped = dataFiltered.map((d: any) => (
            {
              name : sectors_niv1?.data?.find((n: any) => n.value === d.key)?.label, 
              value : d.value, 
              comparisonValue : comparisonData?.find((c: GenericData) => c.key === d.key).value,
              percentage : Math.floor(d.value/totalNiv1*10000)/100,
            }
          ));
          setMajorSectors(dataMaped)
          setLoadingMajorSectors(false);
        }
        catch(error){
          setLoadingMajorSectors(false);
          setMajorSectors([])        
        }
      }
      getNafCategories()
    }
  }, [period, territory, comparison])

  useEffect(() => {
    setLoadingSectors(true);
    if(period){
      const getNafCategories = async () => {
        let comparisonData: any;
        if(comparison.selected){
          const comparisonItem = comparison?.data?.find((d: any) => d.value === comparison.selected);
          const comparisonName = getTerritory(comparisonItem); 
          let fileName: string = '';
          if(sectors_niv1.selected){
            fileName = period + '.' + 'naf' + '.' + comparisonName + '.' + sectorAge + '.' + `S${sectors_niv1.selected}` + '.json';
          } else {
            fileName = period + '.' + 'naf' + '.' + comparisonName + '.' + sectorAge + '.json';
          }
          try {
            const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
            comparisonData = data;
          }
          catch(err) {
            comparisonData = [];
          }
        }
        try {
          let fileName: string = '';
          if(sectors_niv1.selected){
            fileName = period + '.' + 'naf' + '.' + territory + '.' + sectorAge + '.' + `S${sectors_niv1.selected}` + '.json';
          } else {
            fileName = period + '.' + 'naf' + '.' + territory + '.' + sectorAge + '.json';
          }
          const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
          const dataFilteredNiv2 = _.filter(data, (d) => _.some(sectors_niv2.data, {value : d.key}));
          const totalNiv2 = _.sumBy(dataFilteredNiv2, 'value');
          const aggregate = _.sortBy(dataFilteredNiv2)
            .reverse().
              map((d) => (
                {
                  ...d, 
                  category : _.find(sectors_niv1.data, (sectniv1) => sectniv1.value === _.find(sectors_niv2.data, (sectniv2) => sectniv2.code_secteur === d.key)?.code_secteur_niv1)?.label, 
                  label : _.find(sectors_niv2.data, {value : d.key}).label, 
                  comparisonValue : _.find(comparisonData, {key : d.key})?.value,
                  percentage : Math.floor(d.value/totalNiv2*10000)/100, 
                })
              );
          setAggregateData(aggregate);
          // if(aggregate.filter((a) => a.value).length){
          // } else {
          //   setAggregateData([]);
          // }
          const dataFilteredNiv5 = _.filter(data, (d) => _.some(sectors_niv5.data, {value : d.key}));
          const totalNiv5 = _.sumBy(dataFilteredNiv5, 'value');
          const detail = _.sortBy(dataFilteredNiv5)
            .reverse()
              .map((d) => (
                {
                  ...d, 
                  category : _.find(sectors_niv1.data, (sectniv1) => sectniv1.value === _.find(sectors_niv5.data, (sectniv5) => sectniv5.code_secteur === d.key)?.code_secteur_niv1)?.label, 
                  label : _.find(sectors_niv5.data, {value : d.key}).label === "Activités des agences de travail temporaire" ? "Autres" : _.find(sectors_niv5.data, {value : d.key}).label, 
                  comparisonValue : _.find(comparisonData, {key : d.key})?.value,
                  percentage : Math.floor(d.value/totalNiv5*10000)/100, 
                })
              );
          setDetailData(detail);
          // if(detail.filter((d) => d.value).length){
          // } else {
          //   setDetailData([]);
          // }
          setLoadingSectors(false);
        }
        catch(error){
          setLoadingSectors(false);
          setAggregateData([]);
          setDetailData([]);
        }
      }
      getNafCategories()
    }
  }, [period, territory, sectorAge, sectors_niv1.selected, comparison])

  useEffect(() => {
    const loadLines = async () => {
      try {
        const fileName = period + "." + "lines" + "." + territory + "." + "ent" + ".json";
        const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
        const total = data?.find((d: GenericData) => d.key === "Total")?.value;
        const totalNr = data?.find((d: GenericData) => d.key === "TotalNr")?.value;
        setTotalEntLines(total);
        setTotalEntLinesNr(totalNr);
      } catch(error) {
        setTotalEntLines(1);
        setTotalEntLinesNr(0);
      }
      try {
        const fileName = period + "." + "lines" + "." + territory + "." + "naf" + ".json";
        const { data : {data} }: any = await client.get(`${window._env_.MODEL_API_URL}/data/${fileName}?importId=${importId}`);
        const total = data?.find((d: GenericData) => d.key === "Total")?.value;
        const totalNr = data?.find((d: GenericData) => d.key === "TotalNr")?.value;
        setTotalNafLines(total);
        setTotalNafLinesNr(totalNr);
      } catch(error) {
        setTotalNafLines(1);
        setTotalNafLinesNr(0);
      }
    }
    loadLines()
  }, [period, territory])

  return (
    <GenericPage>
      <ContainerCard>
        <GenericCard
          cardName='companiesSize'
          color={theme.colors.sector.primary}
          title={t('Sector.Size.title')}
          Source1={companiesData.length ? <Trans
            i18nKey='Sector.Size.source1' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : _.maxBy(companiesData, "value")?.percentage,
            size : _.maxBy(companiesData, "value")?.name
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.sector.primary}} />}}
          /> : undefined}
          source2={companiesData.length ? t('Sector.Size.source2') : undefined}
          Source3={companiesData.length ? <Trans
            i18nKey='Sector.Size.source3' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : Math.floor((totalLineEntsNr/totalEntLines)*10000)/100
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.sector.primary}} />}}
          /> : undefined}
          /* label={t('Sector.Size.label')}
          total={totalCtt} */
          Icon={() => <IconDemographic width={"4rem"} height={"4rem"} color={theme.colors.sector.primary} />}
          comparisonTotal={comparisonTotalCtt}
          isComparing={!!comparison.selected}
          data={companiesData.map((d: any) => !comparison.selected ? ({Secteur : d.name, Total : d.value}) : ({Secteur : d.name, Total : d.value, [comparisonLabel] : d.comparisonValue}))}
          fileName={i18next.t('Filename.Sector.companies', {period, label : currentLabel})}
        >
          {
            companiesData.length ?
            <ContainerGraphBar>
              <BarGraph 
                color={theme.colors.sector.primary}
                data={companiesData}
                isComparing={!!comparison.selected} 
                total={_.sumBy(companiesData, 'value')}
                comparisonTotal={_.sumBy(companiesData, 'comparisonValue')}
              />
              {
                comparison.selected ? 
                <LegendGraph 
                  color={theme.colors.sector.primary}
                  comparisonLabel={comparisonLabel}
                  territoryLabel={currentLabel}
                /> : null
              }
            </ContainerGraphBar> :
            <Loader size={100} height={50} color={theme.colors.sector.primary} isLoading={loadingCompanies} />
          }
          {/* {
            companiesData.length ? 
            <ContainerBarWithFilter 
              onSelectAge={(value: string | undefined)=> dispatch(selectFilter('groupsAgeCttNiv1', value))}
              data={companiesData}
              comparison={_.sumBy(companiesData, 'comparisonValue')}  
              comparisonLabel={comparisonLabel}
              territoryLabel={currentLabel}
              color={theme.colors.sector.primary}
            /> :
            <Loader size={100} height={43} color={theme.colors.job.primary.medium} isLoading={loadingCharts['companies'] || false} />
          } */}
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='majorSectors'
          color={theme.colors.sector.secondary}
          title={t('Sector.MajorIndustry.title')}
          Source1={majorSectors.length ? <Trans
            i18nKey='Sector.MajorIndustry.source1' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : _.maxBy(majorSectors, "value")?.percentage,
              sector : _.maxBy(majorSectors, "value")?.name
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.sector.secondary}} />}}
          /> : undefined}
          source2={majorSectors.length ? t('Sector.MajorIndustry.source2') : undefined}
          Source3={majorSectors.length ? <Trans
            i18nKey='Sector.MajorIndustry.source3' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : Math.floor((totalNafLinesNr/totalNafLines)*10000)/100
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.sector.secondary}} />}}
          /> : undefined}
          /* label={t('Sector.MajorIndustry.label')}
          total={totalCtt} */
          Icon={() => <IconDemographic width={"4rem"} height={"4rem"} color={theme.colors.sector.secondary} />}
          comparisonTotal={comparisonTotalCtt}
          isComparing={!!comparison.selected}
          data={majorSectors.map((d: any) => !comparison.selected ? ({Secteur : d.name, Total : d.value}) : ({Secteur : d.name, Total : d.value, [comparisonLabel] : d.comparisonValue}))}
          fileName={i18next.t('Filename.Sector.majorSectors', {period, label : currentLabel})}
        >
          <div style={{display : 'flex', flexDirection : 'row', alignItems : 'center'}} >
            {
              majorSectors.length ? 
              <ContainerSectors>
              {
                majorSectors.map(({key, value, name, comparisonValue, percentage}) => {
                  return (
                    <CompareBarIndicatorWithDot
                      key={name} 
                      color={theme.colors.sector.secondary} 
                      percentage={percentage} 
                      comparisonPercentage={Math.floor(comparisonValue/comparisonTotalCtt*10000)/100} 
                      width={500} 
                      label={i18next.t('Chart.sector', {count : value})} 
                      description={name} 
                      isComparing={!!comparison.selected}  
                      comparisonLabel={comparisonLabel === 'Comparaison nationale' ? 'National' : comparisonLabel}   
                      value={value}                         
                    />
                  )
                })
              }
              </ContainerSectors> 
              :
              <Loader size={100} height={63} color={theme.colors.sector.secondary} isLoading={loadingMajorSectors} />
            }
            {
              !!comparison.selected ? 
              <LegendGraph 
                color={theme.colors.sector.secondary}
                comparisonLabel={comparisonLabel}
                territoryLabel={currentLabel}
              /> : null
            } 
          </div>
        </GenericCard>
      </ContainerCard>
      <ContainerCard>
        <GenericCard
          cardName='sectors'
          color={theme.colors.sector.primary}
          title={t('Sector.Detail.title')}
          Source1={aggregateData.length ? <Trans
            i18nKey='Sector.Detail.source1' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : dataView === 'aggregate' ? _.maxBy(aggregateData, "value")?.percentage : _.maxBy(detailData, "value")?.percentage,
              sector : dataView === 'aggregate' ? _.maxBy(aggregateData, "value")?.label : _.maxBy(detailData, "value")?.label
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.sector.primary}} />}}
          /> : undefined}
          source2={aggregateData.length ? t('Sector.Detail.source2') : undefined}
          Source3={aggregateData.length ? <Trans
            i18nKey='Sector.Detail.source3' // optional -> fallbacks to defaults if not provided
            values={{ 
              percentage : Math.floor((totalNafLinesNr/totalNafLines)*10000)/100
            }}
            components={{bold : <strong style={{fontFamily : "work-sans-semiBold", color : 'black', background : theme.colors.sector.primary}} />}}
          /> : undefined}
          /* label={t('Sector.Detail.label')}
          total={totalCtt} */
          Icon={() => <IconDemographic width={"4rem"} height={"4rem"} color={theme.colors.sector.primary} />}
          data={
            dataView === 'aggregate' ? 
              !comparison.selected ?
              aggregateData.map((d: any) => ({Code : d.key, "Secteurs" : d.label, Total : d.value})) : 
              aggregateData.map((d: any) => ({Code : d.key, "Secteurs" : d.label, Total : d.value, [comparisonLabel] : d.comparisonValue})) 
            : 
              !comparison.selected ?
              detailData.map((d: any) => ({Code : d.key, "Secteurs" : d.label, Total : d.value})) : 
              detailData.map((d: any) => ({Code : d.key, "Secteurs" : d.label, Total : d.value, [comparisonLabel] : d.comparisonValue})) 
          }
          fileName={i18next.t('Filename.Sector.sectors', {period, label : currentLabel})}
        >
          <ContainerBubbleChart 
            category='sector'
            onSelectNiv1={(value: string | undefined) => dispatch(selectFilter('sectors_niv1', value))}
            onSelectNiv2={(value: string | undefined)=> dispatch(selectFilter('groupsAgeSector', value))}
            filterDataNiv1={sectors_niv1}
            filterDataNiv2={groupsAgeSector}
            onClickSwitchButton={(value: 'aggregate' | 'detail') => setDataView(value)}
            data={dataView === 'aggregate' ? aggregateData : dataView === 'detail' ? detailData : []}
            dataView={dataView}
            totalAggregate={aggregateData.length}
            totalDetail={detailData.length}
            color={theme.colors.sector.primary}
            comparison={!!comparison.selected}
            comparisonLabel={comparisonLabel}
            territoryLabel={currentLabel}
            isLoading={loadingSectors}
          /> 
        </GenericCard>
      </ContainerCard>
    </GenericPage>
  )
}

export default CttSectors;

const ContainerCard = styled.div`
  margin-bottom : 4.5rem;
`;

const ContainerGraphics = styled.div`
  width : 60rem;
  height : 60rem;
`;

const ContainerGraphBar = styled.div`
  padding-left   : 6.9rem;
  height         : 50rem;
  width          : 70rem;
  display        : flex;
  flex-direction : row;
  align-items    : center;

  @media screen and (max-width : ${sizeMobile}px) {
    padding-left   : 0rem;
  }
`;

const ContainerSectors = styled.div`
  display        : flex;
  flex-direction : column;
  width          : 100%;

  @media screen and (max-width : ${sizeMobile}px) {
    margin-top     : 2rem;
  }
`;
