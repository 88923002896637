import defaultState from '../defaultState';

const loadingReducer = (state : any = defaultState.login, action : any) => {
  switch (action.type) {
    case 'LOADING_BEGIN':
      return {
        ...state,
        [action.name] : state[action.name] ? state[action.name] + 1 : 1
      };

    case 'LOADING_END':
      return {
        ...state,
        [action.name] : state[action.name] ? state[action.name] - 1 : 0
      };

    case '@@RESET':
      return defaultState.loading;

    default:
      return state;
  }
};

export default loadingReducer;