import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';

import { IconLabel } from '../../types';
import pxToRem from '../../utils/dimension';



export interface SwitchButtonWithIconsProps {
    circleColor : string;
    checked : boolean;
    leftIcon? : IconLabel;
    rightIcon? : IconLabel;
    onClick : () => void;
    colors : Colors;
}

interface Colors {
    default : string;
    selected : string;
}



const SwitchButtonWithIcons : React.FC<SwitchButtonWithIconsProps> = ({
    circleColor,
    checked,
    leftIcon='three-dots',
    rightIcon='list',
    colors,
    onClick
}) => {
    
    return(
        <SwitchContainer height={31} width={69} onClick={onClick}>
            <Container>
                <div style={{position : 'absolute', left : `${pxToRem(10)}rem`, top : `${pxToRem(10.5)}rem`}}>
                    <NavigationIcon color={colors.default} icon={leftIcon} height={10} width={11}/>
                </div>
                <div style={{position : 'absolute', right : `${pxToRem(10)}rem`, top : `${pxToRem(10.5)}rem`}}>
                    <NavigationIcon color={colors.default} icon={rightIcon} height={8} width={11}/>
                </div>   
            </Container>
            <Circle checked={checked} radius={43} circleColor={circleColor} >
                {checked ?
                <NavigationIcon color={colors.selected} icon={leftIcon} /> :
                <NavigationIcon color={colors.selected} icon={rightIcon}/>}
            </Circle>
        </SwitchContainer>
    )
};

export default SwitchButtonWithIcons;


interface CircleProps {
    checked : boolean;
    radius : number;
    circleColor : string;
}

interface SwitchContainerProps {
    height : number;
    width : number;
}

const NavigationIcon = styled(Icon)`
  width : ${pxToRem(11)}rem;
  height : ${pxToRem(10)}rem;
`;

const SwitchContainer = styled.div<SwitchContainerProps>`
    position         : relative;
    display          : flex;
    align-items      : center;
    justify-content  : center;
    background-color : transparent;
    cursor           : pointer;
    width            : ${pxToRem(69)}rem;
    height           : ${pxToRem(31)}rem;
`;

const Container = styled.div`
    position : relative;
    display : flex;
    // box-shadow : 1px 2px 5px grey;
    height : 100%;
    width : 100%;
    border : solid;
    border-radius : 10em;
    background-color : transparent;
    border-width : 1px;
    border-color : #F4F4F4;
    align-items : center;
    justify-content : space-between;
`;

const Circle = styled.div<CircleProps>`
    position : absolute;
    display : flex;
    box-shadow : 0px 2px 3px #979797;
    height : ${({radius}) => `${pxToRem(radius)}rem`};
    width : ${({radius}) => `${pxToRem(radius)}rem`};
    ${({checked}) => checked ? `left : ${pxToRem(-6)}rem;` : `right : ${pxToRem(-6)}rem;`};
    border-radius : 50%;
    background-color : ${({circleColor}) => `${circleColor}`};
    align-items : center;
    justify-content : center;
`;

