import { client } from "../store/sagas/axiosApiCallWrapper";

export const quentile = (data : number[], q : number, type: 'floor' | 'ceil') => {
  var pos = ((data.length) - 1) * q;    
  var base = Math.floor(pos);          
  var rest = pos - base;           
  if( (data[base+1]!==undefined) ) {
    const value = data[base] + rest * (data[base+1] - data[base]);
    const diviser = getDigitDivision(value); 

    if(type === 'floor'){
      return Math.floor(value/diviser)*diviser;
    } else{
      return Math.ceil(value/diviser)*diviser;
    } 
  } else {
    const value = data[base];
    const diviser = getDigitDivision(value); 
    if(type === 'floor'){
      return Math.floor(value/diviser)*diviser;
    } else{
      return Math.ceil(value/diviser)*diviser;
    }  
  }
}

// wrapPromise.js
/**
 * Wraps a promise so it can be used with React Suspense
 * @param {Promise} promise The promise to process
 * @returns {Object} A response object compatible with Suspense
 */
 export const wrapPromise = (promise: Promise<any>): any => {
  let status = 'pending';
  let response: any;

  const suspender = promise.then(
    res => {
      status = 'success';
      response = res;
    },
    err => {
      status = 'error';
      response = err;
    },
  );

  const handler: any = {
    pending: () => {
      throw suspender;
    },
    error: () => {
      throw response;
    },
    default: () => response,
  };

  const read = () => {
    const result = handler[status] ? handler[status]() : handler.default();
    return result;
  };

  return { read };
}

const fetchData = (url: string) => {
  const promise = client.get(url).then(({data}) => data);

  return wrapPromise(promise);
}

export default fetchData;

const getDigitDivision = (value: number) => {
  if(value < 100){
    return 1
  } else if(value < 1000){
    return 10
  } else if(value < 10000){
    return 100
  } else if(value < 100000){
    return 1000
  } else if(value < 1000000){
    return 10000
  } else if(value < 10000000){
    return 100000
  } else if(value < 100000000){
    return 1000000
  } else if(value < 1000000000){
    return 10000000
  } else return 0
}

export const getLegend = (minValue : number, maxValue : number, data : number[]) => {
  let dataSorted = [];
  if(minValue === Infinity || maxValue === Infinity){
    return [];
  }
  dataSorted = data.filter((data) => data).sort((a : number, b : number) => {
    if(a < b){
      return 1
    }
    if(b < a){
      return -1
    }
    return 0
  })

  return [
    {
      label : `${quentile(dataSorted, 0.25, 'ceil')}  /  ${quentile(dataSorted, 0, 'ceil')}`,
      color : 'dark'
    },
    {
      label : `${quentile(dataSorted, 0.5, 'ceil')}  /  ${quentile(dataSorted, 0.25, 'ceil')}`,
      color : 'medium'
    },
    {
      label : `${quentile(dataSorted, 0.75, 'ceil')}  /  ${quentile(dataSorted, 0.5, 'ceil')}`,
      color : 'light'
    },
    {
      label : `0 / ${quentile(dataSorted, 0.75, 'ceil')}`,
      color : 'lighter'
    }
  ]
}

export const getColor = (legend : any[], value : number) => {
  let intensityColor : any = 'disabled';
  legend?.forEach((data : any) => {
    const values = data.label.split('/');
    const value0 = parseFloat(values[0]);
    const value1 = parseFloat(values[1]);
    const valueRound = Math.round(value*10)/10;
    if(value === 0){
      intensityColor = 'disabled';
    } else if(valueRound >= value0 && valueRound <= value1){
      intensityColor = data.color;
    }
  })
  return intensityColor;
}

export const getTerritory = (item: any) => {
  let comparisonName;
  if(item?.code_reg && !item?.code_dep){
    comparisonName = `r${item.value}`;
  } else if(item?.code_dep) {
    comparisonName = `d${item?.value}`;
  } else if(item?.code_int) {
    comparisonName = `d${item?.value}`;
  } else {
    comparisonName = `${item?.value}`;
  }
  return comparisonName;
}

export const getTrimestre = (item: string) => {
  if(item === '1'){
    return "1er Trimestre"
  } else if(item === '2'){
    return "2ème Trimestre"
  } else if(item === '3'){
    return "3ème Trimestre"
  } else if(item === '4') {
    return "4ème Trimestre"
  } else {
    return ""
  }
}