import { StringifyOptions } from 'querystring';
import React, { Children } from 'react';
import styled from 'styled-components';
import { sizeMobile } from '../../utils/constants';

import pxToRem from '../../utils/dimension';


const BarIndicator : React.FC<BarIndicatorProps> = ({ 
    color, 
    percentage, 
    width=219, 
    height=41, 
    label,
    isComparing=false, 
    dottedBorder=false,
    showLabel=true
}) => {
    return(
        <Container width={width} height={height}>
            <PathIndicator percentage={percentage} width={width} color={color} height={height} dottedBorder={dottedBorder} />
            { 
            showLabel ?
                !isComparing ?
                <LabelContainer height={height} isComparing={false}>
                    <Label>{parseFloat(label || "")?.toLocaleString()} {label?.split(' ')[1]}&nbsp;{`/`}</Label>
                    <Percentage color={color}>&nbsp;{`${percentage}%`}</Percentage>
                </LabelContainer>
                :
                <LabelContainer height={height} isComparing={true}>
                    <Percentage color={color}>&nbsp;{`${percentage}%`}</Percentage>
                </LabelContainer>
                :
                null
            }
        </Container>
    )
}

export default BarIndicator;


export interface BarIndicatorProps {
    color : string;
    percentage : number;
    width? : number;
    height? : number;
    label? : string;
    isComparing : boolean;
    dottedBorder? : boolean;
    showLabel?: boolean;
}

interface PathIndicatorProps {
    width : number;
    height : number;
    percentage : number;
    color : string;
    dottedBorder : boolean;
}

interface ContainerProps {
    width : number;
    height : number;
}

interface LabelContainerProps {
    height : number;
    isComparing : boolean;
}

interface PercentageProps {
    color : string;
}

export interface DotProps {
    color : string;
    height : number;
    dashed : boolean;
}

const Container = styled.div<ContainerProps>`
    display          : flex;
    flex-direction   : row;
    justify-content  : flex-start;
    align-items      : center;
    position         : relative;
    width            : ${({width}) => pxToRem(width)}rem;
    height           : ${({height}) => pxToRem(height)}rem;
    background-color : transparent;
    border-radius    : 6px;
    margin-bottom    : ${pxToRem(4)}rem;
    @media screen and (max-width : ${sizeMobile}px) {
        width : 27.5rem;
    }
`;

const PathIndicator = styled.div<PathIndicatorProps>`
    flex-basis       : ${({width, percentage}) => pxToRem(width * (percentage / 100)*(1+((100-percentage)/100)))}rem;
    height           : ${({height}) => pxToRem(height)}rem;
    background-color : ${({color, dottedBorder}) => !dottedBorder ? color : 'transparent'};
    border-radius    : ${pxToRem(6)}rem;
    ${({dottedBorder}) => dottedBorder && 
    `border-style    : dashed;
    border-width     : 1px;
    `}
    border-color     : ${({color}) => color};
    position         : relative;
`;

const LabelContainer = styled.div<LabelContainerProps>`
    display          : flex;
    flex-direction   : row;
    background-color : transparent;
    width            : auto;
    padding          : 0px;
    margin           : 0px;
    font-size        : ${pxToRem(14)}rem;
    font-weight      : 300;
    margin-left      : 1.5rem;

`;

const Label = styled.div`
    width : auto;
    color : #A4A2A0;
    white-space : nowrap;

    @media screen and (max-width : ${sizeMobile}px) {
        display : none;
    }
`;

const Percentage = styled.div<PercentageProps>`
    color       : ${({color}) => color};
    font-family : work-sans-semiBold;
`;

export const Dot = styled.div<DotProps>`
    position : absolute;
    height : ${pxToRem(14)}rem;
    width : ${pxToRem(14)}rem;
    border-radius : 100%;
    background-color : ${({color}) => color};
    top : ${({height}) => pxToRem(height/2 - 7)}rem;
    right : ${- pxToRem(7)}rem;
    ${({dashed, color}) => dashed &&
    `
    border-style : dashed;
    border-color : ${color};
    border-width : 1px;
    background-color : white;
    `
}
`;