import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { theme } from '../../assets/theme';
import { t } from '../../i18n';
import { FixedSizeList } from 'react-window';
import { useSelector } from 'react-redux';

import { RootState } from '../../types';

import SearchInput from '../atoms/SearchInput';
import Icon from '../atoms/Icon';

import ColumnSortLabel from '../atoms/ColumnSortLabel';
import BarList from '../atoms/BarList';
import pxToRem from '../../utils/dimension';
import { useLocation, useNavigate, useHref } from 'react-router';
import Pagination from '../atoms/Pagination';
import { statisticSecretValue } from '../../utils/constants';

const pageSlice = 20;

const List : React.FC<ListProps> = ({data, category, color, total, hideLabel, comparison, comparisonTotalCtt}) => {
  const filters = useSelector((state : RootState) => state.filters);
  const [inputValue, setInputValue] = useState<string>('');
  const [dataFiltered, setDataFiltered] = useState<any[]>([]);
  const [dataSorted, setDataSorted] = useState<any[]>(data);
  const [sortType, setSortType] = useState<string>('');
  const [indexArray, setIndexArray] = useState<any>([0, pageSlice]);
  const [dataPage, setDataPage] = useState<any[]>([]);
  const [pages, setPages] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);
  const scrolledRef = React.useRef(false);
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setReload(false);
    if(inputValue){
      const filteredItems = data?.filter(({label} : any) => label?.toLowerCase().match(inputValue.toLowerCase()));
      setDataFiltered(filteredItems);
      let pagesArray=[];
      let i;
      for(i=0; i<Math.ceil(filteredItems?.length/pageSlice); i++){
        pagesArray.push(i);
      }
      setPages(pagesArray);
    }
    else {
      setDataSorted(data);
      setDataFiltered([]);
      let pagesArray=[];
      let i;
      for(i=0; i<Math.ceil(data?.length/pageSlice); i++){
        pagesArray.push(i);
      }
      setPages(pagesArray);
    }
    setSortType('');
    setCurrentPage(0);
  }, [inputValue , reload])

  useEffect(() => {
    setIndexArray([0, pageSlice]);
    setCurrentPage(0);
  }, [dataSorted])

  useEffect(() => {
    setIndexArray([0, pageSlice]);
  }, [dataFiltered])

  useEffect(() => {
    setSortType('');
  }, [filters])

  useEffect(() => {
    if(inputValue){
      setReload(true);
    } else {
      setDataSorted(data);
      let pagesArray=[];
      let i;
      for(i=0; i<Math.ceil(data?.length/pageSlice); i++){
        pagesArray.push(i);
      }
      setPages(pagesArray)
    }
  }, [data])

  useEffect(() => {
    if(inputValue){
      const dataSlice = dataFiltered?.slice(indexArray[0], indexArray[1]);
      setDataPage(dataSlice);
    }
    else{
      const dataSlice = dataSorted?.slice(indexArray[0], indexArray[1]);
      setDataPage(dataSlice);
    }
  }, [indexArray])

  const handleClickNextPage = () => {
    setIndexArray([indexArray[0]+pageSlice, indexArray[1]+pageSlice]);
    setCurrentPage(currentPage+1);
  }

  const handleClickPreviousPage = () => {
    setIndexArray([indexArray[0]-pageSlice, indexArray[1]-pageSlice]);
    setCurrentPage(currentPage-1);
  }

  const handleSelectPage = (page: number) => {
    setIndexArray([page*pageSlice, page*pageSlice+pageSlice]);
    setCurrentPage(page);
  }

  const sortAsc = (comparator : string) => {
    if(dataFiltered.length){
      const dataSorted = _.orderBy(dataFiltered, (d) => d[comparator] !== statisticSecretValue ? d[comparator] : 0.01, ["asc"]);
      setDataFiltered([...dataSorted])
    }
    else {
      const dataSorted = _.orderBy(data, (d) => d[comparator] !== statisticSecretValue ? d[comparator] : 0.01, ["asc"]);      
      setDataSorted([...dataSorted])
    }
  }

  const sortDesc = (comparator : string) => {
    if(dataFiltered.length){
      const dataSorted = _.orderBy(dataFiltered, (d) => d[comparator] !== statisticSecretValue ? d[comparator] : 0.01, ["desc"]);
      setDataFiltered([...dataSorted])
    }
    else {
      const dataSorted = _.orderBy(data, (d) => d[comparator] !== statisticSecretValue ? d[comparator] : 0.01, ["desc"]);      
      setDataSorted([...dataSorted])
    }
  }

  
  React.useEffect(() => {
    const label = dataFiltered.length ? dataFiltered.at(-1).label : data.at(-1).label;
    if(currentPage+1 === pages.length && pages.length > 1){
      const element = document.getElementById(label);
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block : 'nearest' });
        scrolledRef.current = true;
      }
    }
  }, [dataPage]);

  return (
    <ContainerSearch>
      <SearchInput
        placeholder={t(`List.search.${category}`)}
        type='text'
        onChange={(event : any) => setInputValue(event.target.value)}
        value={inputValue}
      >
        <Icon icon='search' width={14} height={14} color={color} />
      </SearchInput>
      <Result>

        <ListItems>
          <Sorter>
            <AlphaSort>
              <ColumnSortLabel 
                label={t('List.ColumnLabel.alphabetical')}
                onAscendingSort={() => {sortAsc('label'); setSortType('alpha');} }
                onDescendingSort={() => {sortDesc('label'); setSortType('alpha');}}
                type='alpha'
                sortType={sortType}
              />
            </AlphaSort>
            <IndicatorSort>
              <ColumnSortLabel 
                label={t('List.ColumnLabel.values')}
                onAscendingSort={() => {sortDesc('value'); setSortType('value')}}
                onDescendingSort={() => {sortAsc('value'); setSortType('value')}}
                type='value'
                sortType={sortType}
                // total={totalIndicators}
              />
            </IndicatorSort>
          </Sorter>
          {
            dataPage?.map((item : any, index : number) => {
              return (
                <BarList
                  key={item?.label+index} 
                  label={hideLabel ? "" : item?.category}
                  description={item.label}
                  color={color}
                  value={item.value}
                  total={total} 
                  comparingValue={item.comparisonValue}
                  totalComparingValue={comparisonTotalCtt} 
                  isComparing={!!comparison || false}              
                />
              
              )
            })
          }
        </ListItems>
        <ContainerButton>
          {
            (dataFiltered.length > 0 && inputValue && dataFiltered.length > pageSlice) || (data?.length && !inputValue) && pages.length > 1 ?
            <Button onClick={indexArray?.[0] > 0 ? handleClickPreviousPage : () => {}}>
              <Icon icon='previous' color={theme.colors.arrowNext} width={15} />
              {/* <TextButton style={{marginLeft : '10px'}} >{t('List.Button.previous')}</TextButton> */}
            </Button> : 
            <Empty />
          }
          {
            pages.length > 1 ?
            pages.length <= 15 ?
            <PageNumbers>
                {pages?.map((page, idx) => {
                  return (
                      <NumberContainer key={page} selected={currentPage === idx} onClick={() => handleSelectPage(idx)}>
                          {idx + 1}
                      </NumberContainer>
                  )
                }
                )}
            </PageNumbers> : 
            <PageNumbers>
              <Pagination value={currentPage+1} range={pages.length} onChange={handleSelectPage} />
            </PageNumbers>
            : null
          }
          {
            dataFiltered.length > 0 ? 
            pages.length > 1 ?
            <Button onClick={indexArray?.[1] < dataFiltered?.length ? handleClickNextPage : () => {}} >
              {/* <TextButton style={{marginRight : '10px'}}>{t('List.Button.next')}</TextButton> */}
              <Icon icon='next' color={theme.colors.arrowNext} width={15} />
            </Button> : 
            <Empty /> :
            data?.length && !inputValue && pages.length > 1 ?
            <Button onClick={indexArray?.[1] < data?.length ? handleClickNextPage : () => {}} >
              {/* <TextButton style={{marginRight : '10px'}}>{t('List.Button.next')}</TextButton> */}
              <Icon icon='next' color={theme.colors.arrowNext} width={15} />
            </Button> : 
            <Empty />
          }
        </ContainerButton>
      </Result>
    </ContainerSearch>
  )
}

export default List;

interface ListProps {
  data : any;
  color: string;
  total: number;
  category: string;
  hideLabel?: boolean;
  comparison?: boolean;
  comparisonTotalCtt?: number;
}

interface NumberContainerProps {
  selected : boolean;
}

const ContainerSearch = styled.div`
  margin-top     : 35px;
`;

const Result = styled.div`
  min-height     : 40rem;
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
`;

const VerticalBar = styled.div`
  display : flex;
  justify-content : space-between;
  height  : 300px;
  margin  : 60px auto 190px auto;
  width   : 80%;
`;

const HorizontalBar = styled.div`
  display        : flex;
  flex-direction : column;
  margin-top     : 30px;
  margin-bottom  : 110px;
`;

const ListItems = styled.div`
  display : flex;
  flex-direction : column;
  position : relative;
`;

const ContainerItem = styled.div`
  padding : 10px 0;
  background-color : white;
  height : 100px;
`;

const Item = styled.div`
  display : flex;
  flex-direction : row;
  background-color : ${({theme}) => theme.colors.backgroundColor};
  height : 74px;
  align-items : center;
  margin : 10px 0;
  border-radius : 5px;
  height : 75px;
`;

const ItemName = styled.div`
  flex : 1;
  padding : 0 25px;
  font-family : work-sans-regular;
`;

const ItemIndicator = styled.div`
  flex : 1;
  padding : 0 25px;
`;

const ItemLandmark = styled.div`
  flex : 1;
  padding : 0 25px;
`;

const Sorter = styled.div`
  display : flex;
  flex-direction : row;
  padding-left : 2rem;
`;

const AlphaSort = styled.div`
  flex : 1;
  min-width: 27.5rem;
`;

const IndicatorSort = styled.div`
  flex : 1;
  padding-left : 4rem;
`;

const LandmarkSort = styled.div`
  flex : 1;
`;

const ContainerButton = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : center;
  align-items : center;
`;

const Button = styled.div`
  cursor : pointer;
  display : flex;
  align-items : center;
  margin : 0 15px;
`;

const TextButton = styled.div`
  color : ${({theme}) => theme.colors.text};
  font-family : work-sans-regular;
`;

const Empty = styled.div`

`;

const PageNumbers = styled.div`
  display         : flex;
  flex-direction  : row;
  max-width       : 30rem;
`;

const NumberContainer = styled.div<NumberContainerProps>`
  font-family  : ${({selected}) => selected ? "work-sans-semiBold" : "work-sans-regular"};
  color        : #A4A2A0;
  font-size    : ${pxToRem(14)}rem;
  margin       : 0 9px;
  cursor       : pointer;
  user-select: none;
`;