import { call, put, takeEvery, select } from 'redux-saga/effects';
import { apiCall } from './axiosApiCallWrapper';
import { RootState } from '../../types';
import { departementsLoaded, intercosLoaded, regionsLoaded } from '../actions/maps';


function* loadMaps() {
  const {selected}  = yield select((state: RootState) => state.datasets);
  const period = selected?.nomenclature?.split('_')[1];
  let departements: any = {};
  let intercos: any = {};
  let regions: any = {};

  try {
    const { data } = yield call(apiCall, 'GET', `${window._env_.MODEL_API_URL}/maps?year=${period}`);
  
    data?.forEach((d: {id: string}) => {
      if(d.id.includes('reg')){
        regions[d.id] = d;
      } else if(d.id.includes('dep')){
        departements[d.id] = d;
      } else if(d.id.includes('interco')){
        intercos[d.id] = d;
      }
    })
    yield put(regionsLoaded(period, regions));
    yield put(departementsLoaded(period, departements));
    yield put(intercosLoaded(period, intercos));
  }
  catch (err) {
    console.log('error', err);
  }
}

function* mapsSaga() {
  yield takeEvery('SELECT_DATASET',  loadMaps);
}

export default mapsSaga;