import React from 'react';
import styled from 'styled-components';
import { IconLabel } from '../../types';
import pxToRem from '../../utils/dimension';
import Icon from './Icon';



const NavigationIcon = styled(Icon)`
  width : ${pxToRem(37.65)}rem;
  margin-right ${pxToRem(14.3)}rem: 
`;

const NumberIndicator : React.FC<NumberIndicatorProps> = ({numberInterim, nationalNumberInterim, color, isComparing, Icon}) => {

    return(
        <Container>
          <div style={{marginRight : `${pxToRem(14.3)}rem`}}>
            {Icon && <Icon color={color}/>}
          </div>
          <Title color={color}>
              {`${numberInterim.toLocaleString()} `}&nbsp;
          </Title>
          {isComparing && <Title color='#707070'>
              {`/${nationalNumberInterim.toLocaleString()}`}
          </Title>}
        </Container>
    )
}

export default NumberIndicator;


export interface NumberIndicatorProps {
    numberInterim : number;
    nationalNumberInterim : number;
    color : string;
    isComparing : boolean;
    Icon: React.FC<{color : string}> | undefined;
}

interface TitleProps {
    color : string;
}

const Container = styled.div`
display : flex;
flex-direction : row;
justify-content : center;
align-items : center;
background-color : transparent;
width : max-content;
`;

const Title = styled.div<TitleProps>`
color : ${({color}) => color};
font-size : ${pxToRem(30)}rem;
font-weight : 300;
`;