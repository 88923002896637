import React from 'react';
import styled from'styled-components';
import BarIndicator from './BarIndicator';
import pxToRem from '../../utils/dimension';

export interface RightComparisonBarIndicatorProps {
  color : string;
  percentage : number;
  comparisonPercentage : number;
  width?: number;
  height : number;
  label? : string;
  description? : string;
  withDot?: boolean;
  isComparing : boolean;
  labelWidth?: number;
  showPercentage : boolean;
}


const RightComparisonBarIndicator : React.FC<RightComparisonBarIndicatorProps> = ({ color, percentage, comparisonPercentage, width, height=33, isComparing=false, showPercentage=false}) => {
  return(
    <Container>
      <PathContainer>
      <BarIndicator 
        color={color} 
        percentage={percentage} 
        width={width} 
        height={height} 
        isComparing={isComparing} 
        dottedBorder={false} 
        showLabel={showPercentage} 
      />
      { isComparing &&
        <BarIndicator 
          color={color} 
          percentage={comparisonPercentage} 
          width={width} 
          height={height} 
          isComparing={isComparing} 
          dottedBorder={true} 
          showLabel={showPercentage} 
        />
      }
      </PathContainer>
      
    </Container>
  )
}

export default RightComparisonBarIndicator;

interface LabelContainerProps {
  width : number;
}

const Container = styled.div`
  display         : flex;
  flex-direction  : row;
  align-items     : center;
  justify-content : flex-start;
`;

const PathContainer = styled.div`
`;

const LabelContainer = styled.div<LabelContainerProps>`
  font-size    : ${pxToRem(14)}rem;
  width        : ${({width}) => `${pxToRem(width)}rem`};
  font-family  : work-sans-regular;
`;